import Styled from "styled-components";
import Modal from "react-modal";
import { FormattedMessage } from "react-intl";

import { theme } from "../../../constants";
import pxToRem from "../../../utils/pxToRem";
import LinkCta from "../../LinkCta";
import alert from "./alert.svg";

const customStyles = {
  content: {
    width: "100%",
    padding: `${pxToRem(24)} ${pxToRem(0)} ${pxToRem(40)}`,
    borderRadius: "0",
    maxHeight: "100vh",
    overflow: "hidden",
    display: "flex",
    top: "50%",
    position: "absolute",
    transform: "translateY(-50%)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.44)",
    zIndex: 3,
  },
};

const Inner = Styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: ${theme.white};
    margin: 0 auto;
    max-width: ${pxToRem(377)};
    padding: ${pxToRem(30)} ${pxToRem(20)};
    overflow-y: scroll;
    width: 100%;
    text-align: center;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
`;

const Copy = Styled.p`
    ${theme.copy_bold}
    font-size: ${pxToRem(20)};
    line-height: ${pxToRem(28)};
`;

const StyledModal = Styled(Modal)`
    &:focus-visible {
        outline: none;
    }
`;

const Img = Styled.img`
    margin-bottom: ${pxToRem(28)};
`;

const Buttons = Styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: ${pxToRem(28)};
    width: 100%;
`;

const Button = Styled.div`
    flex: 1 1 50%;
    display: flex;
    justify-content: center;
`;

Modal.setAppElement("#root");

export default function ConfirmationModal({ isOpen, onClose, onConfirm }) {
  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
      aria={{
        labelledby: "heading",
      }}
    >
      <Inner>
        <Img src={alert} alt="" />
        <Copy id="heading">
          <FormattedMessage
            id="agenda.item.remove"
            defaultMessage="translate"
          />
        </Copy>
        <Buttons>
          <Button>
            <LinkCta onClick={onClose} uppercase>
              <FormattedMessage
                id="agenda.item.remove.no"
                defaultMessage="translate"
              />
            </LinkCta>
          </Button>
          <Button>
            <LinkCta onClick={onConfirm} uppercase>
              <FormattedMessage
                id="agenda.item.remove.yes"
                defaultMessage="translate"
              />
            </LinkCta>
          </Button>
        </Buttons>
      </Inner>
    </StyledModal>
  );
}
