import { useState, useEffect } from "react";
import throttle from "lodash.throttle";
import { breakpoints } from "../constants";

const getDeviceConfig = (width) => {
  if (width < breakpoints.sm) {
    return "sm";
  } else if (width >= breakpoints.sm && width < breakpoints.md) {
    return "md";
  } else {
    return "lg";
  }
};

const useBreakpoint = () => {
  const [brkPnt, setBrkPnt] = useState(() =>
    getDeviceConfig(window.innerWidth)
  );

  useEffect(() => {
    const calcInnerWidth = throttle(function () {
      setBrkPnt(getDeviceConfig(window.innerWidth));
    }, 200);
    window.addEventListener("resize", calcInnerWidth);
    return () => window.removeEventListener("resize", calcInnerWidth);
  }, []);

  return brkPnt;
};
export default useBreakpoint;
