import Styled from "styled-components";

import pxToRem from "../../../utils/pxToRem";
import { theme, device } from "../../../constants";

const Wrapper = Styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  min-height: 90vh; // needed for mobile

  @media ${device.desktop} {
    flex-direction: row;
  }
`;

const Title = Styled.h1`
  ${theme.headline_medium}
  color: ${theme.white};
  font-size: ${pxToRem(65)};
  line-height: ${pxToRem(60)};
  margin-bottom: ${pxToRem(6)};
  text-transform: uppercase;

  @media ${device.tablet} {
    font-size: ${pxToRem(65)};
    line-height: ${pxToRem(60)};
    margin-bottom: ${pxToRem(14)};
    max-width: ${pxToRem(628)};
  }

  @media ${device.desktop} {
    font-size: ${pxToRem(80)};
    line-height: ${pxToRem(80)};
    max-width: ${pxToRem(487)};
  }

`;

const Copy = Styled.p`
  ${theme.copy_regular}
  color: ${theme.white};
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(24)};
  max-width: ${pxToRem(571)};
`;

const Content = Styled.div`
  margin-left: ${pxToRem(34)};
  margin-right: ${pxToRem(34)};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  overflow-y: scroll;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  @media ${device.tablet} {
    margin-left: ${pxToRem(78)};
    margin-right: ${pxToRem(55)};
  }

  @media ${device.desktop} {
    margin-left: 11.5vw;
    margin-right: 0;
  }
`;

export { Wrapper, Title, Copy, Content };
