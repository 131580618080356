export default function ChevronRight() {
  return (
    <svg viewBox="0 0 14 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 26L1 14L12 2"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
}
