import { useContext } from "react";
import PropTypes from "prop-types";
import Styled from "styled-components";
import Slider from "react-slick";
import { rgba } from "polished";
import { FormattedMessage, useIntl } from "react-intl";

import { LocaleContext } from "../../../utils/locale-context";
import { GaContext } from "../../../utils/ga-context";
import useBreakpoints from "../../../utils/use-breakpoints";
import pxToRem from "../../../utils/pxToRem";
import { theme, breakpoints, device } from "../../../constants";
import VisuallyHidden from "../../../components/VisuallyHidden";

import { NextArrow, PrevArrow } from "../arrows";
import {
  getItemProps,
  ICONS_PER_TYPES,
  getDurationForType,
  VIDEO,
} from "../utils";

const Wrapper = Styled.div`
  background: ${theme.white};
`;

const Section = Styled.section`
  padding: ${pxToRem(37)} 0 ${pxToRem(45)} 0;
  color: ${theme.white};
  border-bottom: 1px solid ${rgba(theme.white, 0.1)};
  background: ${theme.dark_grey_2};

  &:first-child {
    background: ${theme.dark_grey_2};

    .slick-list {
      &:before,
      &:after {
        background: ${theme.dark_grey_2};
      }
    }
  }

  .slick-list {
    position: relative;

    &:before {
      position: absolute;
      width: 11px;
      height: 101%;
      top: -1px;
      left: 0;
      z-index: 1;
      background: ${theme.dark_grey_2};
      content: '';

      @media ${device.tablet} {
        width: 120px;
      }

      @media ${device.desktop} {
        width: 165px;
      }
    }

    &:after {
      position: absolute;
      width: 10px;
      height: 101%;
      top: -1px;
      right: 0;
      z-index: 1;
      opacity: 0.7;
      background: ${theme.dark_grey_2};
      content: '';

      @media ${device.tablet} {
        content: '';
        width: 120px;
      }

      @media ${device.desktop} {
        width: 165px;
      }
    }
  }
`;

const SectionHead = Styled.div`
  padding: 0 ${pxToRem(20)};
  text-align: center;
  margin-bottom: ${pxToRem(13)};

  @media ${device.tablet} {
    margin-bottom: ${pxToRem(24)};
  }

  @media ${device.desktop} {
    margin-bottom: ${pxToRem(36)};
  }
`;

const SectionTitle = Styled.h2`
  ${theme.headline_medium}
  text-transform: uppercase;
  font-size: ${pxToRem(20)};
  line-height: ${pxToRem(32)};

  @media ${device.tablet} {
    font-size: ${pxToRem(28)};
    line-height: ${pxToRem(34)};
  }

  @media ${device.desktop} {
    font-size: ${pxToRem(40)};
    line-height: ${pxToRem(48)};
  }
`;

const SectionCopy = Styled.p`
  color: ${theme.white};
  line-height: ${pxToRem(22)};
  font-size: ${pxToRem(14)};
  ${theme.copy_regular}
`;

const Slide = Styled.div`

  padding-left: 5px;
  padding-right: 5px;

  @media ${device.tablet} {
    padding-left: 15px;
    padding-right: 15px;
    /* display: flex;
    flex-direction: row-reverse;
    max-width: ${pxToRem(687)};
    margin: auto; */
  }
/*
  @media ${device.desktop} {
    padding: 0 ${pxToRem(70)};
    max-width: ${pxToRem(1270)};
  } */
`;

const ImageWrapper = Styled.div`
  margin-bottom: ${pxToRem(12)};

  /* @media ${device.tablet} {
    width: ${pxToRem(332)};
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    margin-left: ${pxToRem(30)};
  } */
`;

const ImgPositioner = Styled.div`
  padding: 0;
  border: none;
  background: transparent;
  position: relative;
  width: 100%;
  display: block;
`;

const Duration = Styled.p`
  background: rgba(12, 12, 12, 0.5);
  border-radius: 15px;
  position: absolute;
  top: ${pxToRem(10)};
  right: ${pxToRem(10)};
  color: ${theme.white};
  font-size: ${pxToRem(11)};
  line-height: ${pxToRem(16)};
  padding: ${pxToRem(2)} ${pxToRem(6)};
  ${theme.copy_medium}
  text-transform: uppercase;
`;

const Content = Styled.div`
  color: ${theme.white};
  ${theme.copy_regular}
`;

const Img = Styled.img`
  width: 100%;
`;

const PlayIcon = Styled.img`
`;

const Title = Styled.h2`
  ${theme.copy_medium}
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(24)};
  margin-bottom: ${pxToRem(4)};

  /* @media ${device.desktop} {
    max-width: ${pxToRem(450)};
  } */
`;

const Body = Styled.p`
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(18)};
  color: ${theme.white};

  /* @media ${device.desktop} {
    max-width: ${pxToRem(540)};
  } */
`;

const PlayWrapper = Styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${pxToRem(58)};
  height: ${pxToRem(58)};

  padding: 0;
  border: none;
  background: transparent;
  display: none;

  .slick-active & {
    display: block;
  }

  &:focus-visible {
    img {
      transform: scale(1.2);
      transform-origin: center;
    }
  }
`;

const INITAL_SLIDE_PER_BP = {
  lg: 2,
  md: 1,
  sm: 0,
};
export default function OndemandBody({ sections, onItemClick }) {
  const { locale } = useContext(LocaleContext);
  const { trackEvent } = useContext(GaContext);
  const bp = useBreakpoints();

  const intl = useIntl();
  const nextLabel = intl.formatMessage({ id: "component.carousel.next" });
  const prevLabel = intl.formatMessage({ id: "component.carousel.prev" });

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: INITAL_SLIDE_PER_BP[bp],
    centerMode: true,
    centerPadding: "150px",
    nextArrow: <NextArrow label={nextLabel} showOnTablet />,
    prevArrow: <PrevArrow label={prevLabel} showOnTablet />,
    draggable: false,
    swipeToSlide: false,
    accessibility: true,
    swipe: false,
    responsive: [
      {
        breakpoint: breakpoints.md - 1,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerPadding: "105px",
          centerMode: true,
          infinite: true,
          initialSlide: 1,
        },
      },
      {
        breakpoint: breakpoints.sm - 1,
        settings: {
          centerPadding: "15px",
          centerMode: true,
          infinite: false,
          slidesToShow: 1,
          swipe: true,
          draggable: true,
        },
      },
    ],
  };

  if (!sections) return null;
  return (
    <Wrapper>
      {Object.keys(sections).map((slider) => {
        return (
          <Section key={slider}>
            <SectionHead>
              <SectionTitle>
                {sections[slider][0][`cat-title-${locale}`]}
              </SectionTitle>
              <SectionCopy>
                {sections[slider][0][`cat-copy-${locale}`]}
              </SectionCopy>
            </SectionHead>
            <Slider {...settings}>
              {sections[slider].map((item) => {
                let props = getItemProps(item);
                const type = item.type.toLowerCase();
                props.onClick = () => {
                  trackEvent("On-demand content selection", item[`title-en`]);
                };

                if (props.as === "button") {
                  props.onClick = () => onItemClick(item);
                }
                return (
                  <div key={item.uid}>
                    <Slide>
                      <ImageWrapper>
                        <ImgPositioner>
                          <VisuallyHidden as="span">
                            <FormattedMessage
                              id="ondemand.video.icons.play"
                              defaultMessage="TRANSLATE THIS"
                            />
                          </VisuallyHidden>
                          <Img src={`/ondemand/thumbnails/${item.image}`} />
                          <Duration>
                            {type === VIDEO ? (
                              getDurationForType(item)
                            ) : (
                              <FormattedMessage
                                id={`ondemand.type.${type}`}
                                defaultMessage={item.type}
                              />
                            )}
                          </Duration>
                          <PlayWrapper {...props}>
                            <PlayIcon src={ICONS_PER_TYPES[type]} alt="" />
                          </PlayWrapper>
                        </ImgPositioner>
                      </ImageWrapper>
                      <Content>
                        <Title>{item[`title-${locale}`]}</Title>
                        <Body>{item[`desc-${locale}`]}</Body>
                      </Content>
                    </Slide>
                  </div>
                );
              })}
            </Slider>
          </Section>
        );
      })}
    </Wrapper>
  );
}

OndemandBody.propTypes = {
  sections: PropTypes.shape({}),
};

OndemandBody.defaultProps = {
  sections: {},
};
