const routes = {
  HOME: "/",
  LOGIN: "/login", // change when we have a flow
  LOADING: "/loading",
  ONBOARDING: "/onboarding",
  AGENDA: "/agenda",
  PROGRAM: "/program",
  MAIN_STAGE: "/main-stage",
  ON_DEMAND: "/on-demand",
  EXPERT: "/expert",
  HELP: "/help",
  PRIVACY: "/privacy",
  TERMS: "/terms-and-conditions",
  COOKIES: "/cookie-policy",
  EXPLORE: "/explore",
};

export default routes;
