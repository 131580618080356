import { fr, de, it, ru, es, en } from "./assets/flags";

export const HEIGHTS = {
  sm: 64
};

export const LANGUAGES = [
  {
    id: "de",
    img: de,
    label: "language-selector.de"
  },
  {
    id: "en",
    img: en,
    label: "language-selector.en"
  },
  {
    id: "es",
    img: es,
    label: "language-selector.es"
  },
  {
    id: "fr",
    img: fr,
    label: "language-selector.fr"
  },
  {
    id: "it",
    img: it,
    label: "language-selector.it"
  },
  {
    id: "ru",
    img: ru,
    label: "language-selector.ru"
  }
];
