import Styled from "styled-components";

import Header from "../Nav";
import Footer from "../Footer";
import { theme } from "../../constants";
const Wrapper = Styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  overflow-y: scroll;
  overflow-x: hidden;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  
  ::-webkit-scrollbar {
    display: none; // edge
  }

  background: ${theme.base};
`;

const Main = Styled.main`
  flex-grow: 1;
  ${(props) => props.flex && "display: flex;"}
  ${(props) => props.column && "flex-direction: column;"}
`;

export default function Page({ children, flex, hideNav, column }) {
  return (
    <Wrapper>
      <Header hideNav={hideNav} />
      <Main flex={flex} column={column}>
        {children}
      </Main>
      <Footer />
    </Wrapper>
  );
}
