import { Route, Switch } from "react-router-dom";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/it";
import "moment/locale/de";
import "moment/locale/ru";

import { routes } from "./constants";
import "./App.css";
import Home from "./screens/home";
import Login, { LoginComponent } from "./screens/login";
import Loading from "./components/Loading";
import { OnboardingComponent } from "./screens/onboarding";
import Program from "./screens/program";
import Agenda from "./screens/agenda";
import Expert from "./screens/expert";
import Ondemand from "./screens/on-demand";
import MainStage from "./screens/main-stage";
import Help from "./screens/help";
import Privacy from "./screens/privacy";
import Terms from "./screens/terms";
import Cookies from "./screens/cookie-information";
import Explore from "./screens/explore";

import ProtectedRoute from "./auth/protected-route";

function App() {
  return (
    <Switch>
      <ProtectedRoute path={routes.HOME} exact component={Home} />
      <Route path={routes.LOGIN} exact component={Login} />
      <ProtectedRoute path={routes.PROGRAM} exact component={Program} />
      <ProtectedRoute path={routes.AGENDA} exact component={Agenda} />
      <ProtectedRoute path={routes.EXPERT} exact component={Expert} />
      <ProtectedRoute path={routes.ON_DEMAND} exact component={Ondemand} />
      <ProtectedRoute path={routes.MAIN_STAGE} exact component={MainStage} />
      <ProtectedRoute path={routes.EXPLORE} exact component={Explore} />
      <Route path={routes.HELP} exact component={Help} />
      <Route path={routes.PRIVACY} exact component={Privacy} />
      <Route path={routes.TERMS} exact component={Terms} />
      <Route path={routes.COOKIES} exact component={Cookies} />
      <Route path={`${routes.LOGIN}-test`} exact component={LoginComponent} />
      <ProtectedRoute
        path={routes.ONBOARDING}
        exact
        component={OnboardingComponent}
      />
      <Route path={`${routes.LOADING}-test`} component={Loading} />
    </Switch>
  );
}

export default App;
