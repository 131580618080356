import Styled from "styled-components";

import pxToRem from "../../utils/pxToRem";
import { theme, device } from "../../constants";

const Copy = Styled.p`
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(24)};
    ${theme.copy_medium}
    margin-top: ${pxToRem(12)};

    @media ${device.desktop} {
      font-size: ${pxToRem(20)};
      line-height: ${pxToRem(32)};
    }
`;

export default function Title({ copy }) {
  return <Copy>{copy}</Copy>;
}
