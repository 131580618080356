import CENTRAL from "./p_central.json";
import DRGB from "./p_drgb.json";
import DE from "./p_de.json";
import FR from "./p_fr.json";
import GB from "./p_gb.json";
import NL from "./p_nl.json";

const PROGRAMME_PER_REGIONS = {
  p_drgb: DRGB,
  p_de: DE,
  p_fr: FR,
  p_gb: GB,
  p_nl: NL,
};

const ALL_DATA = [...CENTRAL, ...DRGB, ...DE, ...FR, ...GB, ...NL];

export { PROGRAMME_PER_REGIONS, ALL_DATA, CENTRAL };
