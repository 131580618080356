import { useContext } from "react";
import Styled from "styled-components";
import { FormattedMessage } from "react-intl";

import { UserMetaContext } from "../../../../utils/metadata-context";
import pxToRem from "../../../../utils/pxToRem";
import { device } from "../../../../constants";
import Cta from "../../../../components/Cta";
import { Wrapper, Title, Copy, Content } from "../styled";

import Sm from "./sm.png";
import Md from "./md.png";
import Lg from "./lg.png";
import BorderSm from "./border-sm.svg";

const Split = Styled.div`
  ${(props) =>
    props.hasBackground
      ? `
      background: url(${Sm}) no-repeat top center / cover;
      height: ${pxToRem(182)};
    `
      : `
        @media ${device.mobile} {
          background: url(${BorderSm}) no-repeat bottom left / cover;

        }
        flex: 1;
    `}

  @media ${device.tablet} {
    ${(props) =>
      props.hasBackground
        ? `
        background: url(${Md}) no-repeat top center / cover;
        height: ${pxToRem(370)};
      `
        : "flex: 1;"}


  }

  @media ${device.desktop} {
    ${(props) =>
      props.hasBackground &&
      `
      background: url(${Lg}) no-repeat top center / cover;
      height: unset;
      `}

    flex: 1 0 50%;
  }
`;

const Inner = Styled.div``;

const ButtonWrapper = Styled.div`
  margin-top: ${pxToRem(94)};
  max-width: 100%;

  @media ${device.tablet} {
    margin-top: ${pxToRem(26)};
    max-width: ${pxToRem(220)};
  }
`;

export default function Welcome({ onNext, dots, prev }) {
  const { user } = useContext(UserMetaContext);
  return (
    <Wrapper>
      <Split>
        <Content>
          {prev()}
          <Inner>
            <Title>
              <FormattedMessage
                id="onboarding.slide.1.title"
                defaultMessage="Translate {name}!"
                values={{ name: user.given_name }}
              />
            </Title>
            <Copy>
              <FormattedMessage
                id="onboarding.slide.1.copy"
                defaultMessage="Translate this string for the Make It festival.We have 2 weeks packed with inspiring content and sessions to help enhance your printing operation."
              />
            </Copy>
            <ButtonWrapper>
              <Cta uppercase onClick={() => onNext()}>
                <FormattedMessage
                  id="onboarding.slide.1.button"
                  defaultMessage="Translate this"
                />
              </Cta>
            </ButtonWrapper>
          </Inner>
          {dots()}
        </Content>
      </Split>
      <Split hasBackground />
    </Wrapper>
  );
}
