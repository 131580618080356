import { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Styled from "styled-components";
import Div100vh from "react-div-100vh";
import { useAuth0 } from "@auth0/auth0-react";
import { FormattedMessage } from "react-intl";

import pxToRem from "../../utils/pxToRem";
import { LocaleContext } from "../../utils/locale-context";
import { GaContext } from "../../utils/ga-context";
import { routes, theme, device } from "../../constants";
import Loading from "../../components/Loading";
import Cta from "../../components/Cta";
import Page from "../../components/Page";

import Background from "./Background";

const Inner = Styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-grow: 1;
`;

const Content = Styled.div`
  text-align: center;
  max-width: ${pxToRem(812)};
  padding: ${pxToRem(20)} ${pxToRem(50)};
  margin: auto;
  z-index: 1;

  @media ${device.desktop} {
    max-width: ${pxToRem(900)};
  }
`;

const Title = Styled.h1`
  font-size: ${pxToRem(55)};
  text-transform: uppercase;
  line-height: ${pxToRem(55)};
  ${theme.headline_medium}
  color: ${theme.white};
  margin-bottom: ${pxToRem(6)};

  @media ${device.tablet} {
    font-size: ${pxToRem(130)};
    line-height: ${pxToRem(110)};
  }

  @media ${device.desktop} {
    font-size: ${pxToRem(150)};
    line-height: ${pxToRem(130)};
    margin-bottom: ${pxToRem(33)};
  }
`;

const Copy = Styled.p`
  font-size: ${pxToRem(28)};
  line-height: ${pxToRem(34)};
  ${theme.headline_medium}
  color: ${theme.white};
  max-width: ${pxToRem(538)};
  margin: auto;
  margin-bottom: ${pxToRem(34)};

  @media ${device.tablet} {
    font-size: ${pxToRem(28)};
    line-height: ${pxToRem(34)};
  }

  @media ${device.desktop} {
    font-size: ${pxToRem(28)};
    line-height: ${pxToRem(34)};
  }
`;

const Buttons = Styled.div`
  display: flex;
  justify-content: center;
`;

const ButtonWrapper = Styled.div`
  display: flex;

  & + & {
    margin-left: ${pxToRem(23)};
  }
`;

export default function Login({ location, history, shouldRedirect }) {
  const [authIsLoading, setAuthIsLoading] = useState(true);
  const { locale } = useContext(LocaleContext);
  const { trackEvent } = useContext(GaContext);
  // const [userLocale] = useState(locale);
  const fromUrl = location?.state?.from?.pathname;
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    if (isAuthenticated && shouldRedirect) {
      history.push(fromUrl || routes.HOME);
    }
  }, [isAuthenticated, fromUrl, history, shouldRedirect]);

  // useEffect(() => {
  //   // need to trigger a complete reload to update the gateid
  //   if (locale !== userLocale) {
  //     window.location.reload();
  //   }
  // }, [locale, userLocale]);

  useEffect(() => {
    setAuthIsLoading(isLoading);
  }, [isLoading]);

  return authIsLoading ? (
    <Loading />
  ) : (
    <Div100vh>
      <Page flex column>
        <Inner>
          <Background />
          <Content>
            <Title>
              <FormattedMessage id="login.title" defaultMessage="Make it" />
            </Title>

            <Copy>
              <FormattedMessage
                id="login.copy"
                defaultMessage="An inspiring and innovative festival designed to help transform your
            business, from the most influential voices within the Canon print
            business."
              />
            </Copy>

            <Buttons>
              <ButtonWrapper>
                <Cta
                  uppercase
                  onClick={() => {
                    trackEvent("Login", "User Login");
                    loginWithRedirect({ locale });
                  }}
                >
                  <FormattedMessage
                    id="login.login-btn"
                    defaultMessage="Login"
                  />
                </Cta>
              </ButtonWrapper>
            </Buttons>
          </Content>
        </Inner>
      </Page>
    </Div100vh>
  );
}

Login.propTypes = {
  shouldRedirect: PropTypes.bool,
};

Login.defaultProps = {
  shouldRedirect: true,
};
