import { useContext, useState, useEffect } from "react";
import Styled from "styled-components";
import { FormattedMessage } from "react-intl";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";

import pxToRem from "../../../utils/pxToRem";
import {
  theme,
  device,
  pillars,
  subgroups,
  auth0Keys,
} from "../../../constants";
import { UserMetaContext } from "../../../utils/metadata-context";
import { GaContext } from "../../../utils/ga-context";
import { renderCheckboxes } from "../../onboarding/slides/checkboxes";
import open from "./assets/open.svg";
import close from "./assets/close.svg";
import LangSelector from "./LangSelector";

import Pill from "./Pill";

const item = `
    border-bottom: ${pxToRem(1)} solid ${theme.light_grey_1};
`;

const StyledAccordionItem = Styled(AccordionItem)`
    ${item};

    ${(props) =>
      props.$hiddenFromTablet &&
      `
        @media ${device.tablet} {
            display: none;
        }
    `}
`;

const StyledAccordionItemPanel = Styled(AccordionItemPanel)`
  xpadding: ${pxToRem(22)} 0 0;
`;

const Item = Styled.div`
    padding: ${pxToRem(18)} 0;
    display: flex;


    ${(props) =>
      props.$hiddenUntilTablet &&
      `
      align-items: flex-start;
      ${item};
      @media ${device.mobile} {
          display: none;
      }
    `}
`;

const SectionTitle = Styled.span`
    display: flex;
    align-items: center;
    flex-shrink: 0;
    ${theme.copy_regular}
`;

const StyledAccordionItemButton = Styled(AccordionItemButton)`
    padding: ${pxToRem(18)} 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Wrapper = Styled.div`
    padding: 0 ${pxToRem(20)};
    margin-bottom: ${pxToRem(24)};

    @media ${device.tablet} {
      padding: 0 ${pxToRem(120)};
    }

    @media ${device.desktop} {
      padding: 0 ${pxToRem(165)};
    }
`;

const Title = Styled.h2`
    ${theme.copy_bold}
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(24)};
    margin-bottom: ${pxToRem(7)};

    @media ${device.tablet} {
      font-size: ${pxToRem(20)};
      line-height: ${pxToRem(32)};
    }
`;

const Inner = Styled.div`
    border: 1px solid ${theme.light_grey_1};
    padding: 0 ${pxToRem(20)};
    ${theme.copy_regular}
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(24)};
`;

const HeaderInner = Styled.div`
    display: flex;
    align-items: center;
`;

const Checkboxes = Styled.div`
      display: flex;
      flex-grow: 1;
      margin-left: ${pxToRem(24)};
`;

const BoxesSm = Styled.div`
    @media ${device.desktop} {
      display: none;
    }
`;

const BoxesLg = Styled.div`
    @media ${device.tabletMax} {
      display: none;
    }
`;

const TitleSm = Styled.span`
  @media ${device.desktop} {
    display: none;
  }
`;

const Titlelg = Styled.span`
  @media ${device.tabletMax} {
    display: none;
  }
`;

const getPillars = (data) => {
  return pillars.map((p) => ({
    value: p,
    checked: data[auth0Keys.PILLARS].includes(p),
  }));
};

const getSubgroups = (data) => {
  return subgroups.map((p) => ({
    value: p,
    checked: data[auth0Keys.SUBGROUP].includes(p),
  }));
};

export default function Filter() {
  const { user, updateUser } = useContext(UserMetaContext);
  const { trackEvent } = useContext(GaContext);
  const { user_metadata } = user;
  const [pillarsForUser, setPillars] = useState(getPillars(user_metadata));
  const [intrests, setInterests] = useState(getSubgroups(user_metadata));

  useEffect(() => {
    setPillars(getPillars(user.user_metadata));
    setInterests(getSubgroups(user.user_metadata));
  }, [user]);

  const onChangePillars = (index, evt) => {
    const changedPillars = pillarsForUser
      .map((option, i) => {
        if (i === index) {
          if (evt.target.checked) {
            trackEvent("Programme - content themes", option.value);
          }
        }
        return {
          ...option,
          checked: i === index ? evt.target.checked : option.checked,
        };
      })
      .filter((p) => p.checked)
      .map((p) => p.value);

    updateUser({ [auth0Keys.PILLARS]: changedPillars });
  };

  const onChangeSubgroups = (index, evt) => {
    const changedSubgroups = intrests
      .map((option, i) => {
        if (i === index) {
          if (evt.target.checked) {
            trackEvent("Programme - my interests", option.value);
          }
        }

        return {
          ...option,
          checked: i === index ? evt.target.checked : option.checked,
        };
      })
      .filter((p) => p.checked)
      .map((p) => p.value);

    updateUser({ [auth0Keys.SUBGROUP]: changedSubgroups });
  };

  return (
    <Wrapper>
      <Title>
        <FormattedMessage
          id="programme.filter.title"
          defaultMessage="TRANSLATE"
        />
      </Title>
      <Inner>
        <Item $hiddenUntilTablet>
          <SectionTitle>
            <FormattedMessage
              id="programme.item.1"
              defaultMessage="TRANSLATE"
            />
          </SectionTitle>
          <Checkboxes>
            {renderCheckboxes(
              pillarsForUser,
              onChangePillars,
              "data.pillars.",
              0
            )}
          </Checkboxes>
        </Item>
        <Accordion allowZeroExpanded>
          <StyledAccordionItem $hiddenFromTablet>
            <AccordionItemHeading>
              <StyledAccordionItemButton>
                <AccordionItemState>
                  {({ expanded }) => (
                    <>
                      <HeaderInner>
                        <SectionTitle>
                          <FormattedMessage
                            id="programme.item.1"
                            defaultMessage="TRANSLATE"
                          />
                        </SectionTitle>
                        <Pill
                          number={
                            pillarsForUser.filter((i) => i.checked).length
                          }
                        />
                      </HeaderInner>
                      <img src={expanded ? close : open} alt="" />
                    </>
                  )}
                </AccordionItemState>
              </StyledAccordionItemButton>
            </AccordionItemHeading>
            <StyledAccordionItemPanel>
              {renderCheckboxes(
                pillarsForUser,
                onChangePillars,
                "data.pillars.",
                1
              )}
            </StyledAccordionItemPanel>
          </StyledAccordionItem>
          <StyledAccordionItem>
            <AccordionItemHeading>
              <StyledAccordionItemButton>
                <AccordionItemState>
                  {({ expanded }) => (
                    <>
                      <SectionTitle>
                        <FormattedMessage
                          id="programme.item.2"
                          defaultMessage="TRANSLATE"
                        />
                        <Pill
                          number={intrests.filter((i) => i.checked).length}
                        />
                      </SectionTitle>

                      <img src={expanded ? close : open} alt="" />
                    </>
                  )}
                </AccordionItemState>
              </StyledAccordionItemButton>
            </AccordionItemHeading>
            <StyledAccordionItemPanel>
              <BoxesSm>
                {renderCheckboxes(
                  intrests,
                  onChangeSubgroups,
                  "data.subgroups.",
                  1
                )}
              </BoxesSm>
              <BoxesLg>
                {renderCheckboxes(
                  intrests,
                  onChangeSubgroups,
                  "data.subgroups.",
                  4,
                  true
                )}
              </BoxesLg>
            </StyledAccordionItemPanel>
          </StyledAccordionItem>
        </Accordion>
        <Item>
          <SectionTitle>
            <TitleSm>
              <FormattedMessage
                id="programme.item.3"
                defaultMessage="TRANSLATE"
              />
            </TitleSm>
            <Titlelg>
              <FormattedMessage
                id="programme.item.3.lg.before"
                defaultMessage="TRANSLATE"
              />
            </Titlelg>
            <LangSelector />
            <Titlelg>
              <FormattedMessage
                id="programme.item.3.lg.after"
                defaultMessage="TRANSLATE"
              />
            </Titlelg>
          </SectionTitle>
        </Item>
      </Inner>
    </Wrapper>
  );
}
