import Styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { rgba } from "polished";

import pxToRem from "../../utils/pxToRem";
import { theme } from "../../constants";

import ribbonIcon from "./icons/ribbon.png";
import ribbonCheckedIcon from "./icons/ribbon-checked.png";
import VisuallyHidden from "../VisuallyHidden";
import Cta from "../Cta";

const ICONS_PER_TYPE = {
  agenda: {
    default: ribbonIcon,
    checked: ribbonCheckedIcon,
  },
};

const Checkbox = Styled.input`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

const Label = Styled.label`
    display: flex;
    align-items: center;
    cursor: pointer;

    ${Checkbox}:focus + & {
      [data-id="button"] {
        background: ${rgba(theme.primary, 0.07)};
      }
    }

    ${Checkbox}:checked:focus + & {
      [data-id="button"] {
        background: ${theme.primary_dark};
      }
    }
`;

const Img = Styled.img`
    margin: 6px 4px;
`;

const Copy = Styled.span`
    color: ${theme.primary};
    ${theme.copy_regular}
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(22)};
`;

const Wrapper = Styled.div`
  flex-shrink: 0;
`;

const CtaWrapper = Styled.div`
  margin-left: ${pxToRem(10)};
`;

export default function ToggleCheck({
  isChecked,
  copy,
  showCopy,
  id,
  type,
  onChange,
}) {
  return (
    <Wrapper>
      <Checkbox
        onChange={(evt) => {
          onChange(evt, id);
        }}
        checked={isChecked}
        aria-labelledby={id}
        id={`checkbox-${id}`}
        type="checkbox"
      />
      <Label htmlFor={`checkbox-${id}`}>
        <VisuallyHidden id={id}>
          <FormattedMessage id={copy} defaultMessage="translate" />
        </VisuallyHidden>
        {showCopy && (
          <Copy aria-hidden>
            <FormattedMessage id={copy} defaultMessage="translate" />
          </Copy>
        )}
        <CtaWrapper>
          <Cta
            variant={isChecked ? "primary" : "secondary"}
            iconOnly
            as="span"
            aria-hidden
          >
            <Img
              src={
                isChecked
                  ? ICONS_PER_TYPE[type]?.checked
                  : ICONS_PER_TYPE[type]?.default
              }
              alt=""
            />
          </Cta>
        </CtaWrapper>
      </Label>
    </Wrapper>
  );
}
