import { useContext } from "react";
import Styled from "styled-components";
import { useIntl } from "react-intl";

import { LocaleContext } from "../../../utils/locale-context";
import { device, theme } from "../../../constants";
import pxToRem from "../../../utils/pxToRem";
import closeIcon from "./close.svg";

const StyledHeader = Styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${pxToRem(10)};

    @media ${device.tablet} {
        border-bottom: ${pxToRem(1)} solid ${theme.light_grey_2};
        margin-bottom: ${pxToRem(16)};
        padding-bottom: ${pxToRem(8)};
    }
  `;

const CloseBtn = Styled.button`
    padding: 0;
    border: none;
    background: transparent;

    &:focus-visible {
      outline: 1px solid ${theme.primary};
    }
  `;

const Date = Styled.p`
    ${theme.copy_regular}
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(26)};
    color: ${theme.dark_grey_1};
`;

const StyledTime = Styled.span``;

export default function Header({ item, onClose }) {
  const { moment } = useContext(LocaleContext);
  const intl = useIntl();
  const closeLabel = intl.formatMessage({ id: "component.modal.close" });

  const { duration } = item;
  return (
    <StyledHeader>
      <Date id="header">
        <StyledTime>{moment(duration.starttime).format("HH:mm")}</StyledTime>-
        <StyledTime>{moment(duration.endtime).format("HH:mm")}</StyledTime>,{" "}
        <span>{moment(duration.starttime).format("dddd, Do MMMM")}</span>
      </Date>
      <CloseBtn onClick={onClose} aria-label={closeLabel}>
        <img src={closeIcon} alt="" />
      </CloseBtn>
    </StyledHeader>
  );
}
