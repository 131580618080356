const auth0 = {
  HAS_COMPLETED_ONBOARDING: "onboarding-completed",
  LOCALE: "locale",
  COUNTRY: "country",
  TECH: "tech",
  TOPICS: "topics",
  PILLARS: "pillars",
  SUBGROUP: "subgroup",
  EVENTS: "events",
  WEBINARIDS: "webinarids",
};

export default auth0;
