const subgroups = [
  "aerospace",
  "training",
  "biology",
  "future",
  "geology",
  "human",
  "material",
  "mechatronics",
  "ops",
  "navigation",
  "planetary",
  "propulsion",
  "randd",
  "robotics",
  "rover",
  "search",
  "software",
  "colonization",
  "systems",
];

export default subgroups;
