import Page from "../../components/Page";
import HeaderVideo from "./Header-video";
import Body from "./Body";

const GA_ACTIONS = [
  "Homepage programme",
  "Homepage agenda",
  "Homepage meet an expert",
];
const GA_LABELS = ["Launch programme", "Launch agenda", "Launch experts"];

export default function HomeScreen() {
  return (
    <Page>
      <HeaderVideo />
      <Body actions={GA_ACTIONS} labels={GA_LABELS} />
    </Page>
  );
}
