import { useCallback, useContext, useState, useRef } from "react";
import Styled from "styled-components";
import Slider from "react-slick";

import Page from "../../components/Page";
import { UserMetaContext } from "../../utils/metadata-context";
import pxToRem from "../../utils/pxToRem";
import { device, auth0Keys } from "../../constants";

import Welcome from "./slides/Welcome";
import Browse from "./slides/Browse";
import Agenda from "./slides/Agenda";
import Country from "./slides/Country";
import Tech from "./slides/Tech";
import Topics from "./slides/Topics";
import Complete from "./slides/Complete";
import Dots from "./slides/Dots";
import Prev from "./slides/Prev";

const mainHeight = `calc(100vh - ${pxToRem(82)})`;

const Wrapper = Styled.div`
  max-width: 100%;
  width: 100%; // ie11

  @media ${device.desktop} {
    .slick-slider,
    .slick-list,
    .slick-track {
      height: 100%;
      max-height: ${mainHeight};
    }

    .slick-slide > div {
      height: 100%;
      max-height: ${mainHeight};
    }
  }
`;

const Slide = Styled.div`
  @media ${device.desktop} {
    height: 100%;
    max-height: ${mainHeight};
  }
`;

const Inner = Styled.div`
  @media ${device.desktop} {
    height: 100%;
    max-height: ${mainHeight};
  }
  display: flex;

  ${(props) => !props.isActiveSlide && "visibility: hidden;"}
`;

const slides = [
  {
    component: Welcome,
  },
  {
    component: Browse,
  },
  {
    component: Agenda,
  },
  { component: Country },
  { component: Tech },
  { component: Topics },
  { component: Complete },
];
export default function Onboarding() {
  const { syncUser } = useContext(UserMetaContext);
  const [payload, setPayload] = useState({});
  const [slideIndex, setSlideIndex] = useState(0);
  const sliderRef = useRef();
  const settings = {
    accessibility: false,
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    swipeToSlide: false,
    swipe: false,
    adaptiveHeight: true,
    initialSlide: 0,
  };

  const onChange = (value) => {
    setSlideIndex(value);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const onNext = useCallback(
    (values) => {
      if (sliderRef.current) {
        sliderRef.current.slickNext();
        onChange(slideIndex + 1);
        setPayload((payload) => ({ ...payload, ...values }));
      }
    },
    [slideIndex]
  );

  const onPrev = useCallback(() => {
    if (sliderRef.current) {
      onChange(slideIndex - 1);
      sliderRef.current.slickPrev();
    }
  }, [slideIndex]);

  const renderDots = () => <Dots total={slides} activeSlide={slideIndex} />;

  const onComplete = useCallback(async () => {
    const user_metadata = {
      [auth0Keys.COUNTRY]: payload.country,
      [auth0Keys.SUBGROUP]: ["s1", ...payload.tech, ...payload.topics],
      [auth0Keys.HAS_COMPLETED_ONBOARDING]: true,
      [auth0Keys.PILLARS]: [
        "research",
        "technologies",
        "launch",
      ],
      [auth0Keys.EVENTS]: [],
      [auth0Keys.WEBINARIDS]: [],
    };

    //console.log("onboarding payload: ", user_metadata);
    //await updateUser(user_metadata);
    syncUser(user_metadata);
  }, [payload, syncUser]);

  const renderPrev = (index) => (
    <Prev isHidden={index === 0} onClick={onPrev} />
  );
  return (
    <Page flex hideNav>
      <Wrapper>
        <Slider {...settings} ref={sliderRef}>
          {slides.map((slide, index) => {
            const { component: Component } = slide;
            return (
              <Slide key={index}>
                <Inner isActiveSlide={slideIndex === index}>
                  <Component
                    onNext={onNext}
                    dots={() => renderDots()}
                    prev={() => renderPrev(index)}
                    onComplete={onComplete}
                    isActiveSlide={slideIndex === index}
                  />
                </Inner>
              </Slide>
            );
          })}
        </Slider>
      </Wrapper>
    </Page>
  );
}
