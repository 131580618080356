import Styled from "styled-components";
import { device, theme } from "../../constants";
import pxToRem from "../../utils/pxToRem";

export const Content = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${theme.white};
  text-align: center;
  max-width: ${pxToRem(548)};
  padding: ${pxToRem(20)};
  position: absolute;

  @media ${device.tablet} {
    max-width: ${pxToRem(690)};
  }

  @media ${device.desktop} {
    max-width: ${pxToRem(878)};
  }
`;

export const Title = Styled.h1`
  text-transform: uppercase;
  ${theme.headline_bold}
  font-size: ${pxToRem(35)};
  line-height: ${pxToRem(40)};

  @media ${device.tablet} {
    font-size: ${pxToRem(60)};
    line-height: ${pxToRem(102)};
  }

  @media ${device.desktop} {
    font-size: ${pxToRem(80)};
    line-height: ${pxToRem(102)};
  }
`;

export const Subtitle = Styled.p`
  ${theme.headline_medium}
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(20)};

  @media ${device.tablet} {
    font-size: ${pxToRem(28)};
    line-height: ${pxToRem(34)};
  }
`;
