import React, { createContext, useState, useEffect } from "react";
import moment from "moment";
import { types } from "../constants";
import mapCountry2Region from "../constants/regions";

import { sortByDate, getContentForUserType } from "../utils/data";

import { PROGRAMME_PER_REGIONS, ALL_DATA, CENTRAL } from "../data/programme";

import useUsersMetaData from "./use-meta-data";
const UserMetaContext = createContext(null);
export { UserMetaContext };

const sortByStartTime = (a, b) => {
  if (a.duration.starttime < b.duration.starttime) {
    return -1;
  }
  if (a.duration.starttime > b.duration.starttime) {
    return 1;
  }
  // a must be equal to b
  return 0;
};

const sortByDayAndTime = (data) => {
  const hashmapPreDate = data.reduce((accumulator, currentValue) => {
    return {
      ...accumulator,
      [currentValue.date]: accumulator[currentValue.date]
        ? [...accumulator[currentValue.date], currentValue]
        : [currentValue],
    };
  }, {});

  const replaceDate = (timestring, transformedDate) => {
    const dates = timestring.split("T");
    return `${transformedDate}T${dates[1]}`;
  };

  const hashmap = Object.keys(hashmapPreDate).reduce(
    (accumulator, currentValue, index) => {
      const transformedDate = moment().add(index, "d").format("YYYY-MM-DD");
      const valuesPerDate = hashmapPreDate[currentValue];
      return {
        ...accumulator,
        [transformedDate]: [...valuesPerDate].map((event) => ({
          ...event,
          date: transformedDate,
          duration: {
            ...event.duration,
            endtime: replaceDate(event.duration.endtime, transformedDate),
            starttime: replaceDate(event.duration.starttime, transformedDate),
          },
        })),
      };
    },
    {}
  );

  const arrays = [];
  Object.keys(hashmap).map((key, index) => {
    arrays[index] = hashmap[key].sort(sortByStartTime);
  });

  return { arrays, hashmap };
};

const getEventsForToday = (arrays, hashmap) => {
  // get todays date using the timezone of the EVENT
  // this way, incase the local time has changed date we still use the date of the event
  const timezoneIndex = arrays[0][0].duration.starttime.indexOf("+");
  const timezone = arrays[0][0].duration.starttime.substring(timezoneIndex);
  const today = moment().utcOffset(timezone).format("YYYY-MM-DD");
  return hashmap[today] || [];
};

//2021-06-04T00:00:00.000+01:00
const getHasFestivalStarted = () => {
  return moment().isAfter(moment("2021-06-08T00:00:00.000+01:00"));
};

const getHasFestivalEnded = (arrays) => {
  const lastDay = arrays[arrays.length - 1];
  const lastEvent = lastDay[lastDay.length - 1];
  return moment().isAfter(lastEvent?.duration?.endtime);
};

const getLiveEvent = (events) => {
  return events
    .filter(
      (event) => event.type === types.brightcove || event.type === types.youtube
    )
    .find((event) => {
      // get the first live event that hasn't finished yet
      return moment().isBefore(event.duration.endtime);
    });
};

const getHasDayStarted = (events) => {
  return !!events
    .filter((event) => event.type === types.brightcove)
    .find((event) => {
      // get the first live event that has started
      return moment().isAfter(event.duration.starttime);
    });
};

const getCurrentEvent = (events) => {
  return events.find((event) => {
    return moment().isBetween(event.duration.starttime, event.duration.endtime);
  });
};

export default function UserMetaProvider({ children }) {
  const [user, updateUser, syncUser] = useUsersMetaData();
  const [agenda, setAgenda] = useState([]);
  const [programme, setProgramme] = useState([]);

  const [live, setLive] = useState(undefined);
  const [currentEvent, setCurrentEvent] = useState(undefined);
  // const [days, setDays] = useState([]);
  const [central, setCentral] = useState([]);

  const [hasFestivalStarted, setHasFestivalStarted] = useState(false);
  const [hasFestivalEnded, setHasFestivalEnded] = useState(false);
  const [hasDayStarted, setHasDayStarted] = useState(false);

  const { user_metadata } = user;
  const { country, events } = user_metadata;

  console.log("user: ", user);

  // event is run by central content
  const getEvents = () => {
    if (!country) return false;
    // SET UP PROGRAMME
    const { programme_jsons } = mapCountry2Region(country) || [];
    const programmePerRegion = programme_jsons
      .flatMap((key) => {
        return PROGRAMME_PER_REGIONS[key];
      })
      .filter((i) => i);

    const programmeForUser = [...CENTRAL, ...programmePerRegion];
    //console.log("programmeForUser: ", programmeForUser);
    const availableForUser = getContentForUserType(user, programmeForUser);
    // get events as hashmap AND as map-able arrays
    const { arrays, hashmap } = sortByDayAndTime(availableForUser);

    const eventsToday = getEventsForToday(arrays, hashmap);
    setHasFestivalStarted(getHasFestivalStarted());
    setHasFestivalEnded(getHasFestivalEnded(arrays));

    const live = getLiveEvent(eventsToday);
    setHasDayStarted(getHasDayStarted(eventsToday));
    setLive(live);
    const current = getCurrentEvent(eventsToday);
    setCurrentEvent(current);

    setProgramme(sortByDate(arrays.flat()));
    setCentral(sortByDate(CENTRAL)); // could be moved? Unrelated to content
    // END PROGRAMME
  };

  useEffect(() => {
    getEvents();
  }, [country]);

  useEffect(() => {
    if (!events) return false;
    const eventsForUser = events
      .map((e) => {
        return ALL_DATA.find((d) => e === d.uid);
      })
      .filter((e) => e);

    setAgenda(sortByDate(eventsForUser));
  }, [events]);

  // get next live
  const onLiveComplete = () => {
    setTimeout(() => {
      getEvents();
    }, 1000);
  };

  return (
    <UserMetaContext.Provider
      value={{
        user,
        updateUser,
        programme,
        agenda,
        syncUser,
        hasFestivalStarted,
        hasFestivalEnded,
        live,
        onLiveComplete,
        hasDayStarted,
      }}
    >
      {children}
    </UserMetaContext.Provider>
  );
}
