import React from "react";
import Styled from "styled-components";
import Modal from "react-modal";
import ReactGA from "react-ga";
import playButton from "./play.svg";
import closeButton from "./close.svg";
import "./globe.scss";
import { device } from "../../constants";

// TODO: This may need to be moved to a utility, as I'm reference it from a different folder
import Teams from "../../screens/main-stage/Player";

const videoStyles = {
  content: {
    position: "fixed",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    border: "medium none",
    background: "transparent none repeat scroll 0% 0%",
    overflow: "auto",
    borderRadius: "4px",
    outline: "currentcolor none medium",
    padding: "0 15px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: "50%",
    width: "100%",
    maxWidth: "42rem",
  },
  overlay: {
    position: "fixed",
    inset: 0, // Inset seems to work in iOS when passed as a JS style
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    zIndex: 50,
  },
};

const Row = Styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
`;

const Column = Styled.div`
  display: flex;
  flex-direction: column;
`;

const Heading = Styled.h2`
  font-size: 1.625rem; // 26px
  margin-bottom: 5px;
  font-family: 'Rajdhani',sans-serif;
`;

const Location = Styled.div`
  font-size: 1.4375rem; // 23px
  font-weight: 300;
  font-family: 'Rajdhani',sans-serif;
`;

const Content = Styled.div`
  line-height: 1.375rem;
`;

const Arrow = Styled.div`
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-top: 20px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
    z-index: 9999;
`;

const Rule = Styled.hr`
  border: 1px solid #ffffff;
`;

const EmbedVideo = Styled.div`
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  margin: 0 auto;


  &:before {
    display: block;
    content: "";
    padding-top: 56.25%;
  }

  & iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

const Button = Styled.button`
  border: 0;
  background: 0;
`;

const Close = Styled.img`
  width: 22px;

  @media ${device.desktop} {
    width: 30px;
  }

  @media ${device.huge} {
    width: 50px;
  }
`;

const ModalHeader = Styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 2px solid rgba(255, 255, 255, 0.4);
  margin-bottom: 30px;

  .marker-name {
    color: #ffffff;
    font-size: 1.25rem; // 18 pixels
    margin-bottom: 5px;
  }
  .marker-location {
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 40px;
    font-size: 1.25rem; // 18 pixels
  }

  @media ${device.desktop} {
    .marker-name {
      font-size: 1.5625rem; // 25 pixels
    }
  }

  @media ${device.huge} {
    .marker-name {
      font-size: 2.5rem; // 40 pixels
    }
    .marker-location {
      font-size: 2rem; // 32 pixels
    }
  }
`;

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

function Popover({ isOpen, onClose, marker }) {
  // Destructure with some defaults
  // NOTE: If marker is undefined then use empty object {}
  const {
    name = "Unknown",
    city = "Unknown",
    content = "",
    video = "",
  } = marker || {};

  const [isVideoOpen, setIsVideoOpen] = React.useState(false);
  // No need to show the modal if there's no marker content
  if (!marker) {
    return null;
  }

  return (
    <>
      <Modal
        closeTimeoutMS={1000}
        isOpen={isOpen}
        onRequestClose={onClose}
        className="globe-popover"
        overlayClassName="globe-popover-overlay"
      >
        {/* <button onClick={onClose}>close</button> */}
        <Row>
          <div>
            <Heading>{name}</Heading>
            {city && <Location>{city}</Location>}
          </div>
          <Button
            onClick={() => {
              ReactGA.event({
                category: "Globe",
                action: "Video played",
              });
              setIsVideoOpen(true);
            }}
          >
            <img src={playButton} alt={`Play ${name}'s video}`} />
          </Button>
        </Row>

        {marker?.content && (
          <>
            <Rule />
            <Content>
              <p>{marker?.content}</p>
            </Content>
          </>
        )}
      </Modal>

      <Modal
        isOpen={isVideoOpen}
        onRequestClose={() => setIsVideoOpen(false)}
        style={videoStyles}
      >
        <ModalHeader>
          <div>
            <h2 className="marker-name">{name}</h2>
            <p className="marker-location">{city}</p>
          </div>
          <Button onClick={() => setIsVideoOpen(false)}>
            <Close src={closeButton} alt={`Play ${name}'s video}`} />
          </Button>
        </ModalHeader>
        {marker?.video && <Teams link={marker.video} />}
      </Modal>
    </>
  );
}

export default Popover;
