import Styled from "styled-components";
import { device } from "../../constants";

import BgLg from "./images/bg-lg.png";
import BgMd from "./images/bg-md.png";
//import BgSm from "./images/bg-sm.png";

const Background = Styled.div`
  display: block;
  height: 100%;
  position: absolute;
  left: 0;
  max-width: 100%;
  width: 100%;
  z-index: 0;
  background: url(${BgMd}) no-repeat center center / cover;

  @media ${device.tablet} {
    background: url(${BgMd}) no-repeat center center / cover;
  }

  @media ${device.desktop} {
    background: url(${BgLg}) no-repeat center center / cover;
  }
`;

export default function LoginBg() {
  return <Background />;
}
