import Styled from "styled-components";
import { device, theme } from "../../../constants";
import pxToRem from "../../../utils/pxToRem";

const Iframe = Styled.iframe`
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const IframeWrapper = Styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 */
  background: ${theme.black};
`;

const Wrapper = Styled.div`
  width: 100%;

  @media ${device.desktop} {
    padding: 0 ${pxToRem(20)};
  }
`;

export default function Player({ link, children }) {
  return (
    <Wrapper>
      <IframeWrapper>
        {link ? (
          <Iframe
            title="video"
            src={link}
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          />
        ) : (
          children
        )}
      </IframeWrapper>
    </Wrapper>
  );
}
