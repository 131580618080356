import Styled from "styled-components";
import { FormattedMessage } from "react-intl";
import pxToRem from "../../../utils/pxToRem";
import { device } from "../../../constants";
import { ChevronLeft } from "../../../components/Icon";
import Cta from "../../../components/Cta";

const Wrapper = Styled.div`
  margin-top: ${pxToRem(10)};
  height: ${pxToRem(40)};

  @media ${device.tablet} {
    margin-top: ${pxToRem(24)};
  }

  @media ${device.desktop} {
    margin-top: 14vh;
  }
`;

// const Button = Styled.button`
//   border: none;
//   background: transparent;
//   text-transform: uppercase;
//   ${theme.copy_regular}
//   font-size: ${pxToRem(14)};
//   display: flex;
//   padding: ${pxToRem(10)};
//   color: ${theme.black};

//   &:focus {
//     text-decoration: underline;
//   }
// `;

const IconWrapper = Styled.div`
  width: ${pxToRem(5)};
  margin-right: ${pxToRem(10)};
`;

export default function Prev({ isHidden, onClick }) {
  return (
    <Wrapper>
      {!isHidden && (
        <Cta onClick={onClick} variant="secondary" size="small" uppercase>
          <IconWrapper>
            <ChevronLeft />
          </IconWrapper>
          <FormattedMessage
            id="onboarding.previous"
            defaultMessage="Translate this"
          />
        </Cta>
      )}
    </Wrapper>
  );
}
