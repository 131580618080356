import Styled from "styled-components";

import pxToRem from "../../../utils/pxToRem";
import { theme, device } from "../../../constants";

const Ul = Styled.ul`
  display: flex;
  margin-bottom: ${pxToRem(124)};
  margin-top: ${pxToRem(29)};
  justify-content: center;

  @media ${device.tablet} {
    margin-bottom: 15vh;
    margin-top: ${pxToRem(20)};
    justify-content: flex-start;
  }
`;

const Li = Styled.li`
  margin-right: ${pxToRem(10)};
`;

const Dot = Styled.div`
  width: ${pxToRem(10)};
  height: ${pxToRem(10)};
  border-radius: 50%;
  padding: 0;
  border: none;
  background: ${theme.white};


  ${(props) => props.isActive && `background: ${theme.primary}`}
`;

export default function Dots({ onClick, total, activeSlide }) {
  return (
    <Ul>
      {total.map((item, index) => {
        return (
          <Li key={index}>
            <Dot isActive={index === activeSlide} />
          </Li>
        );
      })}
    </Ul>
  );
}
