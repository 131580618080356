import { useContext } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import Styled from "styled-components";

import pxToRem from "../../../utils/pxToRem";
import { theme, device } from "../../../constants";
import { GaContext } from "../../../utils/ga-context";

import open from "./open.svg";
import close from "./close.svg";

const item = `
    border-bottom: ${pxToRem(1)} solid ${theme.light_grey_1};
`;

const StyledAccordionItem = Styled(AccordionItem)`
    ${item};
    padding: ${pxToRem(16)} 0;
`;

const StyledAccordionItemButton = Styled(AccordionItemButton)`
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`;

const StyledAccordionItemPanel = Styled(AccordionItemPanel)`
  padding: ${pxToRem(16)} 0 0;
`;

const Question = Styled.div`
    display: flex;
    align-items: center;

    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(24)};
    ${theme.copy_medium}

    @media ${device.tablet} {
        font-size: ${pxToRem(20)};
        line-height: ${pxToRem(32)};
    }
`;

const Answer = Styled.div`
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(24)};
    ${theme.copy_regular}
    color: ${theme.dark_grey_3};

    p {
        margin: 1em 0;
      }
    
    p:first-child {
    margin-top: 0;
    }

    a {
        color: ${theme.primary};
        text-decoration: none;

        &:link,
        &:visited,
        &:hover,
        &:focus {
            color: ${theme.primary};
        }

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
`;

const Section = Styled.div`
    margin-top: ${pxToRem(62)};
`;

const SectionTitle = Styled.h3`
    color: ${theme.dark_grey_1};
    font-size: ${pxToRem(20)};
    line-height: ${pxToRem(32)};
    ${theme.copy_bold}
    padding-bottom: ${pxToRem(8)};
    border-bottom: ${pxToRem(1)} solid ${theme.light_grey_1};

    @media ${device.tablet} {
        font-size: ${pxToRem(28)};
        line-height: ${pxToRem(34)};
    }
`;

const Wrapper = Styled.div`
    margin-top: ${pxToRem(24)};
`;

export default function Questions({ data, dataInEn }) {
  const { trackEvent } = useContext(GaContext);

  return (
    <Wrapper>
      {data.map((section, index) => {
        return (
          <Section key={section.section}>
            <SectionTitle>{section.section}</SectionTitle>
            <Accordion
              allowZeroExpanded
              onChange={(uuid) => {
                if (uuid[0]) {
                  const question = dataInEn[index].questions.find(
                    (i) => i.id === uuid[0]
                  );
                  trackEvent("Help questions", question.question);
                }
              }}
            >
              {section.questions.map((item) => {
                return (
                  <StyledAccordionItem key={item.question} uuid={item.id}>
                    <AccordionItemHeading>
                      <StyledAccordionItemButton>
                        <AccordionItemState>
                          {({ expanded }) => (
                            <>
                              <Question>{item.question}</Question>
                              <img src={expanded ? open : close} alt="" />
                            </>
                          )}
                        </AccordionItemState>
                      </StyledAccordionItemButton>
                    </AccordionItemHeading>
                    <StyledAccordionItemPanel>
                      <Answer
                        dangerouslySetInnerHTML={{ __html: item.answer }}
                      />
                    </StyledAccordionItemPanel>
                  </StyledAccordionItem>
                );
              })}
            </Accordion>
          </Section>
        );
      })}
    </Wrapper>
  );
}
