import Styled from "styled-components";
import { FormattedMessage } from "react-intl";

import { theme, device } from "../../../constants";
import pxToRem from "../../../utils/pxToRem";
import Cta from "../../../components/Cta";

import iconNonSticky from "./icon-non-sticky.svg";
import sync from "./sync.svg";
import check from "./check.svg";

const Wrapper = Styled.div`
    padding: ${pxToRem(0)} ${pxToRem(20)} ${pxToRem(15)};

    @media ${device.tablet} {
        padding: ${pxToRem(0)} ${pxToRem(121)} ${pxToRem(25)};
    }

    @media ${device.desktop} {
        padding: ${pxToRem(0)} ${pxToRem(165)} ${pxToRem(50)};
    }
`;

const Inner = Styled.div`
    border: ${pxToRem(1)} solid ${theme.light_grey_1};
    padding: ${pxToRem(5)} ${pxToRem(20)} ${pxToRem(23)};
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${theme.dark_grey_1};
    text-align: center;

    @media ${device.tablet} {
        flex-direction: row;
        text-align: left;
        padding: ${pxToRem(7)} ${pxToRem(28)} ${pxToRem(7)} ${pxToRem(24)};
        justify-content: space-between;
    }
`;

const Content = Styled.div`
    @media ${device.tablet} {
        display: flex;
    }
`;

const CopyWrapper = Styled.div`
    @media ${device.tablet} {
        margin-left: ${pxToRem(24)};
        margin-right: ${pxToRem(24)};
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`;

const CtaWrapper = Styled.div`
    flex-shrink: 0;
`;

const Img = Styled.img``;

const Title = Styled.h3`
    ${theme.copy_bold}
    font-size: ${pxToRem(20)};
    line-height: ${pxToRem(32)};
`;

const Copy = Styled.p`
    ${theme.copy_regular}
    font-size: ${pxToRem(12)};
    line-height: ${pxToRem(18)};
    margin-bottom: ${pxToRem(13)};

    @media ${device.desktop} { 
        margin-bottom: 0;   
        font-size: ${pxToRem(14)};    
        line-height: ${pxToRem(22)}; 
    }
`;

const Icon = Styled.img`
    margin-right: ${pxToRem(6)};
`;

export default function Sync({ shouldSync, onClick }) {
  return (
    <Wrapper>
      <Inner>
        <Content>
          <Img src={iconNonSticky} alt="" />
          <CopyWrapper>
            <Title>
              <FormattedMessage
                id="agenda.sync.title"
                defaultMessage="TRANSLATE"
              />
            </Title>
            <Copy>
              <FormattedMessage
                id="agenda.sync.copy"
                defaultMessage="TRANSLATE"
              />
            </Copy>
          </CopyWrapper>
        </Content>
        <CtaWrapper>
          <Cta
            uppercase
            onClick={() => onClick()}
            size="small"
            disabled={shouldSync ? false : true}
            as={shouldSync ? "button" : "span"}
            variant={shouldSync ? "primary" : "invited"}
            hasIcon
          >
            <Icon src={shouldSync ? sync : check} alt="" />
            <FormattedMessage id="agenda.sync.cta" defaultMessage="translate" />
          </Cta>
        </CtaWrapper>
      </Inner>
    </Wrapper>
  );
}
