import { useRef, useCallback, useState, useEffect, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Styled from "styled-components";
import { NavLink, Link, useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useClickAway } from "react-use";
import Countdown from "react-countdown";

import { LocaleContext } from "../../utils/locale-context";
import { UserMetaContext } from "../../utils/metadata-context";
import { GaContext } from "../../utils/ga-context";
import { useMediaQuery } from "../../utils/use-media-query";
import pxToRem from "../../utils/pxToRem";
import { theme, device, routes, auth0Keys } from "../../constants";
import chevronDownIcon from "./assets/chevron-down.svg";

import VisuallyHidden from "../VisuallyHidden";
import LanguageSelector from "./LanguageSelector";
import Logo from "../Logo";

import burgerIcon from "./assets/burger.svg";
import liveIcon from "./assets/live.svg";
import Drawer from "./Drawer";
import LINKS from "./links";

import { HEIGHTS, LANGUAGES } from "./consts";

const Offset = Styled.div`
  padding-top: ${pxToRem(HEIGHTS.sm)};
`;

const Header = Styled.header`
  height: ${pxToRem(HEIGHTS.sm)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${pxToRem(25)} 0 ${pxToRem(20)};
  position: fixed;
  top: 0;
  width: 100%;
  background: ${theme.black};
  z-index: 2;
`;

const LogoWrapper = Styled(Link)`
  width: ${pxToRem(198)};
`;

const BurgerButton = Styled.button`
  border: none;
  background: transparent;
  padding: 0;

  @media ${device.desktop} {
    display: none;
  }
`;
const BurgerImg = Styled.img``;

const StyledNav = Styled.nav`
  margin-left: ${pxToRem(45)};
  height: 100%;

  @media ${device.tabletMax} {
    display: none;
  }
`;
const Ul = Styled.ul`
  display: flex;
  height: 100%;
`;

const Li = Styled.li`
  margin-right: ${pxToRem(32)};
`;

const Left = Styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  display: flex;
  align-items: center;
`;

const Right = Styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  @media ${device.tabletMax} {
    display: none;
  }
`;

const NavItem = Styled(NavLink)`
  ${theme.copy_regular}
  color: ${theme.white};
  ${theme.copy_regular}
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(24)};
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
  padding: 0;
  border-bottom: ${pxToRem(3)} solid transparent;

  ${(props) =>
    props.isActive &&
    `
    border-bottom: ${pxToRem(3)} solid ${theme.secondary};
    ${theme.copy_medium}
  `}
  
  &.active {
    border-bottom: ${pxToRem(3)} solid ${theme.secondary};
    ${theme.copy_medium}
  }

  &:hover,
  &:focus {
    border-bottom: ${pxToRem(3)} solid ${theme.secondary};
  } 
`;

const NavInner = Styled.span`
  ${(props) =>
    props.$isLive &&
    `
    background: ${theme.secondary};
    color: ${theme.white};
    display: flex;
    align-items: center;
    padding: ${pxToRem(2)} ${pxToRem(5)} ${pxToRem(2)} ${pxToRem(2)};
  `}
`;

const LangSelectorButton = Styled.button`
  border: none;
  background: ${theme.black};
  border-radius: ${pxToRem(19)};
  padding: ${pxToRem(4)};
  margin-left: ${pxToRem(16)};
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover,
  &:focus {
    background: ${theme.black};
  }
`;

const Flag = Styled.img`
  width: ${pxToRem(24)};
`;

const HiddenCountdown = Styled.div`
  display: none;
`;

export default function Nav({ hideNav }) {
  const { locale, selectLanguage, moment } = useContext(LocaleContext);
  const { user, updateUser, live, onLiveComplete } =
    useContext(UserMetaContext);
  const { trackEvent } = useContext(GaContext);

  //const location = useLocation();

  const { isAuthenticated, logout } = useAuth0();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLangSelectorOpen, setIsLangSelectorOpen] = useState(false);
  const [isLive, setIsLive] = useState(
    moment().isAfter(live?.duration?.starttime)
  );

  const langRef = useRef();
  const isDesktop = useMediaQuery(device.desktop);

  useClickAway(langRef, () => {
    if (isDesktop) {
      setIsLangSelectorOpen(false);
    }
  });

  const [selectedLang, setSelectedLang] = useState(
    LANGUAGES.find(({ id }) => id === locale)
  );
  const userHasCompletedOnboarding =
    user?.user_metadata[auth0Keys.HAS_COMPLETED_ONBOARDING];

  const handleLanguageSelect = useCallback(
    (value) => {
      trackEvent("Change language", value.id);
      setIsLangSelectorOpen(false);
      if (isAuthenticated) {
        // update user meta data if user is logged in
        updateUser({ locale: value.id });
      } else {
        selectLanguage(value.id);
      }
    },
    [updateUser, isAuthenticated, selectLanguage, trackEvent]
  );

  useEffect(() => {
    setSelectedLang(LANGUAGES.find(({ id }) => id === locale));
  }, [locale]);

  useEffect(() => {
    //setCurrentLive(live);
    setIsLive(moment().isAfter(live?.duration?.starttime));
  }, [live]);

  const handleLogout = useCallback(() => {
    trackEvent("Logout", "User logout");
    logout({
      returnTo: window.location.origin,
    });
  }, [trackEvent, logout]);

  return (
    <>
      {live && (
        <HiddenCountdown id="global-countdown">
          <div id="global-countdown-start">
            <Countdown
              date={live.duration.starttime}
              onComplete={() => setIsLive(true)}
              key={live.duration.starttime}
            />
          </div>
          <div id="global-countdown-end">
            <Countdown
              date={live.duration.endtime}
              onComplete={() => {
                setIsLive(false);
                onLiveComplete();
              }}
              key={live.duration.endtime}
            />
          </div>
        </HiddenCountdown>
      )}
      <Offset />
      <Header>
        <Left>
          <LogoWrapper to={routes.HOME}>
            <VisuallyHidden>
              <FormattedMessage
                id="nav.links.home"
                defaultMessage="Translate this"
              />
            </VisuallyHidden>
            <Logo />
          </LogoWrapper>
          {isAuthenticated && !hideNav && userHasCompletedOnboarding && (
            <StyledNav>
              <Ul>
                {LINKS.map((link) => {
                  const itemIsLive = link.isMain && isLive;
                  return (
                    <Li key={link.url}>
                      <NavItem to={link.url} key={link.label} exact={true}>
                        <NavInner $isLive={itemIsLive}>
                          {itemIsLive && <img src={liveIcon} alt="" />}
                          <FormattedMessage
                            id={link.label}
                            defaultMessage="Translate this"
                          />
                        </NavInner>
                      </NavItem>
                    </Li>
                  );
                })}
              </Ul>
            </StyledNav>
          )}
        </Left>
        <Right>
          {isAuthenticated && !hideNav && userHasCompletedOnboarding && (
            <NavItem as="button" onClick={handleLogout}>
              <FormattedMessage
                id="nav.links.logout"
                defaultMessage="Translate this"
              />
            </NavItem>
          )}

          <div ref={langRef}>
            <LangSelectorButton
              onClick={() => setIsLangSelectorOpen(!isLangSelectorOpen)}
            >
              <Flag src={selectedLang.img} alt="" />
              <img src={chevronDownIcon} alt="" />
            </LangSelectorButton>
            <LanguageSelector
              isOpen={isLangSelectorOpen}
              selectedLang={selectedLang}
              onSelectLanguage={handleLanguageSelect}
            />
          </div>
        </Right>

        <BurgerButton onClick={() => setIsDrawerOpen(true)}>
          <BurgerImg src={burgerIcon} />
        </BurgerButton>
      </Header>
      <Drawer
        anchor="right"
        isOpen={isDrawerOpen}
        onCloseDrawer={() => {
          setIsDrawerOpen(false);
          setIsLangSelectorOpen(false);
        }}
        onLogout={handleLogout}
        isAuthenticated={
          isAuthenticated && !hideNav && userHasCompletedOnboarding
        }
        isLangSelectorOpen={isLangSelectorOpen}
        onToggleLangSelector={() => setIsLangSelectorOpen(!isLangSelectorOpen)}
        selectedLang={selectedLang}
        onSelectLanguage={handleLanguageSelect}
        isLive={isLive}
      />
    </>
  );
}
