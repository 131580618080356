import React from "react";
import styled from "styled-components";

import { device } from "../../constants";
import loadingImg from "./spinner.svg";
import BgLg from "../../screens/login/images/bg-lg.png";

const Spinner = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url(${BgLg}) no-repeat center center / cover;

  @media ${device.tablet} {
  }

  @media ${device.desktop} {
  }

  img {
    width: 82px;
    height: 82px;
  }
`;

const Loading = () => (
  <Spinner>
    <img src={loadingImg} alt="Loading..." />
  </Spinner>
);

export default Loading;
