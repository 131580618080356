import React, { useState, createContext, useRef, useEffect } from "react";
import { IntlProvider } from "react-intl";
import queryString from "query-string";
import moment from "moment";
import useLocalStorage from "./use-local-storage";

import { English, German, Spanish, French, Italian, Russian } from "../lang";
const LocaleContext = createContext();

export { LocaleContext };

const META_PER_LOCALE = {
  en: {
    title: "Countdown to mars",
    desc: "",
  },
  de: {
    title: "Countdown to mars",
    desc: "",
  },
  es: {
    title: "Countdown to mars",
    desc: "",
  },
  ru: {
    title: "Countdown to mars",
    desc: "",
  },
  it: {
    title: "Countdown to mars",
    desc: "",
  },
  fr: {
    title: "Countdown to mars",
    desc: "",
  },
};

const copyPerLocale = {
  en: English,
  fr: French,
  it: Italian,
  es: Spanish,
  ru: Russian,
  de: German,
};

const userLocale = navigator.language.split("-")[0];
const isSupportedLocale = (locale) => !!copyPerLocale[locale];

export default function LocaleProvider({ children }) {
  const { lang: urlLocale } = queryString.parse(window.location.search);
  const [storedLocale, setStoredLocale] = useLocalStorage("locale");

  const getDefaultLocale = () => {
    if (urlLocale && copyPerLocale[urlLocale]) return urlLocale; // if url param exist and is supported
    if (storedLocale) return storedLocale;
    if (isSupportedLocale(userLocale)) return userLocale;
    return "en";
  };

  const defaultLocale = getDefaultLocale();
  const [locale, setLocale] = useState(defaultLocale);
  const [messages, setMessages] = useState(copyPerLocale[defaultLocale]);

  const momentRef = useRef();

  function selectLanguage(value) {
    const getValue = () => {
      if (value) return value; // from auth0 or lang Dropdown
      if (urlLocale && copyPerLocale[urlLocale]) return urlLocale; // if url param exist and is supported
      if (storedLocale) return storedLocale; // from local useLocalStorage
      if (isSupportedLocale(userLocale)) return userLocale; // user default language
      return "en";
    };

    const locale = getValue();
    setLocale(locale);
    setStoredLocale(locale);
    setMessages(copyPerLocale[locale]);
    momentRef.current = moment.locale(locale);
  }

  useEffect(() => {
    document.title = META_PER_LOCALE[locale].title;
    const el = document.querySelector("meta[name='description']");
    el.setAttribute("content", META_PER_LOCALE[locale].desc);
  }, [locale]);

  return (
    <LocaleContext.Provider value={{ locale, selectLanguage, moment }}>
      <IntlProvider
        messages={messages}
        locale={locale}
        moment={momentRef.current}
      >
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
}
