import { useState, useContext } from "react";
import Styled from "styled-components";
import { FormattedMessage } from "react-intl";

import pxToRem from "../../../../utils/pxToRem";
import { device, auth0Keys, theme } from "../../../../constants";
import Cta from "../../../../components/Cta";
import { GaContext } from "../../../../utils/ga-context";
import { Wrapper, Title, Copy, Content } from "../styled";
import { renderCheckboxes } from "../checkboxes";

import Sm from "./sm.svg";
import Md from "./md.png";
import Lg from "./lg.svg";
import BorderSm from "./border-sm.svg";

const CheckboxesLg = Styled.div`
  display: none;
  color: ${theme.white};

  @media ${device.desktop} {
    display: flex;
    flex-direction: column;
  }
`;

const CheckboxesSm = Styled.div`
  margin-top: ${pxToRem(53)};
  color: ${theme.white};
  @media ${device.tablet} {
    display: none;
  }
`;

const CheckboxesMd = Styled.div`
  margin-top: ${pxToRem(48)};
  display: none;
  color: ${theme.white};

  @media ${device.tablet} {
    display: block;

  }

  @media ${device.desktop} {
    display: none;
  }
`;

const Split = Styled.div`
  ${(props) =>
    props.hasBackground
      ? `
      background: url(${Sm}) no-repeat top right / contain;
      height: ${pxToRem(21)};
    `
      : `
        @media ${device.mobile} {
          background: url(${BorderSm}) no-repeat bottom left / cover;

        }
        flex: 1;
    `}

  @media ${device.tablet} {
    ${(props) =>
      props.hasBackground
        ? `
        background: url(${Md}) no-repeat bottom center / cover;
        height: ${pxToRem(370)};
      `
        : "flex: 1;"}


  }

  @media ${device.desktop} {
    ${(props) =>
      props.hasBackground &&
      `
        background: url(${Lg}) no-repeat top left / cover;
        height: unset;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      `}

    flex: 1 0 50%;
  }
`;

const Inner = Styled.div``;

const ButtonWrapper = Styled.div`
  margin-top: ${pxToRem(20)};
  max-width: 100%;

  @media ${device.tablet} {
    margin-top: ${pxToRem(26)};
    max-width: ${pxToRem(220)};
    margin-bottom: ${pxToRem(40)};
  }

  @media ${device.desktop} {
    margin-top: ${pxToRem(26)};
    max-width: ${pxToRem(220)};
    margin-bottom: 0;
  }
`;

const OPTIONS = [
  {
    value: "aerospace",
    checked: false,
  },
  {
    value: "training",
    checked: false,
  },
  {
    value: "biology",
    checked: false,
  },
  {
    value: "future",
    checked: false,
  },
  {
    value: "geology",
    checked: false,
  },
  {
    value: "human",
    checked: false,
  },
];

export default function Browse({ onNext, dots, prev }) {
  const { trackEvent } = useContext(GaContext);
  const [options, setOptions] = useState(OPTIONS);

  const onChange = (index, evt) => {
    setOptions((options) =>
      options.map((option, i) => {
        if (i === index && evt.target.checked) {
          trackEvent("Make it yours", `${option.value}`);
        }
        return {
          ...option,
          checked: i === index ? evt.target.checked : option.checked,
        };
      })
    );
  };

  const renderCta = () => {
    return (
      <ButtonWrapper>
        <Cta
          uppercase
          onClick={() => {
            onNext({
              [auth0Keys.TECH]: options
                .filter((option) => option.checked)
                .map((option) => option.value),
            });
          }}
          disabled={options.every((option) => !option.checked)}
        >
          <FormattedMessage
            id="onboarding.slide.5.button"
            defaultMessage="Translate this"
          />
        </Cta>
      </ButtonWrapper>
    );
  };

  return (
    <Wrapper>
      <Split>
        <Content>
          {prev()}
          <Inner>
            <Title>
              <FormattedMessage
                id="onboarding.slide.5.title"
                defaultMessage="Translate This"
              />
            </Title>
            <Copy>
              <FormattedMessage
                id="onboarding.slide.5.copy"
                values={{ b: (b) => <strong>{b}</strong> }}
                defaultMessage="Translate this string for the Make It festival.We have 2 weeks packed with inspiring content and sessions to help enhance your printing operation."
              />
            </Copy>
            <CheckboxesSm>
              {renderCheckboxes(options, onChange, "data.subgroups.", 1)}
              {renderCta()}
            </CheckboxesSm>
            <CheckboxesMd>
              {renderCheckboxes(options, onChange, "data.subgroups.", 2)}
              {renderCta()}
            </CheckboxesMd>
          </Inner>
          {dots()}
        </Content>
      </Split>
      <Split hasBackground>
        <CheckboxesLg>
          {renderCheckboxes(options, onChange, "data.subgroups.")}
          {renderCta()}
        </CheckboxesLg>
      </Split>
    </Wrapper>
  );
}
