import Page from "../../components/Page";

import Header from "./header";
import Body from "./Body";

export default function Expert() {
  return (
    <Page>
      <Header />
      <Body />
    </Page>
  );
}

// Here is the gate id for the EU master – Meet the experts form;
// #gate-2f7632e4-6472-4030-b139-3692eef8e785
// Or
// <div class='gate-2f7632e4-6472-4030-b139-3692eef8e785'></div>
