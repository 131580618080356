import { useContext } from "react";
import Styled from "styled-components";
import { FormattedMessage } from "react-intl";

import Cta from "../Cta";
import mailIcon from "./mail.svg";
import pxToRem from "../../utils/pxToRem";
import { theme } from "../../constants";
import LinkCta from "../../components/LinkCta";
import { GaContext } from "../../utils/ga-context";

const Wrapper = Styled.div``;

const Inner = Styled.span`
    display: flex;
    align-items: center;
`;
const Icon = Styled.img`
    margin-right: ${pxToRem(6)};
`;

const Copy = Styled.p`
    ${theme.copy_regular}
    margin-top: ${pxToRem(12)};
    font-size: ${pxToRem(12)};
    line-height: ${pxToRem(18)};
    margin-bottom: ${pxToRem(12)};
`;

export default function GoToWebinar({
  item,
  size,
  webinarids,
  onClick,
  gaActionPrefix,
  gaTitle,
}) {
  const { trackEvent } = useContext(GaContext);
  const id = item["url-gtw-msteams"];
  const isInvited = webinarids.find((i) => i === id);
  return (
    <Wrapper>
      <Cta
        size={size}
        variant={isInvited ? "invited" : "primary"}
        uppercase
        disabled={isInvited}
        onClick={() => {
          trackEvent(`${gaActionPrefix} GTW invite request`, gaTitle);
          onClick();
        }}
        hasIcon={isInvited}
      >
        <Inner>
          {isInvited && <Icon src={mailIcon} alt="" />}
          <FormattedMessage
            id={
              isInvited
                ? "event.cta.gotowebinar.invited"
                : "event.cta.gotowebinar.request"
            }
            defaultMessage="translate"
          />
        </Inner>
      </Cta>
      {isInvited && (
        <Copy>
          <FormattedMessage
            id="event.gotowebinar.sent.short"
            defaultMessage="translate"
          />
        </Copy>
      )}
      {isInvited && (
        <LinkCta
          onClick={() => {
            trackEvent(`${gaActionPrefix} GTW invite resend`, gaTitle);
            onClick();
          }}
          size="small"
          uppercase
          bold
        >
          <FormattedMessage
            id="event.gotowebinar.sent.again"
            defaultMessage="translate"
          />
        </LinkCta>
      )}
    </Wrapper>
  );
}
