import { useEffect, useContext } from "react";
import Page from "../../components/Page";
import { GaContext } from "../../utils/ga-context";

import Header from "./Header";
import Body from "./Body";

export default function Privacy() {
  const { trackEvent } = useContext(GaContext);
  useEffect(() => {
    trackEvent("Hygiene - Privacy", "Privacy");
  }, []);
  return (
    <Page>
      <Header />
      <Body />
    </Page>
  );
}
