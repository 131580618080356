const theme = {
  primary: "#D2322E",
  primary_dark: "#D2322E",
  primary_medium: "#D2322E",
  primary_light: "#D2322E",
  secondary: "#CC0000",
  tertiary: "#2AFFFF",
  invited: "#982171",
  red_dark_1: "#A30000",

  base: "#1D1D1D",
  grey_6: "#F2F2F2",

  black: "#000000",
  white: "#FFFFFF",
  light_grey_1: "#D9DBDC",
  light_grey_2: "#ECEDED",
  light_grey_3: "#F5F6F6",
  dark_grey: "#575757",
  dark_grey_1: "#323232",
  dark_grey_2: "#2C2C2C",
  dark_grey_3: "#6E7276",
  dark_grey_4: "#939598",

  headline_regular: `
    font-family: 'Rajdhani', sans-serif;
    font-weight: 400;
    font-style: normal;
  `,
  headline_medium: `
    font-family: 'Rajdhani', sans-serif;
    font-weight: 500;
    font-style: normal;
  `,
  headline_bold: `
    font-family: 'Rajdhani', sans-serif;
    font-weight: 600;
    font-style: normal;
  `,
  copy_regular: `
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-style: normal;
  `,
  copy_medium: `
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-style: normal;
  `,
  copy_bold: `
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-style: normal;
  `,
};

export default theme;
