import Sm1 from "./sm/sm-1.png";
// import Sm2 from "./sm/sm-1.png";
// import Sm3 from "./sm/sm-1.png";
// import Sm4 from "./sm/sm-1.png";

import Md1 from "./md/md-1.png";
// import Md2 from "./md/md-2.png";
// import Md3 from "./md/md-3.png";
// import Md4 from "./md/md-4.png";

import Lg1 from "./lg/lg-1.png";
// import Lg2 from "./lg/lg-2.jpg";
// import Lg3 from "./lg/lg-3.jpg";
// import Lg4 from "./lg/lg-4.jpg";

import thumb1 from "./thumbnails/thumb1.png";
import thumb2 from "./thumbnails/thumb2.png";
import thumb3 from "./thumbnails/thumb3.png";

const images = {
  header: {
    1: { sm: Sm1, md: Md1, lg: Lg1 },
    2: { sm: Sm1, md: Md1, lg: Lg1 },
    3: { sm: Sm1, md: Md1, lg: Lg1 },
    4: { sm: Sm1, md: Md1, lg: Lg1 },
  },
  body: {
    1: { sm: thumb1, md: thumb1, lg: thumb1 },
    2: { sm: thumb2, md: thumb2, lg: thumb2 },
    3: { sm: thumb3, md: thumb3, lg: thumb3 },
  },
};
export default images;
