import React, { useEffect, useState } from "react";
import Styled from "styled-components";

// NOTE: Using a PR that has touch events
// https://github.com/chrisrzhou/react-globe/pull/53
import ReactGlobe from "react-globe";
import ReactGA from "react-ga";

// Responsive utils
import { useWindowSize } from "./resize";
import { device } from "../../constants";
import animations from "../../constants/animations";

import defaultMarkers from "./markers";
import globeImage from "./mars_texture.jpg";
import cloudImage from "./clouds2_03.png";
import backgroundImage from "./background_newcosmos_04.jpg";
import staticImage from "./static-globe.jpg";

import markerRenderer from "./marker";
import Popover from "./modal";

const Wrapper = Styled.div`
  display: flex;
  height: 100vh;

  @media ${device.desktop} {
    align-items: center;
  }
`;

const GlobeWrapper = Styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  @media ${device.desktop} {
    height: calc(100% + 15vh);
    top: -100px;
  }

  // @media ${device.huge} {
  //   height: calc(100% + 30vh);
  //   top: -30vh;
  // }
`;

const StaticWrapper = Styled.div`
  background: transparent url("${staticImage}") no-repeat center center;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media ${device.desktop} {
    background-position: center center;
  }

  @media ${device.huge} {
    background-position: center center;
  }
`;

const Content = Styled.div`
  // Stop the content interfering with the map interactions
  pointer-events: none;

  position: relative;
  z-index: 10;
  color: #ffffff;

  text-align: center;
  margin-top: 100px;
  width: 100%;

  br {
    display: none;
  }

  h1 {
    font-family: 'Rajdhani',sans-serif;
    text-transform: uppercase;
    // margin-bottom: 30px;
    font-size: 4rem; // 32px
    font-weight: 500;
    line-height: 3rem; // 32px
    margin-bottom: 10px;
    text-shadow: 0px 0px 20px #000000BF;
  }
  h2 {
    font-family: 'Rajdhani',sans-serif;
    text-transform: uppercase;
    // margin-bottom: 30px;
    font-size: 1.8rem; // 32px
    font-weight: 500;
    line-height: 3rem; // 32px
    margin-bottom: 10px;
    text-shadow: 0px 0px 20px #000000BF;
  }
  p {
    font-family: 'Montserrat',sans-serif;
    font-weight: 400;
    font-size: 0.9375rem; // 11 px
    line-height: 1.2rem; // 19 px
    width: 75%;
    margin: 0 auto 15px auto;
    text-shadow: 0px 0px 10px #000000BF;
  }
  strong {
    font-weight: 500;
  }

  @media ${device.tablet} {
    h1 {
      font-size: 4.375rem; // 70px
      line-height: 3.75rem; // 60px
      margin-bottom: 20px;
    }

    p {
      width: 50%;
    }
    br {
      display: inline;
    }
  }

  @media ${device.desktop} {
    width: 30%;
    margin-left: 50px;
    margin-top: 0;
    margin-bottom: 10vh;
    text-align: left;

    br {
      display: inline;
    }

    p {
      width: 100%;
    }

    h1 {
      font-size: 5.625rem; // 90px
      line-height: 5rem; // 80px
    }
  }

  @media ${device.huge} {
    margin-left: 100px;

    br {
      display: inline;
    }
    h1 {
      font-size: 8.75rem; // 140px
      line-height: 7.5rem; // 120px
    }

    p {
      font-size: 1.5625rem; // 25px
      line-height: 1.875rem; // 30px
    }
  }
`;

const edge = !("reversed" in document.createElement("ol"));

const content = !edge ? (
  <Content>
    <h2>VIRTUAL ASTRONAUT</h2>
    <h1>
      EXPLORE
      <span>
        <br />{" "}
      </span>
      MARS
    </h1>
    <p>
      Navigate the surface of Mars and discover the research sites of previous
      missions.
    </p>
  </Content>
) : (
  <Content>
    <h1>Please use a newer browser</h1>
    <p>
      This section allows you to explore Mars, but you'll need to use a newer
      browser to explore the content.
    </p>
    <p>
      <strong>Please update Microsoft Edge or use Chrome</strong>
    </p>
  </Content>
);

//focusDistanceRadiusScale: 2 // no movement

function Globe() {
  const [globe, setGlobe] = useState();
  const [focus, setFocus] = useState(null); // we can use this to manually set focus if needed
  const [event, setEvent] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [details, setDetails] = useState(null);

  // Create options as state, so we can change them dynamically
  const [options, setOptions] = useState({
    enableCameraZoom: false,
    enableCameraAutoRotate: true,
    cameraAutoRotateSpeed: animations.cameraAutoRotateSpeed,
    cameraDistanceRadiusScale: 5, // Need to work out how to update this
    cameraRotateSpeed: 0.7,
    focusDistanceRadiusScale: 2.5,
    focusAnimationDuration: 1000,
    enableMarkerGlow: false,
    enableMarkerTooltip: false,
    markerRenderer: markerRenderer,
    markerOffsetRadiusScale: 0.01,
    enableDefocus: true,
    globeGlowColor: "#a21c38",
    pointLightIntensity: 0.5,
    globeGlowPower: 3,
    globeGlowRadiusScale: 0.2,
    globeCloudsOpacity: 0.2,
  });

  // Placeholders to be populated responsively
  let globeResponsiveness = {};

  const { width, height } = useWindowSize();

  // Only run once the globe object exists
  useEffect(() => {
    if (!globe) {
      return;
    }

    // Wait a second and re-add the same options
    setTimeout(() => {
      const tempOptions = { ...options };
      setOptions(tempOptions);
    }, 1000);
  }, [globe]);

  // Setting up React globe responsive variables
  if (width >= device.lg) {
    globeResponsiveness = {
      width: "130vw",
      height: "160vh",
    };
  } else if (width >= device.md) {
    globeResponsiveness = {
      width: "130vw",
      height: "130vh",
    };
  } else {
    globeResponsiveness = {
      width: "130vw",
      height: "120vh",
    };
  }

  const onClickMarker = (marker, markerObject, event) => {
    // Locking the globe stops clicking during the transition
    ReactGA.event({
      category: "Globe",
      action: "hotspot triggered",
    });

    globe.lock();
    setModalIsOpen(true);

    setTimeout(() => {
      setFocus(marker);
    }, animations.globe / 2);

    // Once zoomed in, show modal and stop rotation
    setTimeout(() => {
      globe.unlock();

      // Stop camera auto rotate
      const tempOptions = { ...options };
      tempOptions.enableCameraAutoRotate = false;
      setOptions(tempOptions);
    }, animations.globe);
  };

  function onDefocus(previousFocus) {
    setModalIsOpen(false);

    setTimeout(() => {
      setFocus(null);
    }, animations.globe / 2);

    setTimeout(() => {
      const tempOptions = { ...options };
      tempOptions.enableCameraAutoRotate = true;
      setOptions(tempOptions);
    }, animations.globe * 1.5);
  }

  return (
    <div>
      <Popover isOpen={modalIsOpen} onClose={onDefocus} marker={focus} />

      <Wrapper>
        {content}
        <GlobeWrapper>
          {!edge ? (
            <ReactGlobe
              initialCameraDistanceRadiusScale={5}
              height={globeResponsiveness.height || "100vh"}
              markers={defaultMarkers}
              options={options}
              width={globeResponsiveness.width || "100vw"}
              onClickMarker={onClickMarker}
              // onTouchMarker={onClickMarker}
              // onDefocus={onDefocus}
              globeTexture={globeImage}
              globeCloudsTexture={cloudImage}
              globeBackgroundTexture={backgroundImage}
              onGetGlobe={setGlobe}
              focus={focus?.coordinates}
            />
          ) : (
            <StaticWrapper />
          )}
        </GlobeWrapper>
      </Wrapper>
    </div>
  );
}

export default Globe;
