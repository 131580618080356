import { useContext } from "react";
import { FormattedMessage } from "react-intl";
import Styled from "styled-components";

import { LocaleContext } from "../../utils/locale-context";
import pxToRem from "../../utils/pxToRem";
import { theme, device } from "../../constants";
import VisuallyHidden from "../VisuallyHidden";

import chevronNext from "./assets/chevron-right.svg";
import chevronPrev from "./assets/chevron-left.svg";

const StyledHeader = Styled.div`
    display: flex;
    ${theme.copy_bold}
    font-size: ${pxToRem(20)};
    line-height: ${pxToRem(32)};
`;

const Arrows = Styled.div`
    flex: 0 0 auto;
    display: flex;
    align-items: center;

    @media ${device.desktop} {
      margin-left: ${pxToRem(16)};
    }
`;

const Date = Styled.span`
    flex: 1 1 auto;

    @media ${device.desktop} {
      flex: 0 0 auto;
    }
`;

const Arrow = Styled.button`
    border: none;
    background: transparent;
    padding: 0;
`;

const ArrowImg = Styled.img``;

export default function Header({ date, onPrev, onNext, index, total }) {
  const { moment } = useContext(LocaleContext);
  return (
    <StyledHeader>
      <Date>{moment(date).format("dddd, Do MMMM")}</Date>
      {total && (
        <Arrows>
          <Arrow onClick={onPrev} disabled={index === 0}>
            <VisuallyHidden>
              <FormattedMessage
                id="component.carousel.prev"
                defaultMessage="translate"
              />
            </VisuallyHidden>
            <ArrowImg src={chevronPrev} alt="" />
          </Arrow>
          <Arrow onClick={onNext} disabled={index === total - 1}>
            <VisuallyHidden>
              <FormattedMessage
                id="component.carousel.next"
                defaultMessage="translate"
              />
            </VisuallyHidden>
            <ArrowImg src={chevronNext} alt="" />
          </Arrow>
        </Arrows>
      )}
    </StyledHeader>
  );
}
