import Styled from "styled-components";

import { theme, device } from "../../../constants";
import pxToRem from "../../../utils/pxToRem";
import { formatSpeakers } from "../../../utils/data";

const Wrapper = Styled.div`
    display: flex;
    flex-direction: column;
    padding-top: ${pxToRem(30)};
    border-top: ${pxToRem(1)} solid ${theme.light_grey_1};

    @media ${device.tablet} {
        flex-direction: row;
        flex-wrap: wrap;
    }
`;

const Speaker = Styled.div`
    display: flex;
    margin-bottom: ${pxToRem(44)};

    @media ${device.tablet} {
        flex: 1 1 50%;
        padding-right: ${pxToRem(30)};
    }

    @media ${device.desktop} {
        flex: 1 1 33.33%;
        padding-right: ${pxToRem(30)};
    }
`;

const Img = Styled.img`
    width: ${pxToRem(50)};
    height: ${pxToRem(50)};
    margin-right: ${pxToRem(16)};
`;

const Info = Styled.div`
    color: ${theme.black};
`;

const Name = Styled.p`
    ${theme.copy_bold}
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(22)};
`;
const Title = Styled.p`
    ${theme.copy_regular}
    font-size: ${pxToRem(12)};
    line-height: ${pxToRem(18)};

`;

export default function Speakers({ item }) {
  const speakers = formatSpeakers(item);
  return (
    <Wrapper>
      {speakers.map((item) => {
        return (
          <Speaker key={item.name}>
            <Img src={item.image} alt="" />
            <Info>
              <Name>{item.name}</Name>
              <Title>{item.title}</Title>
            </Info>
          </Speaker>
        );
      })}
    </Wrapper>
  );
}
