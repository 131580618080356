import { useEffect, useState, useContext, useCallback } from "react";
import Styled from "styled-components";
import { useIntl } from "react-intl";

import pxToRem from "../../../../utils/pxToRem";
import { UserMetaContext } from "../../../../utils/metadata-context";
import { GaContext } from "../../../../utils/ga-context";
import Select from "../../../../components/Select";
import { countries } from "../../../../constants/countries";
import { auth0Keys } from "../../../../constants";

const SelectWrapper = Styled.div`
  margin-left: ${pxToRem(13)};
  margin-right: ${pxToRem(13)};
`;

export default function LangSelector() {
  const { user, updateUser } = useContext(UserMetaContext);
  const { trackEvent } = useContext(GaContext);
  const intl = useIntl();
  const noOptionsMessage = intl.formatMessage({
    id: "component.dropdown.selected.no-options",
  });

  const country = user.user_metadata[auth0Keys.COUNTRY] || "GB";
  const locale = user.user_metadata[auth0Keys.LOCALE] || "en";

  const [selectedCountry, setSelectedCountry] = useState(
    countries.find((c) => c.value === country)
  );

  useEffect(() => {
    const selected = countries.find(
      (c) => c.value === user.user_metadata[auth0Keys.COUNTRY]
    );
    setSelectedCountry(selected);
  }, [user]);

  const handleCountrySelect = useCallback(
    (value) => {
      trackEvent("Programme - country selector", value.en);
      updateUser({ [auth0Keys.COUNTRY]: value.value });
    },
    [updateUser, trackEvent]
  );

  return (
    <SelectWrapper>
      <Select
        options={countries}
        value={selectedCountry}
        onChange={handleCountrySelect}
        getOptionLabel={(option) => option[locale]}
        getOptionValue={(option) => option.value}
        ariaLabelledBy="country-label"
        ariaDescribedBy="country-description"
        noOptionsMessage={() => noOptionsMessage}
        isPill
      />
    </SelectWrapper>
  );
}
