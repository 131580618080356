import Styled from "styled-components";
import Drawer from "@material-ui/core/Drawer";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";

import pxToRem from "../../utils/pxToRem";
import { theme, routes } from "../../constants";
import VisuallyHidden from "../VisuallyHidden";
import closeIcon from "./assets/close.svg";
import chevronRightIcon from "./assets/chevron-right.svg";
import arrowLeftIcon from "./assets/arrow-left.svg";
import liveIcon from "./assets/live.svg";
import { HEIGHTS } from "./consts";
import LINKS from "./links";
import LanguageSelector from "./LanguageSelector";
import { NavItem, LangSelectorButton, Flag, Chevron } from "./styled";
const DRAWER_WIDTH = 275;

const InnerDrawer = Styled.div`
  width: ${pxToRem(DRAWER_WIDTH)};
  overflow: hidden;
  background: ${theme.black};
`;

const CloseBtn = Styled.button`
  border: none;
  background: transparent;
  padding: 0;
`;
const CloseImg = Styled.img``;

const DrawerHead = Styled.div`
  height: ${pxToRem(HEIGHTS.sm)};
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 0 ${pxToRem(25)};
`;

const DrawerBody = Styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-top: ${pxToRem(1)} solid ${theme.light_grey_1};
  overflow: hidden;
`;

const StyledNav = Styled.nav``;

const Inner = Styled.div`
  overflow-y: scroll;
  height: calc(100vh - ${pxToRem(HEIGHTS.sm)});
`;

const CloseLangSelectorButton = Styled.button`
  border: none;
  background: transparent;
  padding: 0;
`;

const NavInner = Styled.span`
  ${(props) =>
    props.$isLive &&
    `
    background: ${theme.secondary};
    color: ${theme.white};
    display: flex;
    align-items: center;
    padding: ${pxToRem(2)} ${pxToRem(5)} ${pxToRem(2)} ${pxToRem(2)};
  `}
`;

export default function Nav({
  isOpen,
  onCloseDrawer,
  onLogout,
  isAuthenticated,
  onToggleLangSelector,
  isLangSelectorOpen,
  selectedLang,
  onSelectLanguage,
  isLive,
}) {
  const location = useLocation();

  return (
    <>
      <Drawer anchor="right" open={isOpen} onClose={onCloseDrawer}>
        <InnerDrawer>
          <DrawerHead>
            <CloseBtn onClick={onCloseDrawer}>
              <CloseImg src={closeIcon} alt="" />
            </CloseBtn>
            {isLangSelectorOpen && (
              <CloseLangSelectorButton onClick={onToggleLangSelector}>
                <img src={arrowLeftIcon} alt="" />
              </CloseLangSelectorButton>
            )}
          </DrawerHead>
          <DrawerBody>
            <Inner>
              <StyledNav>
                <ul>
                  <li>
                    <NavItem
                      to={routes.HOME}
                      exact={true}
                      onClick={onCloseDrawer}
                    >
                      <FormattedMessage
                        id="nav.links.home"
                        defaultMessage="Translate this"
                      />
                    </NavItem>
                  </li>
                  {isAuthenticated &&
                    LINKS.map((link) => {
                      const itemIsLive = link.isMain && isLive;
                      return (
                        <li key={link.url}>
                          <NavItem
                            to={link.url}
                            key={link.label}
                            exact={true}
                            onClick={onCloseDrawer}
                            $isLive={itemIsLive}
                          >
                            <NavInner $isLive={itemIsLive}>
                              {itemIsLive && <img src={liveIcon} alt="" />}
                              <FormattedMessage
                                id={link.label}
                                defaultMessage="Translate this"
                              />
                            </NavInner>
                          </NavItem>
                        </li>
                      );
                    })}
                </ul>
              </StyledNav>
              {isAuthenticated && (
                <NavItem as="button" onClick={onLogout}>
                  <FormattedMessage
                    id="nav.links.logout"
                    defaultMessage="Translate this"
                  />
                </NavItem>
              )}

              <LangSelectorButton as="button" onClick={onToggleLangSelector}>
                <VisuallyHidden id="dropdown-title">
                  <FormattedMessage
                    id="language-selector.title"
                    defaultMessage="Select"
                  />
                </VisuallyHidden>
                <div>
                  <Flag src={selectedLang.img} alt="" />
                  <FormattedMessage
                    id={selectedLang.label}
                    defaultMessage="Translate this"
                  />
                </div>
                <Chevron src={chevronRightIcon} alt="" />
              </LangSelectorButton>
            </Inner>
            <LanguageSelector
              isOpen={isLangSelectorOpen}
              selectedLang={selectedLang}
              onSelectLanguage={onSelectLanguage}
            />
          </DrawerBody>
        </InnerDrawer>
      </Drawer>
    </>
  );
}
