import Styled from "styled-components";
import { FormattedMessage } from "react-intl";

import { theme } from "../../constants";
import pxToRem from "../../utils/pxToRem";
import VisuallyHidden from "../VisuallyHidden";

import EN from "./flags/GB.png";
import FR from "./flags/FR.png";
import ES from "./flags/ES.png";
import DE from "./flags/DE.png";
import IT from "./flags/IT.png";
import RU from "./flags/RU.png";

const FLAGS_PER_LOCALE = {
  en: EN,
  fr: FR,
  de: DE,
  it: IT,
  ru: RU,
  es: ES,
};

const Wrapper = Styled.div`
    display: flex;
    ${theme.copy_regular}
    font-size: ${pxToRem(12)};
    line-height: ${pxToRem(14)};
    align-items: center;
`;

const Flag = Styled.img`
    margin-right: ${pxToRem(7)};
`;

const Copy = Styled.p`
    color: ${theme.dark_grey_4};
    margin-right: ${pxToRem(10)};
`;

const Lang = Styled.span`
    text-transform: uppercase;
    color: ${theme.black};
`;

export default function OnlyAvailableIn({ lang }) {
  return (
    <Wrapper>
      <Copy>
        <FormattedMessage id="language.only-in" defaultMessage="translate" />:
        <VisuallyHidden as="span">
          <FormattedMessage
            id={`language.full.${lang}`}
            defaultMessage="translate"
          />
        </VisuallyHidden>
      </Copy>
      <Flag src={FLAGS_PER_LOCALE[lang]} alt="" />
      <Lang aria-hidden>
        <FormattedMessage
          id={`language.short.${lang}`}
          defaultMessage="translate"
        />
      </Lang>
    </Wrapper>
  );
}
