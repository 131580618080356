import Styled from "styled-components";
import { FormattedMessage } from "react-intl";

import pxToRem from "../../../utils/pxToRem";
import { theme } from "../../../constants";

const Checkboxes = Styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${(props) =>
    props.isInline &&
    `
    flex-direction: row;
  `}

  ${(props) =>
    props.cols > 1 &&
    `
     flex-direction: row;
     flex-wrap: wrap;
   `}

   ${(props) =>
     props.cols > 1 &&
     !props.noMaxWidth &&
     `
     max-width: ${pxToRem(229 * props.cols)};
   `}
`;

const Label = Styled.label`
  ${theme.copy_regular}
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(24)};
  margin-bottom: ${pxToRem(21)};
  padding-right: ${pxToRem(20)};
  ${(props) => `width: ${props.bias * 100 - 1}%`};
  //${(props) => `flex: 1 1 ${props.bias * 100 - 1}%`};
  display: flex;
  min-height: ${pxToRem(48)};
  
  ${(props) =>
    props.isInline &&
    `
    min-height: unset;
    margin-bottom: 0;
  `}
`;

const Checkbox = Styled.input`
  margin-right: ${pxToRem(8)};
  margin-top: 0.4em;
`;

const renderCheckboxes = (options, onChange, prefix, cols = 1, noMaxWidth) => {
  const hasWrap = cols > 1;
  return (
    <Checkboxes
      hasWrap={hasWrap}
      cols={cols}
      isInline={cols === 0}
      noMaxWidth={noMaxWidth}
    >
      {options.map((option, index) => {
        return (
          <Label
            key={`${cols}-${option.value}`}
            bias={cols === 0 ? 1 : 1 / cols}
            isInline={cols === 0}
          >
            <Checkbox
              type="checkbox"
              value={option.value}
              name="tech"
              checked={option.checked}
              onChange={(evt) => onChange(index, evt)}
            />
            <span>
              <FormattedMessage
                id={`${prefix}${option.value}`}
                defaultMessage="Translate This"
              />
            </span>
          </Label>
        );
      })}
    </Checkboxes>
  );
};

export { Checkboxes, Label, Checkbox, renderCheckboxes };
