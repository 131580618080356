import React from "react";
import Styled from "styled-components";

import pxToRem from "../../../utils/pxToRem";
import { device } from "../../../constants";

const Iframe = Styled.iframe`
  min-height: 560px;
`;

const Wrapper = Styled.div`
  display: flex;
  overflow: hidden;
  padding: ${pxToRem(24)} ${pxToRem(20)};

  @media ${device.tablet} {
    padding: 0 0;
    min-width: ${pxToRem(225)};
    margin-left: ${pxToRem(20)};
  }

  @media ${device.desktop} {
    margin-top: 0;
    padding: 0 0;
    min-width: ${pxToRem(294)};
    margin-left: ${pxToRem(20)};
  }
`;

export default function Slido({ link }) {
  return (
    <Wrapper>
      <Iframe
        title="slido"
        src={link}
        height="100%"
        width="100%"
        frameBorder="0"
        id="slido-iframe"
      />
    </Wrapper>
  );
}
