import moment from "moment";
import { auth0Keys } from "../constants";

export const sortByDates = (data) => {
  return data.reduce((total, current) => {
    const date = moment(current.duration.starttime).format("YYYY-MM-DD");
    return {
      ...total,
      [date]: total[current.date]
        ? [...total[current.date], current]
        : [current],
    };
  }, {});
};

const MAX_AMOUNT_OF_SPEAKERS = 6;
export const formatSpeakers = (item = {}) => {
  const speakers = [];
  for (let i = 0; i < MAX_AMOUNT_OF_SPEAKERS; i++) {
    const name = item[`speak${i + 1}name`];
    if (name) {
      speakers.push({
        name,
        title: item[`speak${i + 1}title`],
        image: "/program/speakers/" + item[`speak${i + 1}pic`],
      });
    }
  }

  return speakers;
};

const isStar = "yes";
const isPartner = "yes";

export const getUserIsPartner = (user) =>
  user.user_metadata.usertype === "partner";

export const getContentForUserType = (user, data) =>
  getUserIsPartner(user)
    ? data
    : data.filter((item) => item.partner.toLowerCase() !== isPartner);

export const getStarred = (user, data, futureItemsOnly) => {
  const starred = data.filter(
    (item) => !!item.star && item.star.toLowerCase() === isStar // get all the starred items
  );

  const eventsToDisplay = futureItemsOnly
    ? starred.filter((item) => moment().isBefore(item.duration.starttime)) // get rid of past items if needed
    : starred;

  const byUserSelectedPillars = eventsToDisplay.filter((item) =>
    user.user_metadata[auth0Keys.PILLARS].includes(item.pillar)
  );

  const userSelected = byUserSelectedPillars.filter((item) => {
    if (item["sub-tag-short"]) {
      const userSelectedTopics = item["sub-tag-short"].filter((subgroup) => {
        return user?.user_metadata?.subgroup?.includes(subgroup.toLowerCase());
      });
      return userSelectedTopics.length > 0;
    } else {
      return false;
    }
  });

  let items = userSelected;
  // no pillars or subgroups matched data
  if (userSelected.length === 0) {
    // select the first 3 starred - 1 per pillar
    eventsToDisplay.reduce((total, current) => {
      if (!total[current.pillar]) {
        items.push(current);
      }
      return {
        ...total,
        [current.pillar]: total[current.pillar]
          ? total[current.pillar]
          : current,
      };
    }, {});
  }

  // display max 5 items
  if (userSelected.length > 5) {
    items = userSelected.slice(0, 5);
  }

  return items.map((current) => {
    const availableLanguages = [
      { key: "de", value: current["link-de"] },
      { key: "en", value: current["link-en"] },
      { key: "fr", value: current["link-fr"] },
      { key: "it", value: current["link-it"] },
      { key: "es", value: current["link-es"] },
      { key: "ru", value: current["link-ru"] },
    ].filter((item) => !!item.value);
    return { ...current, availableLangs: availableLanguages.map((i) => i.key) };
  });
};

export const filterByIntrests = (user, data) => {
  const { user_metadata } = user;

  const byUserSelectedPillars = data.filter((e) => {
    return user_metadata[auth0Keys.PILLARS].includes(e.pillar);
  });

  return byUserSelectedPillars.filter((e) => {
    const subgroups = e["sub-tag-short"].filter((sub) => {
      return user_metadata[auth0Keys.SUBGROUP].includes(sub.toLowerCase());
    });

    return subgroups.length > 0;
  });
};

export const getIsOnlyOneLang = (item) =>
  Object.keys(item.languages).filter((l) => item.languages[l] === 1);

export const sortByDate = (events) =>
  events.sort((a, b) =>
    a.duration.starttime.localeCompare(b.duration.starttime)
  );

export const getTitle = (item, locale, isOnlyOneLang) => {
  if (item[`title-${locale}`]) return item[`title-${locale}`];
  if (isOnlyOneLang) return item[`title-${isOnlyOneLang[0]}`];
};

export const getDescription = (item, locale, isOnlyOneLang) => {
  if (item[`desc-${locale}`]) return item[`desc-${locale}`];
  if (isOnlyOneLang) return item[`desc-${isOnlyOneLang[0]}`];
};
