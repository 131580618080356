import React from "react";
import Styled from "styled-components";
import { FormattedMessage } from "react-intl";

import src from "./videos/home_vid.mp4";
import posterSrc from "./videos/home_poster.jpg";
import pxToRem from "../../utils/pxToRem";

import { Content, Title, Subtitle } from "./Styled";
import { device } from "../../constants";

const Wrapper = Styled.div`
    min-height: ${pxToRem(420)};
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(${posterSrc}) no-repeat center center / cover;
    
`;

const Video = Styled.video`
    pointer-events: none;
    min-width: 100%;
    position: absolute;
    top: 0;

    @media (prefers-reduced-motion) {
        display: none;
      }
`;

export default function HeaderVideo() {
  return (
    <Wrapper>
      <Video autoPlay="autoplay" loop="loop" muted>
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </Video>

      <Content>
        <Title>
          <FormattedMessage
            id={`home.header.1.headline`}
            defaultMessage="Translate this"
          />
        </Title>
        <Subtitle>
          <FormattedMessage
            id={`home.header.1.subheadline`}
            defaultMessage="Translate this"
          />
        </Subtitle>
      </Content>
    </Wrapper>
  );
}
