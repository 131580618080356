import { useContext, useCallback, useState, useEffect } from "react";
import Styled from "styled-components";

import Page from "../../components/Page";
import { sortByDates, getStarred, filterByIntrests } from "../../utils/data";
import { UserMetaContext } from "../../utils/metadata-context";
import { GaContext } from "../../utils/ga-context";
import Modal from "../../components/EventModal";
import pxToRem from "../../utils/pxToRem";
import { device, theme } from "../../constants";

import Header from "./Header";
import Intro from "./Intro";
import Filter from "./Filter";
import Body from "./Body";

const Inner = Styled.div`
  padding-bottom: ${pxToRem(32)};
  background: ${theme.white};

  @media ${device.tablet} {
    padding-bottom: ${pxToRem(135)};
  }

  @media ${device.desktop} {
    padding-bottom: ${pxToRem(160)};
  }
`;

export default function Program() {
  const { user, programme } = useContext(UserMetaContext);
  const { trackEvent } = useContext(GaContext);

  const [eventModalIsOpen, setEventModalIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [eventsByDates, setEventsByDates] = useState({});
  const [starred, setStarred] = useState([]);

  const handleEventClick = useCallback(
    (uid, gaTitle) => {
      trackEvent(`Programme - more info`, gaTitle);
      const event = programme.find((e) => e.uid === uid);
      if (event) {
        setSelectedEvent(event);
        setEventModalIsOpen(true);
      }
    },
    [programme, trackEvent]
  );

  useEffect(() => {
    setEventsByDates(sortByDates(filterByIntrests(user, programme)));
    setStarred(getStarred(user, programme, true));
  }, [programme, user]);

  return (
    <Page>
      <Inner>
        {starred.length > 0 && (
          <Header events={starred} onEventClick={handleEventClick} />
        )}
        <Intro />
        <Filter user={user} />
        {programme.length > 0 && (
          <Body
            events={programme}
            eventsByDates={eventsByDates}
            onEventClick={handleEventClick}
          />
        )}
      </Inner>

      <Modal
        isOpen={eventModalIsOpen}
        onClose={() => setEventModalIsOpen(!eventModalIsOpen)}
        item={selectedEvent}
        gaActionPrefix="Programme"
      />
    </Page>
  );
}
