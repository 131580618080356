import * as THREE from "three";
import pin from "./pin.svg";

export default function markerRenderer(marker) {
  // Set size of the map marker
  const size = 60;
  const offset = -(Math.random() * 5);

  // The hit area
  const hitAreaGeometry = new THREE.CircleGeometry( 18, 26 );
  const hitAreaMaterial = new THREE.MeshBasicMaterial({
    opacity: 0,
    // side: THREE.DoubleSide,
    transparent: true,
  });
  const mesh = new THREE.Mesh(hitAreaGeometry, hitAreaMaterial);

  // Inner circle
  const circleGeometry = new THREE.CircleGeometry( 5, 46 );
  const circleMaterial = new THREE.MeshBasicMaterial({
    color: 0xDB1F35,
    // map: texture,
    side: THREE.DoubleSide,
    transparent: true,
  });
  const circleMesh = new THREE.Mesh(circleGeometry, circleMaterial);
  circleMesh.position.set(0, 0, offset);

  // Outer ring
  const ringGeometry = new THREE.RingGeometry( 5, 8.8, 26 );
  const ringMaterial = new THREE.MeshBasicMaterial({
    color: 0xffffff,
    // map: texture,
    side: THREE.DoubleSide,
    transparent: true,
  });
  const ringMesh = new THREE.Mesh(ringGeometry, ringMaterial);
  ringMesh.position.set(0, 0, offset);


  // console.log(mesh.position.clone().toArray());
  mesh.add(circleMesh)
  mesh.add(ringMesh)

  // // Double size of the hit area
  // material.repeat.set(1.5, 1.5);
  // material.offset.set(-0.33, -0.33);
  return mesh;

  // NOTE: Trying to get a flat marker working
  // var spriteMap = new THREE.TextureLoader().load(pin);
  // var spriteMaterial = new THREE.SpriteMaterial({ map: spriteMap });
  // var sprite = new THREE.Sprite(spriteMaterial);
  // return sprite;
}
