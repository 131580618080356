import Styled from "styled-components";
import { FormattedMessage } from "react-intl";

import pxToRem from "../../../utils/pxToRem";
import { theme, device } from "../../../constants";

const Wrapper = Styled.div`
    text-align: center;
    color: ${theme.dark_grey_1};
    padding: ${pxToRem(32)} ${pxToRem(25)};

    @media ${device.tablet} {
        margin: auto;
        max-width: ${pxToRem(690)};
    }

    @media ${device.desktop} {
        max-width: ${pxToRem(1110)};
        padding: ${pxToRem(50)} ${pxToRem(25)} ${pxToRem(56)};
    }
`;

const Title = Styled.h1`
    ${theme.headline_medium}
    font-size: ${pxToRem(28)};
    line-height: ${pxToRem(34)};
    margin-bottom: ${pxToRem(8)};

    @media ${device.tablet} {
        margin-bottom: ${pxToRem(4)};
    }

    @media ${device.desktop} {
        font-size: ${pxToRem(40)};
        line-height: ${pxToRem(48)};
        margin-bottom: ${pxToRem(15)};
    }
`;

const Copy = Styled.p`
    ${theme.copy_regular}
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(22)};

    @media ${device.desktop} {
        font-size: ${pxToRem(20)};
        line-height: ${pxToRem(32)};
    }
`;

export default function Intro() {
  return (
    <Wrapper>
      <Title>
        <FormattedMessage
          id="programme.title"
          defaultMessage="TRANSLATE THIS"
        />
      </Title>
      <Copy>
        <FormattedMessage
          id="programme.intro"
          defaultMessage="TRANSLATE THIS"
        />
      </Copy>
    </Wrapper>
  );
}
