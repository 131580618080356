import React, { memo } from "react";
import PropTypes from "prop-types";
import Styled from "styled-components";

const StyledVisuallyHidden = Styled.p`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const VisuallyHidden = ({ as, className, children, live, ...props }) => {
  return (
    <StyledVisuallyHidden as={as} aria-live={live} {...props}>
      {children}
    </StyledVisuallyHidden>
  );
};

VisuallyHidden.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  as: PropTypes.string,
  live: PropTypes.string,
};

VisuallyHidden.defaultProps = {
  as: "span",
  live: "off",
};

export default memo(VisuallyHidden);
