import { useCallback, useContext, useEffect, useState } from "react";
import Styled from "styled-components";

import { theme, device } from "../../constants";
import pxToRem from "../../utils/pxToRem";
import { formatSpeakers } from "../../utils/data";
import HiddenCountdown from "../../components/HiddenCountdown";
import Page from "../../components/Page";

import { UserMetaContext } from "../../utils/metadata-context";
import { LocaleContext } from "../../utils/locale-context";

import Player from "./Player";
import Speakers from "./Speakers";
import Holding from "./Holding";
import Slido from "./Slido";
import BackupUrl from "./BackupUrl";

import Festival from "../home/Body";
import { FormattedMessage } from "react-intl";

const Header = Styled.div`
  background: ${theme.black};
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
      padding: ${pxToRem(20)};
      flex-direction: row;
  }
`;

const Body = Styled.div`
    padding: ${pxToRem(32)} ${pxToRem(20)} ${pxToRem(22)};
    max-width: ${pxToRem(548)};
    margin: auto;
    color: ${theme.white};

    @media ${device.tablet} {
        padding: ${pxToRem(32)} ${pxToRem(0)} ${pxToRem(22)};
        max-width: ${pxToRem(450)};
    }

    @media ${device.desktop} {
        padding: ${pxToRem(120)} ${pxToRem(0)} ${pxToRem(22)};
        max-width: ${pxToRem(550)};
    }
`;

const Title = Styled.h1`
    ${theme.headline_medium};
    text-transform: uppercase;
    color: ${theme.white};
    font-size: ${pxToRem(52)};
    line-height: ${pxToRem(55)};
    margin-bottom: ${pxToRem(31)};

    @media ${device.desktop} {
        font-size: ${pxToRem(64)};
        line-height: ${pxToRem(60)};
        margin-bottom: ${pxToRem(24)};
    }
`;

const Description = Styled.p`
    ${theme.copy_regular}
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(24)};
    margin-bottom: ${pxToRem(31)};

    @media ${device.tablet} {
        margin-bottom: ${pxToRem(40)};
    }

    @media ${device.desktop} {
        margin-bottom: ${pxToRem(48)};
    }
`;

const FestivalWrapper = Styled.div`
`;

const TodayTitle = Styled.h1`
    ${theme.headline_medium};
    font-size: ${pxToRem(20)};
    line-height: ${pxToRem(24)};

    @media ${device.tablet} {
      font-size: ${pxToRem(20)};
      line-height: ${pxToRem(30)};
      margin-bottom: ${pxToRem(17)};
    }

    @media ${device.desktop} {
      font-size: ${pxToRem(28)};
      line-height: ${pxToRem(30)};
      margin-bottom: ${pxToRem(25)};
    }
`;

const StyledCountdown = Styled.div`
    ${theme.headline_regular};
    font-size: ${pxToRem(90)};
    line-height: ${pxToRem(80)};
    margin-bottom: ${pxToRem(20)};
    
    @media ${device.tablet} {
      font-size: ${pxToRem(160)};
      line-height: ${pxToRem(160)};
      margin-bottom: ${pxToRem(27)};
    }

    @media ${device.desktop} {
      font-size: ${pxToRem(238)};
      line-height: ${pxToRem(195)};
      margin-bottom: ${pxToRem(30)};
    }
`;

const GA_ACTIONS = [
  "Main stage - go to programme",
  "Main stage - go to agenda",
  "Main stage - go to experts",
];
const GA_LABELS = [
  "Programme from stage",
  "Agenda from stage",
  "Experts from stage",
];

export default function MainStage() {
  const { locale } = useContext(LocaleContext);
  const { live, hasFestivalEnded, hasDayStarted, hasFestivalStarted } =
    useContext(UserMetaContext);
  const [url, setUrl] = useState("");
  const [isBackup, setIsBackup] = useState(false);

  const [isLive, setIsLive] = useState(false);

  const speakers = formatSpeakers(live);
  const slido = live?.["slido-url"];

  useEffect(() => {
    setUrl(live?.["brightcove-primary"]);
    setIsBackup(false);
  }, [live]);

  const handleBackupUrl = useCallback(() => {
    setIsBackup(true);
    setUrl(live?.["brightcover-backup"]);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [live]);

  const startingSoonRenderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <>
          <Header>
            {live && <Player link={url} />}
            {slido && <Slido link={slido} />}
          </Header>
          {live && (
            <Body>
              <Title>{live[`title-${locale}`]}</Title>
              <Description>{live[`desc-${locale}`]}</Description>
              {speakers.length > 0 && <Speakers speakers={speakers} />}
              {live?.["brightcover-backup"] && !isBackup && (
                <BackupUrl onClick={handleBackupUrl} />
              )}
            </Body>
          )}
        </>
      );
    } else {
      if (hasDayStarted) {
        return (
          <Holding
            hasFestivalStarted={hasFestivalStarted}
            title="main-stage.day.progress.title"
            copy="main-stage.day.progress.copy"
          ></Holding>
        );
      } else {
        return (
          // Render a countdown
          <Holding hasFestivalStarted={hasFestivalStarted}>
            <TodayTitle>
              <FormattedMessage
                id="main-stage.day.starting.title"
                defaultMessage="Translate"
              />
            </TodayTitle>
            <StyledCountdown>
              {hours > 9 ? hours : `0${hours}`}:
              {minutes > 9 ? minutes : `0${minutes}`}:
              {seconds > 9 ? seconds : `0${seconds}`}
            </StyledCountdown>
          </Holding>
        );
      }
    }
  };

  return (
    <Page>
      {live ? (
        <HiddenCountdown
          date={live.duration.starttime}
          onComplete={() => setIsLive(true)}
          renderer={startingSoonRenderer}
          isHidden={false}
        />
      ) : (
        <Holding
          isFestivalFinished={hasFestivalEnded}
          hasFestivalStarted={hasFestivalStarted}
          title={
            hasFestivalEnded
              ? "main-stage.festival.ended.title"
              : "main-stage.day.ended.title"
          }
          copy={
            hasFestivalEnded
              ? "main-stage.festival.ended.copy"
              : "main-stage.day.ended.copy"
          }
        ></Holding>
      )}

      <FestivalWrapper>
        <Festival actions={GA_ACTIONS} labels={GA_LABELS} />
      </FestivalWrapper>
    </Page>
  );
}
