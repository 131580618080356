import Styled from "styled-components";
import { FormattedMessage } from "react-intl";

import pxToRem from "../../utils/pxToRem";
import { theme, device } from "../../constants";

const Img = Styled.img`
    width: 100%;
`;

const Wrapper = Styled.button`
    position: relative;
    margin-bottom: ${pxToRem(15)};
    padding: 0;
    border: none;
    background: transparent;

    @media ${device.tablet} {
      width: 39%;
      flex-shrink: 0;
    }
`;

const IsLive = Styled.p`
    position: absolute;
    top: ${pxToRem(10)};
    left: ${pxToRem(10)};
    display: flex;
    align-items: center;
    color: ${theme.white};
    background: ${theme.secondary};
    text-transform: uppercase;
    padding: ${pxToRem(4)};
    ${theme.copy_regular}
`;

const Dot = Styled.span`
    width: ${pxToRem(4)};
    height: ${pxToRem(4)};
    margin-right: ${pxToRem(4)};
    background: ${theme.white};
    display: block;
`;

export default function Image({ src, isLive, onClick, title }) {
  return (
    <Wrapper onClick={onClick} aria-label={title}>
      <Img src={src} alt="" />
      {isLive && (
        <IsLive>
          <Dot />
          <FormattedMessage id="event.is-live" defaultMessage="translate" />
        </IsLive>
      )}
    </Wrapper>
  );
}
