import Styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { useContext } from "react";

import pxToRem from "../../../utils/pxToRem";
import { theme, device } from "../../../constants";
import { LocaleContext } from "../../../utils/locale-context";

import Accordion from "./Accordion";
import Contact from "./Contact";

import en from "../copy/en.json";
import ru from "../copy/ru.json";
import it from "../copy/it.json";
import de from "../copy/de.json";
import fr from "../copy/fr.json";
import es from "../copy/es.json";

const DATA_PER_LOCALE = {
  en,
  es: es,
  ru: ru,
  it: it,
  de: de,
  fr: fr,
};

const Body = Styled.div`
  padding: ${pxToRem(44)} ${pxToRem(20)};
  max-width: ${pxToRem(548)};
  margin: auto;
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
      max-width: unset;
      padding: ${pxToRem(56)} ${pxToRem(121)};
  }

  @media ${device.desktop} {
    padding: ${pxToRem(56)} ${pxToRem(165)};
    max-width: ${pxToRem(1440)};
}
`;

const Title = Styled.h2`
    width: 100%;
    color: ${theme.dark_grey_1};
    ${theme.copy_bold}
    font-size: ${pxToRem(28)};
    line-height: ${pxToRem(34)};
    text-align: center;
    margin-bottom: ${pxToRem(12)};

    @media ${device.tablet} {
        font-size: ${pxToRem(40)};
        line-height: ${pxToRem(48)};
        margin-bottom: ${pxToRem(5)};
    }
`;

const Description = Styled.p`
    ${theme.copy_regular}
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(24)};
    color: ${theme.dark_grey_3};
    text-align: center;
    max-width: ${pxToRem(628)};
    margin: auto;

`;

export default function HelpBody() {
  const { locale } = useContext(LocaleContext);
  return (
    <Body>
      <Title>
        <FormattedMessage id="help.subtitle" defaultMessage="translate" />
      </Title>
      <Description>
        <FormattedMessage id="help.description" defaultMessage="translate" />
      </Description>
      <Accordion data={DATA_PER_LOCALE[locale]} dataInEn={DATA_PER_LOCALE.en} />

      <Contact />
    </Body>
  );
}
