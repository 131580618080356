import Select from "react-select";

import pxToRem from "../../utils/pxToRem";
import { theme } from "../../constants";

import checkmarkIcon from "../../assets/icons/checkmark.svg";

const checkbox = (color = "#ccc") => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",

  ":after": {
    background: `url('${checkmarkIcon}') no-repeat`,
    borderRadius: 10,
    content: '" "',
    display: "block",
    marginRight: 8,
    height: pxToRem(24),
    width: pxToRem(24),
  },
});

const optionStyle = {
  fontFamily: "'Gotham SSmA', 'Gotham SSmB', sans-serif",
  color: "#323232",
  fontSize: pxToRem(14),
  lineHeight: pxToRem(22),
  fontWeight: 400,
  fontStyle: "normal",
};

const customStyles = {
  option: (provided, state) => {
    const selected = state.isSelected ? checkbox() : {};
    return {
      ...provided,
      ...optionStyle,
      padding: `${pxToRem(11)} ${pxToRem(8)} ${pxToRem(11)} ${pxToRem(16)}`,
      borderBottom: "1px solid #D9DBDC",
      backgroundColor:
        state.isSelected || state.isFocused
          ? theme.light_grey_3
          : "transparent",
      ...selected,
    };
  },
  control: (provided) => {
    return {
      ...provided,
      ...optionStyle,
      padding: `${pxToRem(11)} ${pxToRem(8)} ${pxToRem(11)} ${pxToRem(16)}`,
      backgroundColor: theme.light_grey_3,
      border: "none",
      borderColor: `${theme.light_grey_2}`,
      borderBottom: `${theme.light_grey_2} 1px solid`,
      borderRadius: 0,
      boxShadow: "none",
      "&:hover": { borderColor: theme.light_grey_2 },
    };
  },
  valueContainer: (provided) => ({
    ...provided,
    ...optionStyle,
    padding: 0,
  }),
  singleValue: (provided) => {
    return { ...provided, ...optionStyle, padding: 0 };
  },
  indicatorsContainer: (provided) => {
    return {
      ...provided,
      height: pxToRem(24),
      width: pxToRem(24),
      padding: 0,
    };
  },
  indicatorSeparator: (provided) => {
    return { ...provided, display: "none", padding: 0 };
  },
  dropdownIndicator: (provided, state) => {
    return {
      ...provided,
      transform: state.selectProps.menuIsOpen && "rotate(-180deg)",
      color: theme.black,
      padding: 0,
    };
  },
  menu: (provided) => {
    return {
      ...provided,
      marginTop: 0,
      borderTop: `1px solid ${theme.dark_grey_1}`,
      borderRadius: 0,
      boxShadow: "0px 0px 12px 0px #00000040",
    };
  },
};

const pillStyles = {
  control: (provided) => {
    return {
      ...provided,
      ...optionStyle,
      padding: "0 10px",
      backgroundColor: theme.black,
      border: "none",
      borderRadius: "50px",
      boxShadow: "none",
      "&:hover": { borderColor: theme.light_grey_2 },
      minHeight: "28px",
    };
  },
  singleValue: (provided) => {
    return {
      ...provided,
      ...optionStyle,
      padding: 0,
      color: theme.white,
      fontSize: 14,
    };
  },
  input: (provided) => {
    return {
      ...provided,
      ...optionStyle,
      padding: 0,
      color: theme.white,
      minWidth: "150px",
      margin: `0 2px`,
    };
  },
  dropdownIndicator: (provided, state) => {
    return {
      ...provided,
      transform: state.selectProps.menuIsOpen && "rotate(-180deg)",
      color: theme.white,
      padding: 0,
    };
  },
  indicatorsContainer: (provided) => {
    return {
      ...provided,
      height: "100%",
      width: pxToRem(24),
      padding: 0,
      alignSelf: "center",
    };
  },
};

export default function Dropdown({
  options,
  onChange,
  value,
  getOptionLabel,
  getOptionValue,
  ariaLabelledBy,
  ariaDescribedBy,
  noOptionsMessage,
  isPill,
}) {
  const styles = isPill
    ? { ...customStyles, ...pillStyles }
    : { ...customStyles };
  return (
    <Select
      styles={styles}
      options={options}
      onChange={onChange}
      value={value}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      maxMenuHeight={pxToRem(180)}
      aria-labelledby={`${ariaLabelledBy} ${ariaDescribedBy}`}
      noOptionsMessage={noOptionsMessage}
    />
  );
}
