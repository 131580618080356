import Styled from "styled-components";
import { FormattedMessage } from "react-intl";

import pxToRem from "../../../utils/pxToRem";
import { theme, device } from "../../../constants";

import sm from "./images/sm.svg";
import md from "./images/md.svg";
import lg from "./images/lg.svg";

const Header = Styled.div`
  height: ${pxToRem(258)};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  background: url(${sm}) no-repeat center center / cover;
  font-size: ${pxToRem(52)};
  line-height: ${pxToRem(56)};

  @media ${device.tablet} {
    background: url(${md}) no-repeat center center / cover;
    font-size: ${pxToRem(80)};
    line-height: ${pxToRem(80)};
  }

  @media ${device.desktop} {
    background: url(${lg}) no-repeat center center / cover;
  }
`;

const Title = Styled.h1`
  color: ${theme.white};
  ${theme.headline_medium}
  font-size: ${pxToRem(52)};
  line-height: ${pxToRem(56)};
  text-transform: uppercase;

  @media ${device.tablet} {
    font-size: ${pxToRem(80)};
    line-height: ${pxToRem(80)};
  }
`;

export default function ExpertHeader() {
  return (
    <Header>
      <Title>
        <FormattedMessage id="help.title" defaultMessage="translate" />
      </Title>
    </Header>
  );
}
