import { useContext, useState, useCallback } from "react";
import axios from "axios";
import Styled from "styled-components";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Countdown from "react-countdown";
import moment from "moment";

import Cta from "../Cta";
import ToggleCheck from "../ToggleCheck";
import { routes, auth0Keys, types } from "../../constants";
import { UserMetaContext } from "../../utils/metadata-context";
import { GaContext } from "../../utils/ga-context";
import pxToRem from "../../utils/pxToRem";
import { getIsOnlyOneLang, getTitle } from "../../utils/data";

import ConfirmationModal from "./ConfirmationModal";
import InviteModal from "./InviteModal";
import GoToWebinar from "./GoToWebinar";
import Calendar from "./Calendar";

const Wrapper = Styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;

    ${(props) =>
      props.isCol &&
      `
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: flex-start;
        gap: ${pxToRem(22)};
    `}
`;

const Icons = Styled.div`
      display: flex;
`;

const HiddenCountdown = Styled.div`
  display: none;
`;

export default function Buttons({
  item,
  isCol,
  size,
  isAgenda,
  gaActionPrefix,
  isList,
}) {
  const isOnlyOneLang = getIsOnlyOneLang(item);
  const gaTitle = getTitle(item, "en", isOnlyOneLang);

  const { duration, type } = item;
  const { endtime, starttime } = duration;
  const isPast = moment().isAfter(moment(endtime));
  const isInFuture = moment().isBefore(moment(starttime));
  const isLiveNow = moment().isBetween(starttime, endtime);

  // live events are hidden if in the past or in the future (if not live)
  const isLiveEventType = type === types.brightcove || type === types.youtube;
  const disabledByDefault = isLiveEventType && (isPast || isInFuture);
  const [isHidden, setIsHidden] = useState(disabledByDefault);
  const [isLive, setIsLive] = useState(isLiveNow);

  const [isConfrimationModalOpen, setIsConfrimationModalOpen] = useState(false);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const { user, updateUser } = useContext(UserMetaContext);
  const { trackEvent } = useContext(GaContext);
  const { uid } = item;
  const isWebinar = item.type === types.gotowebinar;

  const getPropsPerType = (item, gaActionPrefix, gaTitle) => {
    if (item.type === types.teams) {
      return {
        as: "a",
        target: "_blank",
        href: item["url-gtw-msteams"],
        variant: "primary",
        copy: "event.cta.teams",
        onClick: () => {
          trackEvent(`${gaActionPrefix} join Teams`, gaTitle);
        },
      };
    }
    if (isLiveEventType) {
      return {
        as: Link,
        to: routes.MAIN_STAGE,
        variant: "red",
        copy: "event.cta.watch",
      };
    } else {
      return {};
    }
    // add go to webinar
  };

  const props = getPropsPerType(item, gaActionPrefix, gaTitle);

  const handleChange = useCallback(
    (evt) => {
      if (!evt.target.checked) {
        setIsConfrimationModalOpen(true);
      } else {
        trackEvent(`${gaActionPrefix} - add to agenda`, gaTitle);
        const events = [...user.user_metadata[auth0Keys.EVENTS], uid];
        updateUser({ [auth0Keys.EVENTS]: events, should_sync: true });
      }
    },
    [user, uid, updateUser, gaActionPrefix, gaTitle, trackEvent]
  );

  const handleConfirm = useCallback(() => {
    trackEvent(`${gaActionPrefix} - removed from agenda`, gaTitle);

    const events = user.user_metadata[auth0Keys.EVENTS].filter(
      (e) => e !== uid
    );
    updateUser({ [auth0Keys.EVENTS]: events, should_sync: true });
    setIsConfrimationModalOpen(false);
  }, [
    uid,
    updateUser,
    user.user_metadata,
    gaActionPrefix,
    gaTitle,
    trackEvent,
  ]);

  const handleWebinarInvite = useCallback(() => {
    const { email, user_metadata } = user;
    const { first_name, last_name, webinarids, events } = user_metadata;
    const webinar = item["url-gtw-msteams"];
    const { uid } = item;

    const url = `https://attendee.gotowebinar.com/registration.tmpl?webinar=${webinar}&registrant.email=${email}&registrant.givenName=${first_name}&registrant.surname=${last_name}`;

    axios.post(url).catch(function (error) {
      console.log(error);
    });

    const data = events.includes(uid) ? {} : { should_sync: true };
    if (!webinarids.includes(webinar)) {
      updateUser({
        ...data,
        [auth0Keys.WEBINARIDS]: [...webinarids, webinar],
        [auth0Keys.EVENTS]: events.includes(uid) ? events : [...events, uid], // save to agenda
      });
    }

    setIsInviteModalOpen(true);
  }, [item, updateUser, user]);

  return (
    <Wrapper isCol={isCol}>
      <Icons>
        {isAgenda && <Calendar item={item} isList={isList} />}
        <ToggleCheck
          copy="event.status.agenda"
          id={uid}
          type="agenda"
          isChecked={user.user_metadata[auth0Keys.EVENTS].includes(uid)}
          onChange={handleChange}
          showCopy={!isAgenda}
        ></ToggleCheck>
      </Icons>

      {isHidden && (
        <HiddenCountdown>
          <Countdown
            date={starttime}
            onComplete={() => {
              setIsHidden(false);
              setIsLive(true);
            }}
            key={starttime}
          />
        </HiddenCountdown>
      )}

      {isLive && (
        <HiddenCountdown>
          <Countdown // to tell when an event is ending
            date={endtime}
            onComplete={() => {
              setIsHidden(true);
              setIsLive(false);
            }}
            key={endtime}
          />
        </HiddenCountdown>
      )}

      {isWebinar ? (
        <GoToWebinar
          item={item}
          size={size || "small"}
          webinarids={user.user_metadata[auth0Keys.WEBINARIDS]}
          onClick={handleWebinarInvite}
          gaActionPrefix={gaActionPrefix}
          gaTitle={gaTitle}
        />
      ) : (
        !isHidden && (
          <Cta {...props} size={size || "small"} uppercase>
            <FormattedMessage id={props.copy} defaultMessage="translate" />
          </Cta>
        )
      )}

      <ConfirmationModal
        isOpen={isConfrimationModalOpen}
        onClose={() => setIsConfrimationModalOpen(false)}
        onConfirm={handleConfirm}
      />

      <InviteModal
        isOpen={isInviteModalOpen}
        onClose={() => setIsInviteModalOpen(false)}
      />
    </Wrapper>
  );
}
