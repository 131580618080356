import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import smoothscroll from "smoothscroll-polyfill";
import "core-js/features/string/repeat";

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ScrollToTop from "./components/ScrollToTop";
import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";

import UserMetaProvider from "./utils/metadata-context";
import LocaleProvider from "./utils/locale-context";
import GaProvider from "./utils/ga-context";

// kick off the polyfill!
smoothscroll.polyfill();

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <GaProvider>
        <ScrollToTop />
        <Auth0ProviderWithHistory>
          <LocaleProvider>
            <UserMetaProvider>
              <App />
            </UserMetaProvider>
          </LocaleProvider>
        </Auth0ProviderWithHistory>
      </GaProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
