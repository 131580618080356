import { useContext } from "react";
import Styled from "styled-components";

import { LocaleContext } from "../../utils/locale-context";
import pxToRem from "../../utils/pxToRem";
import { theme, device } from "../../constants";

const TimeWrapper = Styled.div`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    ${theme.copy_regular}
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(24)};

    @media ${device.tablet} {
      justify-content: flex-start;
    }
`;

const TimeLine = Styled.div`
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: ${pxToRem(1)};
    background: ${theme.light_grey_1};
    width: 100%;
`;

const Background = Styled.div`
    background: ${theme.white};
    display: flex;
    z-index: 0;
    padding: ${pxToRem(14)} ${pxToRem(12)};

    @media ${device.tablet} {
      padding-left: 0;
    }

    @media ${device.desktop} {
      width: ${pxToRem(190)};
    }
`;

const StyledTime = Styled.p``;

export default function Time({ start, end }) {
  const { moment } = useContext(LocaleContext);
  return (
    <TimeWrapper>
      <TimeLine />
      <Background>
        <StyledTime>{moment(start).format("HH:mm")}</StyledTime>-
        <StyledTime>{moment(end).format("HH:mm")}</StyledTime>
      </Background>
    </TimeWrapper>
  );
}
