import Styled from "styled-components";
import Modal from "react-modal";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { routes, theme } from "../../../constants";
import pxToRem from "../../../utils/pxToRem";
import VisuallyHidden from "../../VisuallyHidden";
import Cta from "../../Cta";
import invite from "./invite.svg";
import close from "./x.svg";

const customStyles = {
  content: {
    width: "100%",
    padding: `${pxToRem(24)} ${pxToRem(0)} ${pxToRem(40)}`,
    borderRadius: "0",
    maxHeight: "100vh",
    overflow: "hidden",
    display: "flex",
    top: "50%",
    position: "absolute",
    transform: "translateY(-50%)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.44)",
    zIndex: 2,
  },
};

const Inner = Styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: ${theme.white};
    margin: 0 auto;
    max-width: ${pxToRem(375)};
    overflow-y: scroll;
    width: 100%;
    text-align: center;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    a {
        color: ${theme.primary};
        text-decoration: none;

        &:visited,
        &:link {
            color: ${theme.primary};
        }

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
`;

const Wrapper = Styled.div`
    padding: ${pxToRem(24)} ${pxToRem(30)} ${pxToRem(30)};

`;

const Title = Styled.p`
    $${theme.copy_bold}
    font-size: ${pxToRem(20)};
    line-height: ${pxToRem(28)};
    color: ${theme.dark_grey_1};
`;

const Copy = Styled.p`
    ${theme.copy_regular}
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(24)};    
    margin-bottom: ${pxToRem(38)};
`;

const Info = Styled(Copy)`
    margin-bottom: ${pxToRem(22)};
`;

const StyledModal = Styled(Modal)`
    &:focus-visible {
        outline: none;
    }
`;

const Img = Styled.img`
    margin-bottom: ${pxToRem(28)};
`;

const CloseBtn = Styled.button`
    background: transparent;
    border: none;
    padding: ${pxToRem(10)};
`;

const Header = Styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
`;

Modal.setAppElement("#root");

export default function InviteModal({ isOpen, onClose }) {
  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
      aria={{
        labelledby: "heading",
      }}
    >
      <Inner>
        <Header>
          <CloseBtn onClick={onClose}>
            <VisuallyHidden>
              <FormattedMessage
                id="event.gotowebinar.sent.close"
                defaultMessage="translate"
              />
            </VisuallyHidden>
            <img src={close} alt="" />
          </CloseBtn>
        </Header>
        <Wrapper>
          <Img src={invite} alt="" />
          <Title id="heading">
            <FormattedMessage
              id="event.gotowebinar.sent.title"
              defaultMessage="translate"
            />
          </Title>
          <Copy>
            <FormattedMessage
              id="event.gotowebinar.sent.copy"
              defaultMessage="translate"
            />
          </Copy>

          <Info>
            <FormattedMessage
              id="event.gotowebinar.sent.agenda"
              defaultMessage="translate"
              values={{
                link: (copy) => <Link to={routes.AGENDA}>{copy}</Link>,
              }}
            />
          </Info>

          <Cta onClick={onClose} uppercase>
            <FormattedMessage
              id="event.gotowebinar.sent.close"
              defaultMessage="translate"
            />
          </Cta>
        </Wrapper>
      </Inner>
    </StyledModal>
  );
}
