import Styled from "styled-components";
import { useContext, useState, useEffect } from "react";
import { ics } from "calendar-link";
import urlify from "urlifyjs";

import { LocaleContext } from "../../../utils/locale-context";
import { GaContext } from "../../../utils/ga-context";
import {
  getDescription,
  getTitle,
  getIsOnlyOneLang,
} from "../../../utils/data";

import VisuallyHidden from "../../VisuallyHidden";
import Cta from "../../Cta";

import icon from "./calendar.svg";
import { FormattedMessage } from "react-intl";
import { theme } from "../../../constants";
import pxToRem from "../../../utils/pxToRem";

const Copy = Styled.span`
  ${theme.copy_regular}
  font-size: ${pxToRem(13)};
  line-height: ${pxToRem(15)};
  color: ${theme.primary};
  margin-right: ${pxToRem(7)};
`;

// const Button = Styled.a`
//     border: none;
//     background: transparent;
//     padding: 0;
//     cursor: pointer;
// `;

const Img = Styled.img`
  margin: 5px 1px;
`;

const Wrapper = Styled.div`
  display: flex;
  align-items: center;

  display: none; // temp
`;

export default function Calendar({ item, isList }) {
  const { locale } = useContext(LocaleContext);
  const { trackEvent } = useContext(GaContext);
  const [generatedIcs, setIcs] = useState({});

  useEffect(() => {
    const isOnlyOneLang = getIsOnlyOneLang(item);
    const title = getTitle(item, locale, isOnlyOneLang);
    const description = getDescription(item, locale, isOnlyOneLang);
    const start = item.duration.starttime;
    const end = item.duration.endtime;

    const event = {
      title,
      description,
      start,
      end,
      url: item.type === "teams" ? item["url-gtw-msteams"] : undefined,
    };

    const gen = ics(event);
    setIcs({ blob: gen, name: urlify(title).split(".").join("") });
  }, [item, locale]);

  return (
    <Wrapper>
      {isList && (
        <Copy aria-hidden>
          <FormattedMessage
            id="agenda.event.calendar.download"
            defaultMessage="Download"
          />
        </Copy>
      )}

      <Cta
        as="a"
        href={generatedIcs.blob}
        download={generatedIcs.name}
        target="_blank"
        iconOnly
        variant="secondary"
        onClick={() => {
          trackEvent("Agenda - download ICS", "ICS download");
        }}
      >
        <VisuallyHidden>
          <FormattedMessage
            id="agenda.event.calendar"
            defaultMessage="download calendar event"
          />
        </VisuallyHidden>
        <Img src={icon} alt="" />
      </Cta>
    </Wrapper>
  );
}
