import { useContext } from "react";
import Styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { GaContext } from "../../utils/ga-context";
import { UserMetaContext } from "../../utils/metadata-context";
import pxToRem from "../../utils/pxToRem";
import { theme, device, routes } from "../../constants";

const StyledFooter = Styled.footer`
  background: ${theme.black};
  color: ${theme.white};
  ${theme.copy_regular}
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(18)};
  display: flex;
  flex-direction: column;
  padding: ${pxToRem(24)} ${pxToRem(20)};
  min-height: ${pxToRem(270)};

  @media ${device.desktop} {
    flex-direction: row;
    min-height: auto;
    justify-content: space-between;
    padding: ${pxToRem(13)} ${pxToRem(20)};
  }
`;

const StyledLink = Styled.a`
  color: ${theme.white};
  ${theme.copy_regular}
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(18)};
  text-decoration: none;
  margin-bottom: ${pxToRem(16)};
  background: transparent;
  border: none;
  padding: 0;
  text-align: left;

  @media ${device.desktop} {
    margin-right: ${pxToRem(30)};
    margin-bottom: ${pxToRem(0)};
  }


  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const Left = Styled.nav`
  display: flex;
  flex-direction: column;

  @media ${device.desktop} {
    flex-direction: row;
  }
`;

const Right = Styled.div`

`;

export default function Footer() {
  const { user } = useContext(UserMetaContext);
  //const { trackEvent } = useContext(GaContext);

  const country = user?.user_metadata?.country;
  // const isGB = country === "GB";

  const links = [
    {
      id: "footer.tac",
      defaultMessage: "Translate this",
      href: "#",
      component: "a",
    },
    {
      id: "footer.privacy",
      defaultMessage: "Translate this",
      href: "#",
      component: "a",
    },
    {
      id: "footer.cookie",
      defaultMessage: "Translate this",
      href: "#",
      component: "a",
    },
    {
      id: "footer.cookie-settings",
      defaultMessage: "Translate this",
      href: "#",
      component: "a",
    },
    {
      id: "footer.help",
      defaultMessage: "Translate this",
      href: "#",
      component: "a",
    },
  ];

  return (
    <StyledFooter>
      <Left>
        {links.map((link) => (
          <StyledLink
            as={link.component}
            key={link.id}
            href={link.href}
            to={link.to}
            className={link.className}
            onClick={link.onClick}
          >
            <FormattedMessage
              id={link.id}
              defaultMessage={link.defaultMessage}
            />
          </StyledLink>
        ))}
      </Left>
      <Right>
        <FormattedMessage
          id="footer.copyright"
          defaultMessage="© Copyright 2021. All Rights Reserved"
        />
      </Right>
    </StyledFooter>
  );
}
