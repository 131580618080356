import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { routes } from "../constants";
import Loading from "../components/Loading";
import { UserMetaContext } from "../utils/metadata-context";

const ProtectedRoute = ({ component: Component, location, ...args }) => {
  const { user } = useContext(UserMetaContext);
  const { isAuthenticated, isLoading } = useAuth0();

  return (
    <Route
      {...args}
      render={props => {
        if (isLoading) return <Loading />;
        if (!isAuthenticated) {
          return (
            <Redirect
              to={{ pathname: routes.LOGIN, state: { from: location } }}
            />
          );
        }
        if (user.user_metadata.isLoading) return <Loading />;
        //
        return <Component {...args} {...props} />;
      }}
      {...args}
    />
  );
};

export default ProtectedRoute;
