import Styled from "styled-components";
import { FormattedMessage } from "react-intl";

import pxToRem from "../../utils/pxToRem";
import { theme } from "../../constants";

const Wrapper = Styled.div`
    ${theme.copy_regular}
    text-align: center;
    padding-top: ${pxToRem(61)};
    border-top: ${pxToRem(1)} solid ${theme.light_grey_1};
    margin-top: ${pxToRem(30)};
`;

const Header = Styled.p`
    font-size: ${pxToRem(20)};
    line-height: ${pxToRem(32)};
    margin-bottom: ${pxToRem(10)};
    color: ${theme.dark_grey_1};
`;

const Copy = Styled.p`
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(22)};
    color: ${theme.dark_grey_3};
`;

export default function NoData() {
  return (
    <Wrapper>
      <Header>
        <FormattedMessage
          id="programme.day.no-data.title"
          defaultMessage="translate"
        />
      </Header>
      <Copy>
        <FormattedMessage
          id="programme.day.no-data.copy"
          defaultMessage="translate"
        />
      </Copy>
    </Wrapper>
  );
}
