import { forwardRef } from "react";
import Styled from "styled-components";
import { FormattedMessage } from "react-intl";
import pxToRem from "../../utils/pxToRem";
import { theme, device } from "../../constants";

import { LANGUAGES, HEIGHTS } from "./consts";
import { LangSelectorButton, Flag } from "./styled";

const Wrapper = Styled.div`
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  background: ${theme.black};
  transform: translateX(100%);

  ${({ isOpen }) => isOpen && "transform: translateX(0);"}

  @media ${device.desktop} {
    width: ${pxToRem(342)};
    top: ${pxToRem(HEIGHTS.sm)};
    left: auto;
    right: 0;
    transform: translateY(-100%);
    height: auto;

    ${({ isOpen }) => isOpen && "transform: translateY(0);"}
  }
`;

const Ul = Styled.ul`
    display: none;
    ${({ isOpen }) => isOpen && "display: block;"}

    @media ${device.desktop} {
      border-top: ${pxToRem(1)} solid ${theme.light_grey_1};
    }
 `;

const Radio = Styled.input`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const LanguageSelector = forwardRef((props, ref) => {
  const { selectedLang, isOpen, onSelectLanguage } = props;
  return (
    <Wrapper isOpen={isOpen} ref={ref}>
      <Ul isOpen={isOpen}>
        {LANGUAGES.map((lang) => {
          const isSelected = selectedLang.id === lang.id;
          return (
            <li key={lang.label}>
              <Radio
                id={lang.id}
                key={lang.id}
                type="radio"
                name="lang-selector"
                data-selected={isSelected}
                value={lang.id}
                onChange={() => {
                  onSelectLanguage(lang);
                }}
              />
              <LangSelectorButton as="label" htmlFor={lang.id}>
                <div>
                  <Flag src={lang.img} alt="" />
                  <FormattedMessage
                    id={lang.label}
                    defaultMessage="Translate this"
                  />
                </div>
              </LangSelectorButton>
            </li>
          );
        })}
      </Ul>
    </Wrapper>
  );
});

export default LanguageSelector;
