import PropTypes from "prop-types";
import Styled from "styled-components";

import { theme } from "../../constants";
import pxToRem from "../../utils/pxToRem";

const StyledLinkCta = Styled.button`
  background: transparent;
  padding: 0;
  border: none;
  color: ${theme.primary};

  ${theme.copy_medium}
  font-size: ${pxToRem(18)};
  line-height: ${pxToRem(26)};
  text-decoration: none;
  text-align: center;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.$size === "small" &&
    `
    font-size: ${pxToRem(10)};
    line-height: ${pxToRem(14)};
  `}

  ${(props) => props.$isLink && "display: table-cell;"}

  ${(props) => props.$uppercase && "text-transform: uppercase;"}
  ${(props) => props.$bold && `${theme.copy_bold}`}

  &:focus-visible {
    text-decoration: underline;
  }
`;

export default function LinkCta({
  variant,
  as,
  uppercase,
  isLink,
  size,
  ...rest
}) {
  return (
    <StyledLinkCta
      as={as}
      $variant={variant}
      $uppercase={uppercase}
      $isLink={isLink}
      $size={size}
      $bold={size}
      {...rest}
    />
  );
}

LinkCta.propTypes = {
  variant: PropTypes.string,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
};

LinkCta.defaultProps = {
  variant: "primary",
  as: "button",
};
