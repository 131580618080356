import Page from "../../components/Page";
import Globe from "../../components/Globe";

export default function Explore() {
  return (
    <Page>
      <Globe />
    </Page>
  );
}
