import playButtonIcon from "./assets/play-button.svg";
import pdfButtonIcon from "./assets/pdf-button.svg";
import podcastButtonIcon from "./assets/podcast-button.svg";

import play from "./assets/play.svg";
import pdf from "./assets/pdf.svg";
import podcast from "./assets/podcast.svg";
import demotool from "./assets/demotool.svg";

import playCta from "./assets/lang-select-cta-video.svg";
import pdfCta from "./assets/lang-select-cta-pdf.svg";
import podcastCta from "./assets/lang-select-cta-podcast.svg";
import demotoolCta from "./assets/lang-select-cta-demotool.svg";

export const VIDEO = "video";
export const PODCAST = "podcast";
export const URL = "url";
export const PDF = "pdf";

export const getLangSelectItemProps = (item, lang) => {
  const type = item.type.toLowerCase();
  if (type === PDF) {
    return {
      as: "a",
      target: "_blank",
      download: true,
      href: item[`link-${lang}`],
      img: pdfCta,
    };
  }

  if (type === PODCAST) {
    return {
      as: "a",
      target: "_blank",
      href: item[`link-${lang}`],
      img: podcastCta,
    };
  }

  if (type === URL) {
    return {
      as: "a",
      target: "_blank",
      href: item[`link-${lang}`],
      img: demotoolCta,
    };
  }

  if (type === VIDEO) {
    return { as: "button", img: playCta };
  }
  return {};
};

export const getItemProps = (item) => {
  if (item.availableLangs.length > 1) {
    return { as: "button" };
  }
  const type = item.type.toLowerCase();

  if (type === PDF) {
    return {
      as: "a",
      target: "_blank",
      download: true,
      href: item[`link-${item.availableLangs[0]}`],
    };
  }

  if (type === PODCAST) {
    return {
      as: "a",
      target: "_blank",
      href: item[`link-${item.availableLangs[0]}`],
    };
  }

  if (type === URL) {
    return {
      as: "a",
      target: "_blank",
      href: item[`link-${item.availableLangs[0]}`],
    };
  }

  if (type === VIDEO) {
    return { as: "button" };
  }
  return {};
};

export const ICONS_PER_TYPES = {
  [VIDEO]: playButtonIcon,
  [PODCAST]: podcastButtonIcon,
  [URL]: podcastButtonIcon,
  [PDF]: pdfButtonIcon,
};

export const ICONS_PER_TYPE_CTA = {
  [VIDEO]: play,
  [PODCAST]: podcast,
  [URL]: demotool,
  [PDF]: pdf,
};

export const getDurationForType = (item) => {
  const type = item.type.toLowerCase();
  if (type === VIDEO) {
    return item.duration;
  }
  if (type === PDF) {
    return "pdf";
  }

  if (type === URL) {
    return "url";
  }
  if (type === PODCAST) {
    return "podcast";
  }
};
