import { useEffect, useState, useContext } from "react";
import Styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";

import { UserMetaContext } from "../../../../utils/metadata-context";
import { GaContext } from "../../../../utils/ga-context";
import pxToRem from "../../../../utils/pxToRem";
import { countries } from "../../../../constants/countries";
import { device, auth0Keys } from "../../../../constants";
import Cta from "../../../../components/Cta";
import Select from "../../../../components/Select";
import { Wrapper, Title, Copy, Content } from "../styled";

import Sm from "./sm.png";
import Md from "./md.png";
import Lg from "./lg.png";
import BorderSm from "./border-sm.svg";

const Split = Styled.div`
  ${(props) =>
    props.hasBackground
      ? `
      background: url(${Sm}) no-repeat top center / cover;
      height: ${pxToRem(182)};
    `
      : `
        @media ${device.mobile} {
          background: url(${BorderSm}) no-repeat bottom left / cover;

        }
        flex: 1;
    `}

  @media ${device.tablet} {
    ${(props) =>
      props.hasBackground
        ? `
        background: url(${Md}) no-repeat top center / cover;
        height: ${pxToRem(370)};
      `
        : "flex: 1;"}


  }

  @media ${device.desktop} {
    ${(props) =>
      props.hasBackground &&
      `
      background: url(${Lg}) no-repeat top center / cover;
      height: unset;
      `}

    flex: 1 0 50%;
  }
`;

const Inner = Styled.div``;

const ButtonWrapper = Styled.div`
  margin-top: ${pxToRem(94)};
  max-width: 100%;

  @media ${device.tablet} {
    margin-top: ${pxToRem(26)};
    margin-bottom: ${pxToRem(26)};
    max-width: ${pxToRem(220)};
  }

  @media ${device.desktop} {
    margin-bottom: ${pxToRem(0)};
  }
`;

const SelectWrapper = Styled.div`
  margin-bottom: ${pxToRem(18)};
`;

export default function Browse({ onNext, dots, prev, isActiveSlide }) {
  const { user } = useContext(UserMetaContext);
  const { trackEvent } = useContext(GaContext);
  const intl = useIntl();
  const noOptionsMessage = intl.formatMessage({
    id: "component.dropdown.selected.no-options",
  });
  const country = user.user_metadata[auth0Keys.COUNTRY] || "gb";
  const locale = user.user_metadata[auth0Keys.LOCALE] || "en";
  const [selectedCountry, setSelectedCountry] = useState(
    countries.find((c) => c.value === country)
  );

  const [countriesList] = useState(countries);

  useEffect(() => {
    const selected = countries.find((c) => c.value === country);
    setSelectedCountry(selected);
  }, [country, locale]);

  return (
    <Wrapper>
      <Split>
        <Content>
          {prev()}
          <Inner>
            <Title id="country-label">
              <FormattedMessage
                id="onboarding.slide.4.title"
                defaultMessage="Translate This"
              />
            </Title>
            <Copy id="country-description">
              <FormattedMessage
                id="onboarding.slide.4.copy"
                defaultMessage="Translate this string for the Make It festival.We have 2 weeks packed with inspiring content and sessions to help enhance your printing operation."
              />
            </Copy>
            {isActiveSlide && (
              <ButtonWrapper>
                <SelectWrapper>
                  <Select
                    options={countriesList}
                    value={selectedCountry}
                    onChange={(value) => {
                      trackEvent("Onboarding - Where are you?", value.en);
                      setSelectedCountry(value);
                    }}
                    getOptionLabel={(option) => option[locale]}
                    getOptionValue={(option) => option.value}
                    ariaLabelledBy="country-label"
                    ariaDescribedBy="country-description"
                    noOptionsMessage={() => noOptionsMessage}
                  />
                </SelectWrapper>

                <Cta
                  uppercase
                  disabled={!selectedCountry}
                  onClick={() =>
                    onNext({
                      [auth0Keys.COUNTRY]: selectedCountry?.value,
                    })
                  }
                >
                  <FormattedMessage
                    id="onboarding.slide.4.button"
                    defaultMessage="Translate this"
                  />
                </Cta>
              </ButtonWrapper>
            )}
          </Inner>
          {dots()}
        </Content>
      </Split>
      <Split hasBackground />
    </Wrapper>
  );
}
