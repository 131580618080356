import Styled from "styled-components";
import { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { GaContext } from "../../utils/ga-context";
import { routes, device, theme } from "../../constants";
import pxToRem from "../../utils/pxToRem";
import images from "./images";
import arrowIcon from "../../assets/icons/arrow-right-circle.svg";

const items = [routes.PROGRAM, routes.AGENDA, routes.EXPERT];

const Wrapper = Styled.div`
  display: flex;
  flex-direction: column;
  padding: ${pxToRem(56)} ${pxToRem(20)};
  max-width: ${pxToRem(548)};
  margin: auto;

  @media ${device.tablet} {
    flex-direction: row;
    justify-content: space-between;
    max-width: ${pxToRem(690)};
    padding-left: 0;
    padding-right: 0;
  }

  @media ${device.desktop} {
    max-width: ${pxToRem(894)};
  }
`;

const Card = Styled.div`
  & + & {
    margin-top: ${pxToRem(32)};

    @media ${device.tablet} {
      margin-top: 0;
    }
  }

  @media ${device.tablet} {
    max-width: ${pxToRem(210)};
  }

  @media ${device.desktop} {
    max-width: ${pxToRem(278)};
  }
`;

const Picture = Styled.picture``;
const Image = Styled.img`
  width: 100%;
  margin-bottom: ${pxToRem(16)};
`;

const Label = Styled.h3`
  ${theme.headline_medium}
  color: ${theme.white};
  margin-bottom: ${pxToRem(2)};
  font-size: ${pxToRem(20)};
  line-height: ${pxToRem(32)};
`;

const BodyCopy = Styled.p`
  ${theme.copy_regular}
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(22)};
  margin-bottom: ${pxToRem(16)};
  color: ${theme.white};
`;

const StyledLink = Styled(Link)`
  text-decoration: none;
  color: ${theme.tertiary};
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(22)};
  ${theme.copy_regular}

  &:focus {
    text-decoration: underline;
  }
`;

const LinkWrapper = Styled.div`
  display: flex;
  align-items: center;
`;

const Icon = Styled.img`
  margin-left: ${pxToRem(6)};
`;

export default function Body({ actions, labels }) {
  const { trackEvent } = useContext(GaContext);
  return (
    <Wrapper>
      {items.map((item, index) => (
        <Card key={item}>
          <Picture>
            <source media={device.desktop} srcSet={images.body[index + 1].lg} />
            <source media={device.tablet} srcSet={images.body[index + 1].md} />
            <Image src={images.body[index + 1].sm} alt="" />
          </Picture>
          <Label>
            <FormattedMessage
              id={`home.body.${index + 1}.label`}
              defaultMessage="Translate this: Default message"
            />
          </Label>
          <BodyCopy>
            <FormattedMessage
              id={`home.body.${index + 1}.copy`}
              defaultMessage="Translate this: Default message"
            />
          </BodyCopy>
          <LinkWrapper>
            <StyledLink
              to={item}
              onClick={() => {
                trackEvent(actions[index], labels[index]);
              }}
            >
              <FormattedMessage
                id={`home.body.${index + 1}.btn`}
                defaultMessage="Translate this: Default message"
              />
            </StyledLink>
            <Icon src={arrowIcon} alt="" />
          </LinkWrapper>
        </Card>
      ))}
    </Wrapper>
  );
}
