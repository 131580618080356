const breakpoints = {
  sm: 780,
  md: 1200,
  lg: 1920
};

const device = {
  mobile: `(max-width: ${breakpoints.sm - 1}px)`,
  tablet: `(min-width: ${breakpoints.sm}px)`,
  tabletMax: `(max-width: ${breakpoints.md - 1}px)`,
  desktop: `(min-width: ${breakpoints.md}px)`,
  desktopMax: `(max-width: ${breakpoints.lg}px)`,
  huge: `(min-width: ${breakpoints.lg}px)`
};

export { breakpoints };
export default device;
