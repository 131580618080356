import { useContext } from "react";
import Styled from "styled-components";
import Modal from "react-modal";
import { useIntl } from "react-intl";

import { LocaleContext } from "../../../utils/locale-context";
import { device, theme } from "../../../constants";
import pxToRem from "../../../utils/pxToRem";
import closeIcon from "../assets/close.svg";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: pxToRem(1110),
    width: `calc(calc(100% - ${pxToRem(40)})`,
    padding: 0,
    borderRadius: "0",
    maxHeight: "calc(100vh - 40px)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.44)",
    zIndex: 2,
  },
};

const Inner = Styled.div`
  padding: ${pxToRem(24)} ${pxToRem(20)} ${pxToRem(20)};

  @media ${device.desktop} {
    padding: ${pxToRem(24)} ${pxToRem(40)} ${pxToRem(40)};
  }
`;

const Header = Styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: ${pxToRem(16)};
  `;

const CloseBtn = Styled.button`
    padding: 0;
    border: none;
    background: transparent;

    &:focus-visible {
      outline: 1px solid ${theme.primary};
    }
  `;

const Title = Styled.h2`
    ${theme.copy_regular}
    color: ${theme.black};
    font-size: ${pxToRem(20)};
    line-height: ${pxToRem(32)};
    margin-right: ${pxToRem(10)};
  `;

const IframeWrapper = Styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 */
`;

const Iframe = Styled.iframe`
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `;

Modal.setAppElement("#root");

export default function PlayerModal({
  item,
  isOpen,
  onClose,
  selectedLanguage,
}) {
  const { locale } = useContext(LocaleContext);
  const intl = useIntl();
  const closeLabel = intl.formatMessage({ id: "component.modal.close" });
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
      aria={{
        labelledby: "heading",
      }}
    >
      <Inner>
        <Header>
          <Title id="header">{item[`title-${locale}`]}</Title>
          <CloseBtn onClick={onClose} aria-label={closeLabel}>
            <img src={closeIcon} alt="" />
          </CloseBtn>
        </Header>
        <IframeWrapper>
          <Iframe
            src={item[`link-${selectedLanguage}`]}
            title="player"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          />
        </IframeWrapper>
      </Inner>
    </Modal>
  );
}
