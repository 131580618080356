import { routes } from "../../constants";

const LINKS = [
  {
    label: "nav.links.programme",
    url: routes.PROGRAM,
  },
  {
    label: "nav.links.agenda",
    url: routes.AGENDA,
  },
  {
    label: "nav.links.main_stage",
    url: routes.MAIN_STAGE,
    isMain: true,
  },
  {
    label: "nav.links.on_demand",
    url: routes.ON_DEMAND,
  },
  {
    label: "nav.links.explore",
    url: routes.EXPLORE,
  },
];

export const EXPERT = {
  label: "nav.links.expert",
  url: routes.EXPERT,
};

export default LINKS;
