import { useContext, useState, useEffect } from "react";
import Styled from "styled-components";
import { FormattedMessage } from "react-intl";

import pxToRem from "../../utils/pxToRem";
import { LocaleContext } from "../../utils/locale-context";
import { theme, device } from "../../constants";
import Cta from "../../components/Cta";

import img from "./images/img.png";

const Body = Styled.div`
  padding: ${pxToRem(32)} ${pxToRem(20)};
  max-width: ${pxToRem(548)};
  margin: auto;
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
      max-width: unset;
      flex-direction: row;
      padding: ${pxToRem(48)} ${pxToRem(121)};
  }

  @media ${device.desktop} {
    padding: ${pxToRem(96)} ${pxToRem(165)};
    max-width: ${pxToRem(1440)};
}
`;

const Title = Styled.p`
    ${theme.copy_bold}
    font-size: ${pxToRem(28)};
    line-height: ${pxToRem(34)};
    margin-bottom: ${pxToRem(8)};

    @media ${device.desktop} {
      font-size: ${pxToRem(40)};
      line-height: ${pxToRem(48)};
      margin-bottom: ${pxToRem(11)};
    }
`;

const Copy = Styled.p`
    ${theme.copy_regular}
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(24)};
    margin-bottom: ${pxToRem(27)};
`;

const Content = Styled.div`
    @media ${device.tablet} {
        flex: 1 1 50%;
        padding-right: ${pxToRem(15)};
    }

`;

const ImgWrapper = Styled.div`
    margin-top: ${pxToRem(32)};

    @media ${device.tablet} {
        margin-top: 0;
        padding-right: ${pxToRem(15)};
        flex: 1 1 50%;
    }
`;
const Img = Styled.img`
    max-width: 100%;
`;

const gateIdsPerLocale = {
  en: "#gate-2f7632e4-6472-4030-b139-3692eef8e785",
  fr: "#gate-3d488081-2383-4913-886f-cc2bdc3e0e68",
  de: "#gate-aec60431-c284-4cfa-8b38-1b0620675431",
  it: "#gate-54606376-303f-43ec-a767-487ccd7db560",
  es: "#gate-857ffabd-6ba1-4d6e-8c2b-58c94e7c1d20",
  ru: "#gate-d69b379e-5489-409f-8061-0e4c0c5deeef",
};

export default function ExpertBody() {
  const { locale } = useContext(LocaleContext);
  const [userLocale] = useState(locale);

  useEffect(() => {
    // need to trigger a complete reload to update the gateid
    window.gcdc("loadGates");
    if (locale !== userLocale) {
      window.location.reload();
    }
  }, [locale, userLocale]);

  return (
    <Body>
      <Content>
        <Title>
          <FormattedMessage id="expert.headline" defaultMessage="translate" />
        </Title>
        <Copy>
          <FormattedMessage id="expert.copy" defaultMessage="translate" />
        </Copy>
        <Cta as="a" href={gateIdsPerLocale[locale]} size="small" uppercase>
          <FormattedMessage id="expert.button" defaultMessage="translate" />
        </Cta>
      </Content>
      <ImgWrapper>
        <Img src={img} alt="" />
      </ImgWrapper>
    </Body>
  );
}
