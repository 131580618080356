import Styled from "styled-components";
import { theme, device } from "../../../constants";
import pxToRem from "../../../utils/pxToRem";

import triangle from "./alert-triangle.svg";

const Wrapper = Styled.div`
    background-color: #CC0000;
    padding: ${pxToRem(16)} ${pxToRem(20)} ${pxToRem(30)};
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${device.tablet} {
        flex-direction: row;
        padding: ${pxToRem(29)} 120px ${pxToRem(34)};
        margin: auto;
    }
    
    @media ${device.desktop} {
        padding: ${pxToRem(29)} 165px ${pxToRem(34)};
    }
`;

const Img = Styled.img`
    margin-bottom: ${pxToRem(9)};

    @media ${device.tablet} {
        margin-bottom: 0;
        margin-right: ${pxToRem(30)};
    }
`;

const Copy = Styled.p`
    color: ${theme.white};
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(18)};
    ${theme.copy_regular}

    @media ${device.tablet} {
        font-size: ${pxToRem(16)};
        line-height: ${pxToRem(24)};
    }
`;

export default function Banner() {
  return (
    <Wrapper>
      <Img src={triangle} alt="" />
      <Copy>
        Earlier today, our site was impacted by a mass web outage which has been
        affecting many sites globally, resulting in our video on-demand service
        being unavailable. Whilst a fix has now been put in place by our
        provider, you may experience some interruptions to our service this
        afternoon. We apologise for any disruption you may experience.
      </Copy>
    </Wrapper>
  );
}
