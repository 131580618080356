import Styled from "styled-components";
import { theme, device } from "../../../constants";
import pxToRem from "../../../utils/pxToRem";

const Wrapper = Styled.div`
    display: flex;
    flex-direction: column;
    border-top: ${pxToRem(1)} solid ${theme.light_grey_1};
    padding-bottom: ${pxToRem(20)};

    @media ${device.desktop} {
        flex-direction: row;
    }

    @media ${device.desktop} {
      padding-bottom: ${pxToRem(100)};
  }
`;

const Speaker = Styled.div`
    display: flex;
    padding-top: ${pxToRem(45)};

    @media ${device.desktop} {
        flex: 1 1 50%;
        padding-top: ${pxToRem(40)};

        &:nth-child(odd) {
            padding-right: ${pxToRem(15)};
        }

        &:nth-child(even) {
            padding-left: ${pxToRem(15)};
        }
          
    }
`;

const Img = Styled.img`
    width: ${pxToRem(50)};
    height: ${pxToRem(50)};
    margin-right: ${pxToRem(16)};
    flex-shrink: 0;
`;

const Content = Styled.div``;

const Name = Styled.p`
    ${theme.copy_bold};
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(22)};
`;

const Title = Styled.p`
    ${theme.copy_regular};
    font-size: ${pxToRem(12)};
    line-height: ${pxToRem(18)};
`;

export default function Speakers({ speakers }) {
  return (
    <Wrapper>
      {speakers.map((speaker) => (
        <Speaker key={speaker.name}>
          <Img src={speaker.image} alt="" />
          <Content>
            <Name>{speaker.name}</Name>
            <Title>{speaker.title}</Title>
          </Content>
        </Speaker>
      ))}
    </Wrapper>
  );
}
