import Styled from "styled-components";
import { theme, device } from "../../constants";
import pxToRem from "../../utils/pxToRem";
import chevronNextDark from "../../assets/icons/chevron-right-dark.svg";
import chevronPrevDark from "../../assets/icons/chevron-left-dark.svg";
import chevronNextLight from "../../assets/icons/chevron-right-light.svg";
import chevronPrevLight from "../../assets/icons/chevron-left-light.svg";

const Arrow = Styled.button`
  z-index: 1;
  height: ${pxToRem(44)};
  width: ${pxToRem(24)};
  background: ${(props) =>
    props.dark ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.1)"};
  color: ${theme.white};
  left: 2vw;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  padding: 0;

  ${({ isRight }) =>
    isRight &&
    `
    left: auto;
    right: 2vw;
  `}

  @media ${(props) => (props.showOnTablet ? device.mobile : device.tabletMax)} {
    display: none;
  }

  &[disabled] {
    display: none;
  }

  &:focus-visible {
    background: ${(props) =>
      props.dark ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 0, 0, 0.7)"};
  }
`;

function NextArrow(props) {
  const {
    onClick,
    label,
    dark,
    showOnTablet,
    isNotInfinite,
    currentSlide,
    slideCount,
  } = props;
  return (
    <Arrow
      onClick={onClick}
      aria-label={label}
      dark={dark}
      showOnTablet={showOnTablet}
      disabled={isNotInfinite && currentSlide === slideCount - 1}
      isRight
    >
      <img src={dark ? chevronNextDark : chevronNextLight} alt="" />
    </Arrow>
  );
}

function PrevArrow(props) {
  const { onClick, label, dark, showOnTablet, isNotInfinite, currentSlide } =
    props;
  return (
    <Arrow
      onClick={onClick}
      aria-label={label}
      showOnTablet={showOnTablet}
      dark={dark}
      disabled={isNotInfinite && currentSlide === 0}
    >
      <img src={dark ? chevronPrevDark : chevronPrevLight} alt="" />
    </Arrow>
  );
}

export { NextArrow, PrevArrow };
