import CENTRAL from "./o_central.json";
import ES from "./o_es.json";
import FR from "./o_fr.json";
import GB from "./o_gb.json";
import IT from "./o_it.json";
import NL from "./o_nl.json";
import NO from "./o_no.json";

export const ONDEMAND_PER_REGIONS = {
  o_es: ES,
  o_fr: FR,
  o_gb: GB,
  o_it: IT,
  o_nl: NL,
  o_no: NO,
};

export { CENTRAL };
