const region2country = {
  region01: {
    name: "UK & Ireland",
    countries: ["GB", "IE"],
    programme_jsons: ["p_gb"],
    ondemand_jsons: ["o_gb"],
  },
  region02: {
    name: "Germany",
    countries: ["AT", "DE"],
    programme_jsons: ["p_de"],
    ondemand_jsons: [],
  },
  region03: {
    name: "Belgium",
    countries: ["BE"],
    programme_jsons: ["p_de", "p_nl"],
    ondemand_jsons: ["o_nl"],
  },
  region04: {
    name: "Benelux",
    countries: ["NL", "LU"],
    programme_jsons: ["p_nl"],
    ondemand_jsons: ["o_nl"],
  },
  region05: {
    name: "France",
    countries: ["FR"],
    programme_jsons: ["p_fr"],
    ondemand_jsons: ["o_fr"],
  },
  region06: {
    name: "Italy",
    countries: ["IT"],
    programme_jsons: [],
    ondemand_jsons: ["o_it"],
  },
  region07: {
    name: "Norway",
    countries: ["NO"],
    programme_jsons: [],
    ondemand_jsons: ["o_no"],
  },
  region08: {
    name: "Spain",
    countries: ["ES"],
    programme_jsons: [],
    ondemand_jsons: ["o_es"],
  },
  region09: {
    name: "Switzerland",
    countries: ["CH"],
    programme_jsons: ["p_de", "p_fr"],
    ondemand_jsons: ["o_fr", "o_it"],
  },
  region10: {
    name: "DRGB",
    countries: [
      "PL",
      "SK",
      "HU",
      "LV",
      "EE",
      "LT",
      "CZ",
      "DZ",
      "BH",
      "EG",
      "IR",
      "IQ",
      "IL",
      "JO",
      "KW",
      "LB",
      "LY",
      "MA",
      "OM",
      "PS",
      "SA",
      "SY",
      "TN",
      "AE",
      "YE",
      "ZA",
      "CY",
      "MT",
      "AE",
      "GE",
      "SK",
      "QA",
      "RU",
      "AM",
      "AZ",
      "KZ",
      "TJ",
      "UZ",
      "BY",
      "RS",
      "ME",
      "HR",
      "BA",
      "TR",
      "AL",
      "MK",
      "BG",
      "RO",
      "SI",
      "UA",
      "GR",
    ],
    programme_jsons: ["p_drgb"],
    ondemand_jsons: [],
  },
};

const mapCountry2Region = (country) => {
  const region = Object.keys(region2country).find((key) =>
    region2country[key].countries.includes(country)
  );
  const regionData = region2country[region] || {
    programme_jsons: [],
    ondemand_jsons: [],
  };
  return regionData;
  //return region || "central";
};

export default mapCountry2Region;
