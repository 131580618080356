import { useContext } from "react";
import Styled from "styled-components";
import Slider from "react-slick";
import { FormattedMessage, useIntl } from "react-intl";

import { LocaleContext } from "../../../utils/locale-context";
import { GaContext } from "../../../utils/ga-context";
import pxToRem from "../../../utils/pxToRem";
import { theme, breakpoints, device } from "../../../constants";
import Cta from "../../../components/Cta";
import VisuallyHidden from "../../../components/VisuallyHidden";

import { getItemProps, ICONS_PER_TYPES, ICONS_PER_TYPE_CTA } from "../utils";

import { NextArrow, PrevArrow } from "../arrows";

const Wrapper = Styled.section`
  background: ${theme.base};
  padding: ${pxToRem(20)} ${pxToRem(0)} ${pxToRem(32)} ${pxToRem(0)};

  @media ${device.tablet} {
    padding: ${pxToRem(48)} ${pxToRem(0)} ${pxToRem(24)} ${pxToRem(0)};
  }

  .slick-dots {
    position: static;
    margin-top: ${pxToRem(20)};
  }

  .slick-dots li,
  .slick-dots li button {
    width: unset;
    color: ${theme.dark_grey_3};
  }


  .slick-dots li button:focus-visible:before {
    color: ${theme.white};
    opacity: 0.7;
  }
  .slick-dots li button:before {
    font-size: ${pxToRem(10)};
    color: ${theme.dark_grey_3};
    opacity: 1;
  }

  .slick-dots li.slick-active button:before {
    color: ${theme.white};
    opacity: 1;
  }

  .slick-list {
    position: relative;

    &:before {
      position: absolute;
      width: 11px;
      height: 101%;
      top: -1px;
      left: 0;
      z-index: 1;
      background: ${theme.base};
      content: '';

      @media ${device.tablet} {
        opacity: 0;
      }
    }

    &:after {
      position: absolute;
      width: 10px;
      height: 101%;
      top: -1px;
      right: 0;
      background: blue;
      z-index: 1;
      opacity: 0.7;
      background: ${theme.base};
      content: '';

      @media ${device.tablet} {
        opacity: 0;
      }
    }
  }
`;

const Slide = Styled.div`
  padding-left: 5px;
  padding-right: 5px;

  @media ${device.tablet} {
    display: flex;
    flex-direction: row-reverse;
    padding: 0 120px;
    margin: auto;
  }

  @media ${device.desktop} {
    padding: 0 165px;
  }
`;

const ImageWrapper = Styled.div`
  margin-bottom: ${pxToRem(16)};

  @media ${device.tablet} {
    width: ${pxToRem(332)};
    display: flex;
    align-items: center;
    margin-left: ${pxToRem(30)};
    flex: 1 1 50%;
  }
`;

const ImgPositioner = Styled.div`
  position: relative;
  width: 100%;
  display: block;
`;

const Content = Styled.div`
  color: ${theme.white};
  ${theme.copy_regular}

  @media ${device.tablet} {
    flex: 1 1 50%;
  }

  @media ${device.desktop} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
`;

const Img = Styled.img`
  width: 100%;
`;

const PlayIcon = Styled.img`
`;

const PlayWrapper = Styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 0;
  border: none;
  background: transparent;
  display: none;

  &:focus-visible {
    outline: 1px solid ${theme.primary};
  }

  .slick-current & {
    display: block;
  }
`;

const OverLine = Styled.p`
  font-size: ${pxToRem(14)};
  line-height:${pxToRem(20)};
  text-transform: uppercase;
  margin-bottom: ${pxToRem(8)};
`;

const Title = Styled.h2`
  ${theme.headline_medium}
  font-size: ${pxToRem(40)};
  line-height: ${pxToRem(40)};
  text-transform: uppercase;
  margin-bottom: ${pxToRem(16)};
  word-break: break-word;
  max-width: 100%;

  @media ${device.tablet} {
    margin-bottom: ${pxToRem(20)};
    font-size: ${pxToRem(52)};
    line-height: ${pxToRem(52)};
  }

  @media ${device.desktop} {
    margin-bottom: ${pxToRem(26)};
    font-size: ${pxToRem(64)};
    line-height: ${pxToRem(60)};
  }
`;

// const Body = Styled.p`
//   font-size: ${pxToRem(16)};
//   line-height: ${pxToRem(24)};
//   margin-bottom: ${pxToRem(23)};

//   @media ${device.desktop} {
//     max-width: ${pxToRem(800)};
//   }
// `;

const CtaIcon = Styled.img`
  margin-right: ${pxToRem(4)};
  width: ${pxToRem(24)};
`;

const Button = Styled.button`
  padding: 0;
  border: none;
  background: transparent;
  display: none;

  &:focus-visible {
    outline: 1px solid ${theme.primary};
  }

  .slick-current & {
    display: block;
  }
`;

export default function Header({ items, onItemClick }) {
  const { locale } = useContext(LocaleContext);
  const { trackEvent } = useContext(GaContext);
  const intl = useIntl();
  const nextLabel = intl.formatMessage({ id: "component.carousel.next" });
  const prevLabel = intl.formatMessage({ id: "component.carousel.prev" });
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <NextArrow label={nextLabel} dark />,
    prevArrow: <PrevArrow label={prevLabel} dark />,
    responsive: [
      {
        breakpoint: breakpoints.sm - 1,
        settings: {
          centerMode: true,
          centerPadding: "15px",
        },
      },
    ],
    autoplay: true,
    autoplaySpeed: 6000,
  };
  return (
    <Wrapper>
      <Slider {...settings}>
        {items.map((item) => {
          const type = item.type.toLowerCase();
          let props = getItemProps(item);
          props.onClick = () => {
            trackEvent("content launched", item[`title-${locale}`]);
          };

          if (props.as === "button") {
            props.onClick = () => onItemClick(item);
          }
          return (
            <div key={item.uid}>
              <Slide>
                <ImageWrapper>
                  <ImgPositioner>
                    <VisuallyHidden as="span">
                      {" "}
                      <FormattedMessage
                        id="ondemand.video.icons.play"
                        defaultMessage="TRANSLATE THIS"
                      />
                    </VisuallyHidden>
                    <Img src={`/ondemand/thumbnails/${item.image}`} alt="" />
                    <PlayWrapper {...props}>
                      <PlayIcon src={ICONS_PER_TYPES[type]} alt="" />
                    </PlayWrapper>
                  </ImgPositioner>
                </ImageWrapper>
                <Content>
                  <OverLine>
                    <FormattedMessage
                      id="ondemand.top.title"
                      defaultMessage="TRANSLATE THIS"
                    />
                  </OverLine>
                  <Title>{item[`title-${locale}`]}</Title>
                  <Button as="div">
                    <Cta uppercase {...props} size="small" hasIcon isOndemand>
                      <CtaIcon src={ICONS_PER_TYPE_CTA[type]} alt="" />
                      <FormattedMessage
                        id={`ondemand.top.cta.${type}`}
                        defaultMessage="TRANSLATE THIS"
                      />
                    </Cta>
                  </Button>
                </Content>
              </Slide>
            </div>
          );
        })}
      </Slider>
    </Wrapper>
  );
}
