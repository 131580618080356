export default [
  {
    id: "1",
    name: "MARS 2",
    city: "Mars 2 was an uncrewed space probe of the Mars program, a series of uncrewed Mars landers and orbiters launched by the Soviet Union beginning 19 May 1971.",
    content: "",
    video: "https://www.youtube.com/embed/yDlCGFYqkGY",
    lat: "45",
    long: "313",
    coordinates: [45, 313],
  },
  {
    id: "2",
    name: "MARS 3",
    city: "Mars 3 was a robotic space probe of the Soviet Mars program, launched May 28, 1971, nine days after its twin spacecraft Mars 2.",
    content: "",
    video: "https://www.youtube.com/embed/yDlCGFYqkGY",
    lat: "45",
    long: "158",
    coordinates: [45, 158],
  },
  {
    id: "3",
    name: "MARS 6",
    city: "Mars 6 was a Soviet spacecraft launched to explore Mars 5 August, 1973.",
    content: "",
    video: "https://www.youtube.com/embed/a0Sok7cyDXo",
    lat: "23.90",
    long: "19.42",
    coordinates: [23.90, 19.42],
  },
  {
    id: "4",
    name: "VIKING 1",
    city: "Viking 1 was the first of two spacecrafts sent to Mars as part of NASA's Viking program,landing on July 20, 1976. It was the first successful Mars lander in history.",
    content: "",
    video: "https://www.youtube.com/embed/2omjPrRxJBk",
    lat: "22.48",
    long: "49.97",
    coordinates: [22.48, 49.97],
  },
  {
    id: "5",
    name: "MARS POLAR LANDER",
    city: "The Mars Polar Lande was a 290-kilogram robotic spacecraft lander launched by NASA on January 3, 1999. Premature termination of the engine firing prior to the lander touching the surface, caused it to strike the planet at a high velocity.",
    content: "",
    video: "https://www.youtube.com/embed/VWCG1LugTz8",
    lat: "76.57",
    long: "165.2",
    coordinates: [76.57, 165.2],
  },
  {
    id: "6",
    name: "Frederic Morlie",
    city: "Paris, France",
    content: "",
    video: "https://web.microsoftstream.com/embed/video/9db1263d-3ecd-461a-8642-a34b76258980",
    lat: "48.856613",
    long: "2.352222",
    coordinates: [48.856613, 2.352222],
  },
  {
    id: "7",
    name: "PATHFINDER",
    city: "Mars Pathfinder is an American robotic spacecraft that landed a base station with a roving probe on Mars in 1997.",
    content: "",
    video: "https://youtube.com/embed/3E9BcdFYENg?t=12",
    lat: "19.33",
    long: "33.55",
    coordinates: [19.33, 33.55],
  },
  {
    id: "8",
    name: "BEAGLE 2",
    city: "Beagle 2 was successfully launched on 19 December 2003 and was scheduled to land on 25 December; however, no contact was received at the expected time of landing. ESA declared the mission lost in February 2004.",
    content: "",
    video: "https://www.youtube.com/embed/gzJqLzT7HvE",
    lat: "10.6",
    long: "270",
    coordinates: [10.6, 270],
  },
  {
    id: "9",
    name: "SPIRIT",
    city: "Spirit is a robotic rover active from 2004 to 2010. Aided by cleaning events that resulted in more energy from its solar panels, Spirit went on to function effectively over twenty times longer than NASA planners expected.",
    content: "",
    video: "https://www.youtube.com/embed/6t3IARmIdOI",
    lat: "14.571892",
    long: "-175.47848",
    coordinates: [14.571892, -175.47848],
  },
  {
    id: "10",
    name: "OPPORTUNITY",
    city: "Opportunity was operational on Mars for 5110 sols. Launched on July 7, 2003. Careful operation allowed Opportunity to operate for 57 times its designed lifespan, exceeding the initial plan by 14 years, 46 days (Earth time).",
    content: "",
    video: "https://www.youtube.com/embed/1Ll-VHYxWXU",
    lat: "1.9462",
    long: "-354.4734",
    coordinates: [1.9462, -354.4734],
  },
  {
    id: "11",
    name: "PHEONIX",
    city: "Phoenix was an unmanned space probe that landed on the surface of Mars on May 25, 2008 and operated until November 2, 2008.",
    content: "",
    video: "https://www.youtube.com/embed/hH5pNFROlYU",
    lat: "68.218830",
    long: "-234.250778",
    coordinates: [68.218830, -234.250778],
  },
  {
    id: "13",
    name: "CURIOSITY",
    city: "Curiosity was launched on 26 November 2011, landing on 6 August 2012. The rover's goals include an investigation of the Martian climate and geology, and assessment of whether Mars has ever offered conditions favorable for microbial life.",
    content: "",
    video: "https://www.youtube.com/embed/P4boyXQuUIw",
    lat: "4.5895",
    long: "-137.4417",
    coordinates: [4.5895, -137.4417],
  },
  {
    id: "14",
    name: "INSIGHT",
    city: "The Interior Exploration using Seismic Investigations, Geodesy and Heat Transport mission, launched 5 May, 2018 is a robotic lander designed to study the deep interior of the planet Mars.",
    content: "",
    video: "https://www.youtube.com/embed/C0lwFLPiZEE",
    lat: "2.502",
    long: "-132.623",
    coordinates: [2.502, -132.623],
  },
  {
    id: "15",
    name: "EXOMARS SCHIAPARELLI",
    city: "Schiaparelli EDM was a failed Entry, Descent, and Landing Demonstrator Module (EDM) of the ExoMars programme—a joint mission of the European Space Agency (ESA) and the Russian space agency Roscosmos.",
    content: "",
    video: "https://www.youtube.com/embed/CuxqW_4xN08",
    lat: "2.9462",
    long: "-340.4734",
    coordinates: [2.9462, -340.4734],
  },
  {
    id: "16",
    name: "ROSALIND FRANKLIN ROVER",
    city: "Rosalind Franklin, previously known as the ExoMars rover, is a planned robotic Mars rover, part of the international ExoMars programme led by the European Space Agency and the Russian Roscosmos State Corporation.",
    content: "",
    video: "https://www.youtube.com/embed/BNItE7zjhq8",
    lat: "-18.1",
    long: "-335.7",
    coordinates: [-18.1, -335.7],
  },
  {
    id: "17",
    name: "PERSERVERANCE ROVER",
    city: "Perseverance launched on 30 July 2020. The rover also carried the mini-helicopter Ingenuity to Mars, an experimental aircraft and technology showcase that made the first powered flight on another planet on 19 April 2021.",
    content: "",
    video: "https://www.youtube.com/embed/rzmd7RouGrM&t=128s",
    lat: "-18.3628",
    long: "-77.5945",
    coordinates: [-18.3628, -77.5945],
  }
];
