import { useRef, forwardRef, useContext, useEffect } from "react";

import Styled from "styled-components";

import { LocaleContext } from "../../../../utils/locale-context";
import { theme, device } from "../../../../constants";
import pxToRem from "../../../../utils/pxToRem";

const Wrapper = Styled.div`
    padding-left: ${pxToRem(20)};

    @media ${device.tablet} {
        padding: 0 ${pxToRem(121)};
    }


    @media ${device.desktop} {
        padding: 0 ${pxToRem(165)};
    }
`;

const MAX_SLIDES_MOBILE = 5;
//width: total screen width, minus padding - item margin
const Slide = Styled.button`
    ${(props) =>
      `width: calc((100vw - ${pxToRem(40)}) / ${
        props.totalSlides > MAX_SLIDES_MOBILE
          ? MAX_SLIDES_MOBILE
          : props.totalSlides
      } - ${pxToRem(4)}); `}
    min-height: ${pxToRem(48)};
    background: ${theme.light_grey_2};
    border: ${pxToRem(1)} solid transparent;
    border-bottom: ${pxToRem(4)} solid transparent;
    margin-right: ${pxToRem(4)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${theme.copy_regular}
    color: ${theme.black};

    &:focus-visible {
      border-bottom-color: ${theme.secondary};
    }

    @media ${device.tablet} {
      ${(props) => `
        width: calc((100vw - ${pxToRem(121 * 2)}) / ${
        props.totalSlides
      }  - ${pxToRem(4)})
      `}
    }

    @media ${device.desktop} {
      ${(props) => `
        width: calc((100vw - ${pxToRem(165 * 2)}) / ${
        props.totalSlides
      }  - ${pxToRem(4)})
      `}
    }
    
    &[disabled] {
        opacity: 0.3;
    }


  ${(props) =>
    props.isActive &&
    `
      background: ${theme.white};
      border-color: ${theme.light_grey_2};
      border-bottom-color: ${theme.secondary};
  `}


    // @media ${device.tablet} {
    //     min-width: 6vw;
    // }
`;

const Name = Styled.p`
    font-size: ${pxToRem(9)};
    line-height: ${pxToRem(14)};

    text-align: center;
    text-transform: uppercase;
    margin-bottom: ${pxToRem(1)};
`;

const Date = Styled.p`
    ${theme.copy_bold};
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(20)};
`;

const Slider = Styled.div`
    max-width: 100%;
    overflow-x: scroll;
    display: flex;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
`;

const Inner = Styled.div`
    display: flex;
    padding-right: ${pxToRem(20)};

    @media ${device.tablet} {
      padding-right: 0;

    }
`;

export default forwardRef((props, ref) => {
  const sliderRef = useRef(null);
  const { activeIndex, onClick } = props;
  const { moment } = useContext(LocaleContext);

  useEffect(() => {
    if (sliderRef.current) {
      const btn = sliderRef.current.querySelector("button");

      if (btn) {
        const scroll = btn.getBoundingClientRect().width + 4;
        sliderRef.current.scroll({
          left: activeIndex * scroll,
          behavior: "smooth",
        });
      }
    }
  }, [activeIndex]);

  const handleSlideClick = (i) => {
    onClick(i);
  };

  return (
    <Wrapper>
      <Slider ref={sliderRef}>
        <Inner>
          {props.dates.map((date, index) => {
            return (
              <Slide
                onClick={() => handleSlideClick(date.id)}
                disabled={date.isWeekend}
                isActive={activeIndex === date.id}
                id={`slide-${index}`}
                key={`slide-${index}`}
                totalSlides={props.dates.length}
              >
                <Name>{moment(date.date).format("ddd")}</Name>
                <Date>{moment(date.date).format("D")}</Date>
              </Slide>
            );
          })}
        </Inner>
      </Slider>
    </Wrapper>
  );
});
