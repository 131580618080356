import Styled from "styled-components";
import { FormattedMessage } from "react-intl";

import lg from "./images/lg.png";
import pxToRem from "../../../utils/pxToRem";
import { device, theme } from "../../../constants";

const StyledHeader = Styled.div`
    height: ${pxToRem(375)};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.white};
    margin-bottom: ${pxToRem(35)};

    background: url(${lg}) no-repeat center center / cover;  

    @media ${device.tablet} {
      margin-bottom: ${pxToRem(50)};
    }

    @media ${device.desktop} {
        margin-bottom: ${pxToRem(80)};
    }
`;

const Title = Styled.h1`
    text-transform: uppercase;
    ${theme.headline_medium}
    font-size: ${pxToRem(52)};
    line-height: ${pxToRem(52)};
    word-break: break-word;
    text-align: center;

    @media ${device.tablet} {
        font-size: ${pxToRem(73)};
        line-height: ${pxToRem(52)};
    }

    @media ${device.desktop} {
        font-size: ${pxToRem(80)};
        line-height: ${pxToRem(80)};
    }
`;

export default function Header() {
  return (
    <StyledHeader>
      <Title>
        <FormattedMessage id="agenda.header.title" defaultMessage="Translate" />
      </Title>
    </StyledHeader>
  );
}
