import Styled from "styled-components";
import { FormattedMessage } from "react-intl";

import pxToRem from "../../../utils/pxToRem";
import { theme } from "../../../constants";

const StyledPill = Styled.div`
    background: ${theme.black};
    border-radius: ${pxToRem(50)};
    padding: ${pxToRem(2)} ${pxToRem(4)};
    padding-right: ${pxToRem(18)};
    ${theme.copy_regular}
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(24)};
    color: ${theme.white};
    margin-left: ${pxToRem(9)};
    display: inline-flex;
    align-items: center;
`;

const PillNumber = Styled.span`
    border-radius: ${pxToRem(50)};
    background: ${theme.white};
    padding: ${pxToRem(2)} ${pxToRem(6)};
    color: ${theme.dark_grey_1};
    font-size: ${pxToRem(12)};
    line-height: ${pxToRem(16)};
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: ${pxToRem(20)};
    margin-right: ${pxToRem(6)};
    ${theme.copy_bold}
`;

export default function Pill({ number }) {
  return (
    <StyledPill>
      <PillNumber>{number}</PillNumber>
      <FormattedMessage
        id="programme.item.selected"
        defaultMessage="TRANSLATE"
      />
    </StyledPill>
  );
}
