import { FormattedMessage } from "react-intl";
import Styled from "styled-components";
import { theme } from "../../../constants";
import pxToRem from "../../../utils/pxToRem";

const Wrapper = Styled.div`
    width: 100%;
`;

const Copy = Styled.p`
    ${theme.copy_regular};
    font-size: ${pxToRem(13)};
    line-height: ${pxToRem(24)};
`;

const Button = Styled.button`
    padding: 0;
    border: 0;
    background: 0;
    color: ${theme.primary};
    font-family: inherit;

    &:focus,
    &:hover {
      text-decoration: underline;
    }
`;

export default function BackupUrl({ onClick }) {
  return (
    <Wrapper>
      <Copy>
        <FormattedMessage
          id="main-stage.backup.url"
          defaultMessage="In the event that the video stops working, <link>please use this backup link</link>"
          values={{ link: (copy) => <Button onClick={onClick}>{copy}</Button> }}
        />
      </Copy>
    </Wrapper>
  );
}
