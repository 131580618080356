import { useEffect, useContext } from "react";

import Page from "../../components/Page";
import { GaContext } from "../../utils/ga-context";

import Header from "./Header";
import Body from "./Body";

export default function Terms() {
  const { trackEvent } = useContext(GaContext);
  useEffect(() => {
    trackEvent("Hygiene - Cookie information", "Cookie information");
  }, []);

  return (
    <Page>
      <Header />
      <Body />
    </Page>
  );
}
