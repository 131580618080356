import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// import ReactGA from "react-ga";

export default function ScrollToTop() {
  const { pathname } = useLocation();
  // ReactGA.set({ page: pathname });
  // ReactGA.pageview(pathname);

  useEffect(
    () => {
      window.scrollTo(0, 0);
    },
    [pathname]
  );

  return null;
}
