import { useContext } from "react";
import { UserMetaContext } from "../../utils/metadata-context";

import { auth0Keys } from "../../constants";

import Onboarding from "../onboarding";
import HomeScreen from "./Home";

export default function Home() {
  const { user } = useContext(UserMetaContext);
  return user?.user_metadata?.[auth0Keys.HAS_COMPLETED_ONBOARDING] ? (
    <HomeScreen />
  ) : (
    <Onboarding />
  );
}
