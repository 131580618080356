import { useContext } from "react";
import Styled from "styled-components";

import { LocaleContext } from "../../../utils/locale-context";
import {
  getIsOnlyOneLang,
  getTitle,
  getDescription,
} from "../../../utils/data";
import { theme, device } from "../../../constants";
import pxToRem from "../../../utils/pxToRem";
import Location from "../../Location";
import OnlyLanguage from "../../OnlyLanguage";
import Buttons from "../../ActionButtons";

const StyledBody = Styled.div`
    display: flex;
    flex-direction: column;

    @media ${device.tablet} {
        flex-direction: row;
        align-items: flex-start;
    }
`;

const ImgWrapper = Styled.div`
    margin-bottom: ${pxToRem(14)};

    @media ${device.tablet} {
        flex: 1 1 50%;
        padding-right: ${pxToRem(30)};
    }
`;

const EventImg = Styled.img`
    width: 100%;
`;

const Content = Styled.div`
    margin-bottom: ${pxToRem(30)};

    @media ${device.tablet} {
        padding-right: ${pxToRem(30)};
        flex: 1 1 50%;
    }
`;

const Title = Styled.h2`
    ${theme.copy_bold}
    font-size: ${pxToRem(18)};
    line-height: ${pxToRem(26)};
    margin-top: ${pxToRem(11)};

    @media ${device.tablet} {
        font-size: ${pxToRem(20)};
        line-height: ${pxToRem(32)};
    }
`;

const ButtonsWrapper = Styled.div`
    margin: ${pxToRem(22)} 0;
`;

const Description = Styled.p`
    ${theme.copy_regular}
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(24)};
    color: ${theme.dark_grey_3};
`;

const Wrapper = Styled.div`
    display: flex;
    justify-content: space-between;
`;

export default function Body({ item, isAgenda, gaActionPrefix }) {
  const { locale } = useContext(LocaleContext);
  const { image } = item;

  const isOnlyOneLang = getIsOnlyOneLang(item);
  const title = getTitle(item, locale, isOnlyOneLang);
  const desc = getDescription(item, locale, isOnlyOneLang);

  return (
    <StyledBody>
      <ImgWrapper>
        <EventImg src={`/program/thumbnails/${image}`} alt="" />
      </ImgWrapper>
      <Content>
        <Wrapper>
          <Location type={item.type} />
          {isOnlyOneLang.length === 1 && (
            <OnlyLanguage lang={isOnlyOneLang[0]} />
          )}
        </Wrapper>
        <Title>{title}</Title>
        <ButtonsWrapper>
          <Buttons
            item={item}
            isCol
            size="large"
            isAgenda={isAgenda}
            gaActionPrefix={gaActionPrefix}
          />
        </ButtonsWrapper>

        <Description>{desc}</Description>
      </Content>
    </StyledBody>
  );
}
