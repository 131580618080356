import Styled from "styled-components";
import { FormattedMessage } from "react-intl";

import pxToRem from "../../utils/pxToRem";
import { theme, types } from "../../constants";

import teams from "./teams.png";
import webinar from "./webinar.png";

const StyledLocation = Styled.div`
    ${theme.copy_regular}
    text-transform: uppercase;
    font-size: ${pxToRem(12)};
    line-height: ${pxToRem(16)};
    display: flex;
    align-items: center;
`;

const Copy = Styled.p``;

const Img = Styled.img`
  margin-right: ${pxToRem(6)};
`;

const renderLocationByType = (type) => {
  if (type === types.brightcove) {
    return (
      <Copy>
        <FormattedMessage
          id="event.location.main-stage"
          defaultMessage="TRANSLATE"
        />
      </Copy>
    );
  }

  if (type === types.teams) {
    return (
      <>
        <Img src={teams} alt="" />
        <Copy>Teams</Copy>
      </>
    );
  }

  if (type === types.gotowebinar) {
    return (
      <>
        <Img src={webinar} alt="" />
        <Copy>Gotowebinar</Copy>
      </>
    );
  }
};
export default function Location({ type }) {
  return <StyledLocation>{renderLocationByType(type)}</StyledLocation>;
}
