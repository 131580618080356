import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import Styled from "styled-components";
import { theme, device, routes } from "../../../constants";
import pxToRem from "../../../utils/pxToRem";
import Cta from "../../../components/Cta";

const Wrapper = Styled.div`
    padding: ${pxToRem(24)} ${pxToRem(20)};

    @media ${device.tablet} {
        padding: ${pxToRem(24)} ${pxToRem(121)} ${pxToRem(89)};
    }

    @media ${device.desktop} {
        padding: ${pxToRem(24)} ${pxToRem(165)} ${pxToRem(81)};
    }
`;

const Inner = Styled.div`
    background: ${theme.light_grey_3};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${pxToRem(44)} ${pxToRem(15)} ${pxToRem(43)};
    text-align: center;
    color: ${theme.dark_grey_1};
    border: ${pxToRem(1)} solid ${theme.light_grey_2};

    @media ${device.tablet} {
        padding: ${pxToRem(37)} ${pxToRem(60)} ${pxToRem(40)};
    }

    @media ${device.desktop} {
        padding: ${pxToRem(53)} ${pxToRem(190)} ${pxToRem(50)};
    }
`;

const Title = Styled.h2`
    ${theme.copy_bold}
    font-size: ${pxToRem(20)};
    line-height: ${pxToRem(32)};
    margin-bottom: ${pxToRem(5)};

    @media ${device.desktop} {
        font-size: ${pxToRem(28)};
        line-height: ${pxToRem(34)};
    }
`;

const Copy = Styled.div`
    ${theme.copy_regular}
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(24)};
    margin-bottom: ${pxToRem(20)};

    @media ${device.tablet} {
        margin-bottom: ${pxToRem(13)};
    }

    @media ${device.desktop} {
        max-width: ${pxToRem(730)};
        margin: auto;
        margin-bottom: ${pxToRem(22)};
    }
`;

export default function Info() {
  return (
    <Wrapper>
      <Inner>
        <Title>
          <FormattedMessage
            id="agenda.empty.title"
            defaultMessage="translate"
          />
        </Title>
        <Copy>
          <FormattedMessage id="agenda.empty.copy" defaultMessage="translate" />
        </Copy>
        <Cta as={Link} to={routes.PROGRAM} uppercase>
          <FormattedMessage id="agenda.empty.cta" defaultMessage="translate" />
        </Cta>
      </Inner>
    </Wrapper>
  );
}
