import Styled from "styled-components";
import Modal from "react-modal";

import { theme, device } from "../../constants";
import pxToRem from "../../utils/pxToRem";

import Header from "./Header";
import Body from "./Body";
import Footer from "./Footer";

const customStyles = {
  content: {
    width: "100%",
    padding: `${pxToRem(24)} ${pxToRem(0)} ${pxToRem(40)}`,
    borderRadius: "0",
    maxHeight: "100vh",
    overflow: "hidden",
    display: "flex",
    top: "50%",
    position: "absolute",
    transform: "translateY(-50%)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.44)",
    zIndex: 2,
  },
};

const Inner = Styled.div`
    background: ${theme.white};
    margin: 0 ${pxToRem(20)};
    padding: ${pxToRem(10)} ${pxToRem(20)};
    overflow-y: scroll;
    width: 100%;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    @media ${device.tablet} {
        margin: 0 ${pxToRem(121)};
    }


    @media ${device.desktop} {
        margin: 0 ${pxToRem(165)};
        padding: ${pxToRem(10)} ${pxToRem(30)};
    }
`;

const StyledModal = Styled(Modal)`
`;

Modal.setAppElement("#root");

export default function EventModal({
  item,
  isOpen,
  onClose,
  isAgenda,
  gaActionPrefix,
}) {
  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
      aria={{
        labelledby: "heading",
      }}
    >
      <Inner>
        <Header item={item} onClose={onClose} />
        <Body item={item} isAgenda={isAgenda} gaActionPrefix={gaActionPrefix} />
        <Footer item={item} />
      </Inner>
    </StyledModal>
  );
}
