import { useContext } from "react";
import Styled from "styled-components";
import Modal from "react-modal";
import { FormattedMessage, useIntl } from "react-intl";

import { LocaleContext } from "../../../utils/locale-context";
import { GaContext } from "../../../utils/ga-context";
import { theme } from "../../../constants";
import pxToRem from "../../../utils/pxToRem";
import closeIcon from "../assets/close.svg";
import pdfIcon from "../assets/lang-select-pdf.svg";
import demotoolIcon from "../assets/lang-select-demotool.svg";
import videoIcon from "../assets/lang-select-video.svg";
import podcastIcon from "../assets/lang-select-podcast.svg";

import { getLangSelectItemProps } from "../utils";
import { LANGUAGES } from "../../../components/Nav/consts";

import { VIDEO, PODCAST, URL, PDF } from "../utils";

const ICONS_PER_TYPE = {
  [PDF]: pdfIcon,
  [VIDEO]: videoIcon,
  [PODCAST]: podcastIcon,
  [URL]: demotoolIcon,
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: pxToRem(377),
    width: "100%",
    padding: `${pxToRem(18)} ${pxToRem(20)} ${pxToRem(23)}`,
    borderRadius: "0",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.44)",
    zIndex: 2,
  },
};

const Header = Styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: ${pxToRem(16)};
    position: absolute;
    right: ${pxToRem(20)};
    top: ${pxToRem(18)};
  `;

const Body = Styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `;

const Icon = Styled.img`
  margin-top: ${pxToRem(7)};
`;

const CloseBtn = Styled.button`
    padding: 0;
    border: none;
    background: transparent;

    &:focus-visible {
      outline: 1px solid ${theme.primary};
    }
  `;

const Title = Styled.h2`
    ${theme.copy_regular}
    color: ${theme.black};
    font-size: ${pxToRem(20)};
    line-height: ${pxToRem(32)};
    margin-right: ${pxToRem(10)};
  `;

const Options = Styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
  `;

const Option = Styled.button`
    border: none;
    padding: ${pxToRem(16)} 0; 
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${theme.dark_grey_1};
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(24)};
    ${theme.copy_regular}
    text-decoration: none;

    &:focus-visible {
      outline: 1px solid ${theme.primary};
    }
  `;

const Flag = Styled.img`
    width: ${pxToRem(24)};
    margin-right: ${pxToRem(15)};
  `;

const Inner = Styled.div`
    display: flex;
    align-items: center;
  `;

const Tag = Styled.p`
  ${theme.copy_regular}
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(16)};
  width: 100%;
  text-transform: uppercase;
  margin-top: ${pxToRem(6)};
  margin-bottom: ${pxToRem(5)};
  color: ${theme.dark_grey_3};
`;

Modal.setAppElement("#root");

export default function LangSelectorModal({ item, isOpen, onClose }) {
  const { locale } = useContext(LocaleContext);
  const { trackEvent } = useContext(GaContext);
  const intl = useIntl();
  const closeLabel = intl.formatMessage({ id: "component.modal.close" });
  if (!item.availableLangs) return null;

  const type = item.type.toLowerCase();
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onClose()}
      style={customStyles}
      aria={{
        labelledby: "heading",
      }}
    >
      <Header>
        <CloseBtn onClick={() => onClose()} aria-label={closeLabel}>
          <img src={closeIcon} alt="" />
        </CloseBtn>
      </Header>
      <Body>
        <Icon src={ICONS_PER_TYPE[type]} alt="" />
        <Tag>
          <FormattedMessage
            id={`ondemand.type.${type}`}
            defaultMessage={type}
          />
        </Tag>
        <Title id="header">{item[`title-${locale}`]}</Title>
        <Options>
          {item.availableLangs.map((locale) => {
            const loc = LANGUAGES.find((l) => l.id === locale);
            const props = getLangSelectItemProps(item, locale);
            props.onClick = () => {
              trackEvent("On-demand content selection", item[`title-en`]);
            };

            if (props.as === "button") {
              props.onClick = () => onClose(locale);
            }
            return (
              <Option key={locale} {...props}>
                <Inner>
                  <Flag src={loc.img} alt="" />
                  <FormattedMessage id={loc.label} defaultMessage="TRANSLATE" />
                </Inner>
                <img src={props.img} alt="" />
              </Option>
            );
          })}
        </Options>
      </Body>
    </Modal>
  );
}
