import { useContext, useState, useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment";
// USE THIS WHEN WE HAVE CUSTOM URL
// const custom_domain = process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN;
// const USERS_API = `https://${custom_domain}/api/v2/users/`;
import { LocaleContext } from "./locale-context";
import useLocalStorage from "./use-local-storage";

const domain = process.env.REACT_APP_AUTH0_DOMAIN;

const custom_domain = process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN;
const USERS_API = `https://${custom_domain}/api/v2/users/`;
const audience = `https://${domain}/api/v2/`;
const scope = "read:current_user";

export default function useUsersMetaData(key, initialValue) {
  const { selectLanguage } = useContext(LocaleContext);
  const [localData, setLocalData] = useLocalStorage("metadata", {});
  const [userMetadata, setUserMetadata] = useState({
    isLoading: true,
    isUpdating: true,
  });
  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();

  const getUserMetadata = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience,
        scope,
      });

      const userDetailsByIdUrl = `${USERS_API}${user.sub}`;
      const metadataResponse = await fetch(userDetailsByIdUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const { user_metadata } = await metadataResponse.json();

      let data = {};
      // meta data has been synced
      if (user_metadata.updated_at) {
        if (localData.updated_at) {
          // both auth0 and local has been synced
          // find the latest version
          if (moment(user_metadata.updated_at).isBefore(localData.updated_at)) {
            // local is after
            // use after
            data = { ...user_metadata, ...localData };
          } else {
            // auth0 is latest
            data = { ...user_metadata };
          }
        } else {
          // no local data exist
          data = { ...user_metadata };
        }
      } else {
        // meta data has never been synced
        if (localData.updated_at) {
          // local data has been synced
          // should sync auth0
          // this should only be locale, if any
          data = { ...user_metadata, ...localData };
        } else {
          // no local data exist
          // fresh user
          data = { ...user_metadata };
        }
      }

      if (data.locale) {
        selectLanguage(data.locale);
      }

      //console.log(`Got user metadata: ${JSON.stringify(user_metadata)}`);
      return setUserMetadata({
        ...data,
        isLoading: false,
        isUpdating: false,
      });
    } catch (e) {
      console.log(`Error getting user meta data: ${e.message}`);
    }
  }, [getAccessTokenSilently, user, selectLanguage, localData]);

  const updateUserMetaData = useCallback(
    async (user_metadata) => {
      // if event, invite = user should sync agenda
      try {
        if (user_metadata.locale) {
          selectLanguage(user_metadata.locale);
        }

        //store in local storage
        setLocalData({
          ...userMetadata,
          ...user_metadata,
          updated_at: moment(),
        });

        // update in memory
        setUserMetadata({
          ...userMetadata,
          ...user_metadata,
        });
        //console.log(`User meta data changed locally: `, response);
      } catch (e) {
        console.log(`Error updating user meta data locally: ${e.message}`);
      }
    },
    [userMetadata, selectLanguage, setLocalData]
  );

  const syncUserMetaData = useCallback(
    async (dataToSync = {}) => {
      // do not try to update when update is in progress
      if (userMetadata.isUpdating) return;
      try {
        const accessToken = await getAccessTokenSilently({
          audience,
          scope: "update:current_user_metadata",
        });

        const userDetailsByIdUrl = `${USERS_API}${user.sub}`;
        const metadataResponse = await fetch(userDetailsByIdUrl, {
          method: "PATCH",
          crossDomain: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            user_metadata: {
              ...userMetadata,
              ...dataToSync,
              updated_at: moment(),
            },
          }),
        });

        const response = await metadataResponse;
        // console.log(`User meta data synced to auth0: `, response);
        getUserMetadata();
      } catch (e) {
        console.log(`Error updating user meta data: ${e.message}`);
      }
    },
    [getAccessTokenSilently, user, getUserMetadata, userMetadata]
  );

  useEffect(() => {
    if (isAuthenticated && userMetadata.isLoading) {
      getUserMetadata();
    }
  }, [isAuthenticated, getUserMetadata, userMetadata.isLoading]);

  return [
    { ...user, user_metadata: userMetadata },
    updateUserMetaData,
    syncUserMetaData,
  ];
}
