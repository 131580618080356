import { useContext, useCallback, useState, useEffect } from "react";
import { StickyContainer, Sticky } from "react-sticky";
import Styled from "styled-components";

import { UserMetaContext } from "../../utils/metadata-context";
import { GaContext } from "../../utils/ga-context";

import pxToRem from "../../utils/pxToRem";
import { sortByDates } from "../../utils/data";
import EventModal from "../../components/EventModal";
import Page from "../../components/Page";

import Header from "./Header";
import Info from "./Info";
import Body from "./Body";
import Sync from "./Sync";
import StickySync from "./StickySync";
import { theme } from "../../constants";

const Inner = Styled.div`
  background: ${theme.white};
`;

export default function Agenda() {
  const { agenda, user, syncUser, updateUser } = useContext(UserMetaContext);
  const { trackEvent } = useContext(GaContext);
  const { user_metadata } = user;
  const { should_sync } = user_metadata;

  const [eventModalIsOpen, setEventModalIsOpen] = useState(false);
  const [eventsByDates, setEventsByDates] = useState({});
  const [selectedEvent, setSelectedEvent] = useState({});

  const handleEventClick = useCallback(
    (uid, gaTitle) => {
      trackEvent(`Agenda - more info`, gaTitle);

      const event = agenda.find((e) => e.uid === uid);
      if (event) {
        setSelectedEvent(event);
        setEventModalIsOpen(true);
      }
    },
    [agenda, trackEvent]
  );

  useEffect(() => {
    setEventsByDates(sortByDates(agenda));
  }, [agenda]);

  const handleSync = () => {
    trackEvent("Agenda - sync agenda", "sync agenda");

    updateUser({ should_sync: false });
    syncUser({ should_sync: false });
  };

  return (
    <Page>
      <Inner>
        <Header />
        <Sync shouldSync={should_sync} onClick={handleSync} />
        <StickyContainer>
          <Sticky topOffset={64}>
            {({ style, isSticky }) => {
              return (
                <div
                  style={{
                    ...style,
                    marginTop: isSticky ? pxToRem(64) : 0,
                    zIndex: 1,
                  }}
                >
                  <StickySync
                    isSticky={isSticky}
                    shouldSync={should_sync}
                    onClick={handleSync}
                  />
                </div>
              );
            }}
          </Sticky>

          {agenda.length > 0 && (
            <Body
              eventsByDates={eventsByDates}
              onEventClick={handleEventClick}
            />
          )}

          <Info />
        </StickyContainer>
        <EventModal
          isOpen={eventModalIsOpen}
          onClose={() => setEventModalIsOpen(!eventModalIsOpen)}
          gaActionPrefix="Agenda"
          item={selectedEvent}
          isAgenda
        />
      </Inner>
    </Page>
  );
}
