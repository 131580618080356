import Styled from "styled-components";
import { useContext } from "react";

import pxToRem from "../../../utils/pxToRem";
import { theme, device } from "../../../constants";
import { LocaleContext } from "../../../utils/locale-context";

import en from "../copy/en.json";
import de from "../copy/de.json";
import es from "../copy/es.json";
import fr from "../copy/fr.json";
import it from "../copy/it.json";
import ru from "../copy/ru.json";

const Body = Styled.div`
  padding: ${pxToRem(44)} ${pxToRem(20)} ${pxToRem(44)};
  max-width: ${pxToRem(548)};
  margin: auto;
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
      max-width: unset;
      padding: ${pxToRem(56)} ${pxToRem(121)} ${pxToRem(56)};
  }

  @media ${device.desktop} {
    padding: ${pxToRem(56)} ${pxToRem(165)} ${pxToRem(56)};
    max-width: ${pxToRem(1440)};
}
`;

const Headline = Styled.h2`
    ${theme.copy_bold}
    color: ${theme.dark_grey_1};
    font-size: ${pxToRem(28)};
    line-height: ${pxToRem(34)};
    margin-bottom: ${pxToRem(8)};
    padding-top: ${pxToRem(64)};
`;

const SubHeadline = Styled.h3`
  ${theme.copy_bold}
  color: ${theme.dark_grey_1};
  font-size: ${pxToRem(20)};
  line-height: ${pxToRem(32)};
  margin-bottom: ${pxToRem(8)};
`;

const BodyCopy = Styled.p`
    ${theme.copy_regular}
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(24)};
    color: ${theme.dark_grey_3};
    
    margin-bottom: 1em;

    p {
      margin-bottom: 1em;
    }


    a {
      color: ${theme.primary};
      text-decoration: none;

      &:hover,
      &:focus {
          text-decoration: underline;
      }
    }
`;

// const Ul = Styled.ul`
//     margin-bottom: ${pxToRem(24)};
// `;

// const Li = Styled.li`
//     ${theme.copy_regular}
//     font-size: ${pxToRem(16)};
//     line-height: ${pxToRem(24)};
//     color: ${theme.dark_grey_3};
//     line-break: anywhere;
// `;

// const A = Styled.a`
//     color: ${theme.primary};
//     text-decoration: none;

//     &:hover,
//     &:focus {
//         text-decoration: underline;
//     }
// `;

const Key = Styled.span`
    ${theme.copy_medium}
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(24)};
`;

const Sections = Styled.div`
    margin-bottom: 2em;
`;

const Cookie = Styled.div`
    margin-bottom: 2em;
`;

const COPY_PER_LOCALE = {
  en,
  es,
  ru,
  it,
  de,
  fr,
};

export default function TermsBody() {
  const { locale } = useContext(LocaleContext);

  return (
    <Body>
      {COPY_PER_LOCALE[locale].map((copy) => {
        return (
          <div>
            {copy.headline && <Headline>{copy.headline}</Headline>}
            {copy.subheadline && <SubHeadline>{copy.subheadline}</SubHeadline>}
            {copy.body && (
              <BodyCopy
                as="div"
                dangerouslySetInnerHTML={{ __html: copy.body }}
              />
            )}

            {copy.subSections &&
              copy.subSections.map((s) => (
                <Sections>
                  {s.headline && <SubHeadline>{s.headline}</SubHeadline>}
                  {s.body && (
                    <BodyCopy
                      as="div"
                      dangerouslySetInnerHTML={{ __html: s.body }}
                    />
                  )}
                  {s.cookies &&
                    s.cookies.map((c) => (
                      <Cookie>
                        <p>
                          <Key>{c.name_key}: </Key>
                          <BodyCopy
                            as="span"
                            dangerouslySetInnerHTML={{ __html: c.name }}
                          />
                        </p>
                        <p>
                          <Key>{c.description_key}: </Key>
                          <BodyCopy
                            as="span"
                            dangerouslySetInnerHTML={{ __html: c.description }}
                          />
                        </p>
                        <p>
                          <Key>{c.expiration_key}: </Key>
                          <BodyCopy
                            as="span"
                            dangerouslySetInnerHTML={{ __html: c.expiration }}
                          />
                        </p>
                      </Cookie>
                    ))}
                </Sections>
              ))}
          </div>
        );
      })}
    </Body>
  );
}
