import { useContext } from "react";
import { FormattedMessage } from "react-intl";
import Styled from "styled-components";

import pxToRem from "../../utils/pxToRem";
import { theme, device } from "../../constants";
import { LocaleContext } from "../../utils/locale-context";
import { getIsOnlyOneLang, getTitle } from "../../utils/data";

import Location from "../Location";
import OnlyLanguage from "../OnlyLanguage";
import Buttons from "../ActionButtons";
import arrowIcon from "./assets/arrow-right-circle.svg";

import Time from "./Time";
import Image from "./Image";
import Title from "./Title";
import Header from "./Header";
import NoData from "./NoData";

const Wrapper = Styled.div`
    padding: ${pxToRem(24)} ${pxToRem(20)};

    @media ${device.tablet} {
        padding: ${pxToRem(24)} ${pxToRem(121)};
    }

    @media ${device.desktop} {
        padding: ${pxToRem(24)} ${pxToRem(165)};
    }

    ${(props) => !props.isActive && "visibility: hidden;"}
`;

const Info = Styled.button`
    ${theme.copy_regular}
    color: ${theme.primary};
    background: transparent;
    border: none;
    padding: ${pxToRem(11)} 0 ${pxToRem(24)};
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(22)};

    &:focus-visible {
      text-decoration: underline;
    }
`;

const Event = Styled.div`
  padding-top: ${pxToRem(10)};
  padding-bottom: ${pxToRem(20)};
`;

const Inner = Styled.div`
    display: flex;
    flex-direction: column;

    @media ${device.tablet} {
      flex-direction: row;
    }

    @media ${device.desktop} {
      padding-left: ${pxToRem(190)};
    }
`;

const Content = Styled.div`
  @media ${device.tablet} {
    padding-left: ${pxToRem(30)};
    flex-grow: 1;
  }
`;

const LocAndLang = Styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const NextLink = Styled.button`
  background: transparent;
  padding: 0;
  border: none;
  color: ${theme.primary};
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(24)};
  ${theme.copy_regular}

  &:focus-visible {
    text-decoration: underline;
  }
`;

const LinkWrapper = Styled.div`
  display: flex;
  align-items: center;
  padding-top: ${pxToRem(30)};
  margin-top: ${pxToRem(30)};
  border-top: ${pxToRem(1)} solid ${theme.light_grey_1};
`;

const Icon = Styled.img`
  margin-left: ${pxToRem(6)};
`;

export default function Day({
  events = [],
  date,
  onEventClick,
  onPrev,
  onNext,
  index,
  total,
  isAgenda,
  isActive,
  gaActionPrefix,
}) {
  const { locale } = useContext(LocaleContext);
  return (
    <Wrapper isActive={isActive}>
      <Header
        date={date}
        onPrev={onPrev}
        onNext={onNext}
        index={index}
        total={total}
      ></Header>
      {events.length === 0 && <NoData />}
      {events.map((e) => {
        const isOnlyOneLang = getIsOnlyOneLang(e);
        const title = getTitle(e, locale, isOnlyOneLang);
        const gaTitle = getTitle(e, "en", isOnlyOneLang);

        return (
          <Event key={e.uid}>
            <Time start={e.duration.starttime} end={e.duration.endtime} />{" "}
            <Inner>
              <Image
                src={`/program/thumbnails/${e.image}`}
                onClick={() => onEventClick(e.uid, gaTitle)}
                title={title}
              />

              <Content>
                <LocAndLang>
                  <Location type={e.type} />
                  {isOnlyOneLang.length === 1 && (
                    <OnlyLanguage lang={isOnlyOneLang[0]} />
                  )}
                </LocAndLang>
                <Title copy={title} />
                <Info onClick={() => onEventClick(e.uid, gaTitle)}>
                  <FormattedMessage
                    id="event.more-info"
                    defaultMessage="translate this"
                  />
                </Info>
                <Buttons
                  item={e}
                  isAgenda={isAgenda}
                  gaActionPrefix={gaActionPrefix}
                  isList
                />
              </Content>
            </Inner>
          </Event>
        );
      })}

      <LinkWrapper>
        {total && index !== total - 1 && (
          <>
            <NextLink onClick={onNext}>
              <FormattedMessage
                id="programme.day.next"
                defaultMessage="translate"
              />
            </NextLink>
            <Icon src={arrowIcon} alt="" />
          </>
        )}
      </LinkWrapper>
    </Wrapper>
  );
}
