import React, { useState, useEffect, createContext } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";
import { GA } from "../constants";

const GaContext = createContext();
export { GaContext };

//const TRACKING_ID = "UA-73032448-1";

export default function GaProvider({ children }) {
  const { pathname } = useLocation();
  const [isAccepted, setIsAccepted] = useState(false);
  // ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    const handleGAIsAccepted = () => {
      // console.log("ga is accepted");

      ReactGA.set({ page: pathname });
      ReactGA.pageview(pathname);
      setIsAccepted(true);
    };

    document.addEventListener("gaAccepted", handleGAIsAccepted);

    return () => document.removeEventListener("gaAccepted", handleGAIsAccepted);
  }, []);

  const trackEvent = (action, label) => {
    // console.log("action: ", action);
    // console.log("label: ", label);
    if (isAccepted) {
      // console.log("tracking the event");
      ReactGA.event({
        category: GA.category,
        action,
        label,
      });
    } else {
      // console.log("NOT TRACKING");
    }
  };

  return (
    <GaContext.Provider value={{ trackEvent }}>{children}</GaContext.Provider>
  );
}
