import { useCallback, useContext, useRef, useState } from "react";
import Carousel from "react-slick";
import { StickyContainer, Sticky } from "react-sticky";

import { LocaleContext } from "../../../utils/locale-context";

import Nav from "./Nav";
import Day from "../../../components/Day";
import pxToRem from "../../../utils/pxToRem";
import { theme } from "../../../constants";

const getIsWeekend = (day) => day === 6 || day === 0;

const getFollowingWeekday = (day) => {
  if (day.day() === 6) {
    // is saturday
    return day.add(2, "day");
  } else {
    // is sunday
    return day.add(1, "day");
  }
};

const getDateArray = (start, end) => {
  const dates = [];
  let activeDate = start;
  let id = 0;
  const dayAfterEnd = end.add(1, "day").format("YYYY-MM-DD");
  while (activeDate.isBefore(dayAfterEnd)) {
    // add to array
    const date = activeDate.format("YYYY-MM-DD");

    const day = activeDate.day();
    const isWeekend = getIsWeekend(day);

    dates.push({ date, isWeekend, id: isWeekend ? undefined : id });
    activeDate.add(1, "day");
    if (!isWeekend) id = id + 1;
  }
  return [...dates];
};

const getStartSlide = (dates, today) => {
  let day = today;
  const isWeekend = getIsWeekend(today.day());
  if (isWeekend) {
    // if weekend, try to get the next weekday
    day = getFollowingWeekday(today);
  }
  const todayFormatted = day.format("YYYY-MM-DD");

  const i = dates.findIndex((d) => d.date === todayFormatted);
  return i < 0 ? 0 : i;
};

const settings = {
  dots: false,
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  adaptiveHeight: true,
  accessible: false,
};

export default function Body({ eventsByDates, events, onEventClick }) {
  const navRef = useRef(null);
  const bodyRef = useRef(null);
  const { moment } = useContext(LocaleContext);

  const firstEvent = events[0];
  const lastEvent = events[events.length - 1];

  const dates = getDateArray(
    moment(firstEvent.duration.starttime),
    moment(lastEvent.duration.starttime)
  );

  const datesWithoutWeekends = dates.filter((date) => !date.isWeekend);
  const start = getStartSlide(datesWithoutWeekends, moment());

  const [startSlide, setStart] = useState(start);
  const [active, setActive] = useState(start);

  const onNavClick = useCallback((i) => {
    setActive(i);
    bodyRef.current.slickGoTo(i);
  }, []);

  const onNext = useCallback(() => {
    setActive((a) => a + 1);
    bodyRef.current.slickNext();
  }, []);

  const onPrev = useCallback(() => {
    setActive((a) => a - 1);
    bodyRef.current.slickPrev();
  }, []);

  return (
    <StickyContainer>
      <Sticky topOffset={64}>
        {({ style, isSticky }) => {
          return (
            <div
              style={{
                ...style,
                marginTop: isSticky ? pxToRem(64) : 0,
                zIndex: 1,
                background: theme.white,
                borderTop: isSticky ? `1px solid ${theme.light_grey_2}` : "",
                borderBottom: isSticky ? `1px solid ${theme.light_grey_2}` : "",
                padding: `${pxToRem(8)} 0`,
              }}
            >
              <Nav
                dates={dates}
                activeIndex={active}
                ref={navRef}
                onClick={onNavClick}
              />
            </div>
          );
        }}
      </Sticky>
      <Carousel
        {...settings}
        initialSlide={startSlide}
        ref={bodyRef}
        beforeChange={(_old, i) => {
          setActive(i);
        }}
      >
        {datesWithoutWeekends.map((day, index) => {
          return (
            <div key={day.date}>
              <Day
                events={eventsByDates[day.date]}
                date={day.date}
                onNext={onNext}
                onPrev={onPrev}
                index={index}
                total={datesWithoutWeekends.length}
                onEventClick={onEventClick}
                isActive={index === active}
                gaActionPrefix={"Programme"}
              ></Day>
            </div>
          );
        })}
      </Carousel>
    </StickyContainer>
  );
}
