import { FormattedMessage } from "react-intl";
import { useContext } from "react";
import Styled from "styled-components";
import { theme, device, routes } from "../../../constants";
import pxToRem from "../../../utils/pxToRem";
import { GaContext } from "../../../utils/ga-context";

import Cta from "../../../components/Cta";

import { Link } from "react-router-dom";

const Content = Styled.div`
  max-width: 100%;
  width: 100%;
  height: 100%;
  background: ${theme.black};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${pxToRem(40)} ${pxToRem(20)};
  min-height: ${pxToRem(375)};

  @media ${device.tablet} {
    min-height: ${pxToRem(543)};
    background: ${theme.black};
  }

  @media ${device.desktop} {
    min-height: ${pxToRem(624)};
    background: ${theme.black};
  }
`;

const Wrapper = Styled.div`
  position: relative;
  width: 100%;
  background: ${theme.base};
  color: ${theme.white};
  text-align: center;
  padding: ${pxToRem(20)};
`;

const Title = Styled.h1`
    ${theme.headline_medium}
    max-width: ${pxToRem(800)};
    margin: 0 auto;
    font-size: ${pxToRem(40)};
    line-height: ${pxToRem(40)};
    margin-bottom: ${pxToRem(9)};

    @media ${device.tablet} {
      font-size: ${pxToRem(80)};
      line-height: ${pxToRem(84)};
      margin-bottom: ${pxToRem(18)};
    }

    @media ${device.desktop} {
      margin-bottom: ${pxToRem(30)};
      font-size: ${pxToRem(80)};
      line-height: ${pxToRem(80)};
      margin-bottom: ${pxToRem(4)};
    }
`;

const Copy = Styled.p`
    ${theme.copy_regular};
    margin: 0 auto;
    max-width: ${pxToRem(691)};
    margin-bottom: ${pxToRem(18)};
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(24)};

    @media ${device.tablet} {
      font-size: ${pxToRem(20)};
      line-height: ${pxToRem(32)};
      margin-bottom: ${pxToRem(45)};
    }

    @media ${device.desktop} {
      margin-bottom: ${pxToRem(30)};
      font-size: ${pxToRem(20)};
      line-height: ${pxToRem(32)};
    }
`;

const Subtitle = Styled.h2`
  ${theme.headline_medium}
  max-width: ${pxToRem(800)};
  margin: 0 auto;
  font-size: ${pxToRem(20)};
  line-height: ${pxToRem(22)};

  @media ${device.tablet} {
    font-size: ${pxToRem(40)};
    line-height: ${pxToRem(42)};
    margin-bottom: ${pxToRem(1)};
  }
`;

const Date = Styled.p`
  ${theme.headline_medium}
  max-width: ${pxToRem(800)};
  margin: 0 auto;
  font-size: ${pxToRem(80)};
  line-height: ${pxToRem(80)};
  margin-bottom: ${pxToRem(25)};

  @media ${device.tablet} {
    font-size: ${pxToRem(195)};
    line-height: ${pxToRem(195)};
    margin-bottom: ${pxToRem(1)};
    margin-bottom: ${pxToRem(16)};
  }

`;

export default function Holding({
  title,
  copy,
  isFestivalFinished,
  hasFestivalStarted,
  children,
}) {
  const { trackEvent } = useContext(GaContext);
  return (
    <Wrapper>
      <Content>
        {hasFestivalStarted ? (
          <>
            {title && (
              <Title>
                <FormattedMessage id={title} defaultMessage="translate" />
              </Title>
            )}

            {copy && (
              <Copy>
                <FormattedMessage id={copy} defaultMessage="translate" />
              </Copy>
            )}

            {children}

            {!isFestivalFinished && (
              <Cta
                as={Link}
                to={routes.PROGRAM}
                uppercase
                size="small"
                onClick={() => {
                  trackEvent("Go to programme CTA", "Go to programme clicked");
                }}
              >
                <FormattedMessage
                  id="main-stage.holding.button"
                  defaultMessage="translate"
                />
              </Cta>
            )}
          </>
        ) : (
          <div>
            <Subtitle>
              <FormattedMessage
                id="main-stage.festival.not-started"
                defaultMessage="translate"
              />
            </Subtitle>
            <Date>
              <FormattedMessage
                id="main-stage.festival.not-started.date"
                defaultMessage="08.06.21"
              />
            </Date>

            <Cta
              as={Link}
              to={routes.PROGRAM}
              uppercase
              size="small"
              onClick={() => {
                trackEvent("Go to programme CTA", "Go to programme clicked");
              }}
            >
              <FormattedMessage
                id="main-stage.holding.button"
                defaultMessage="translate"
              />
            </Cta>
          </div>
        )}
      </Content>
    </Wrapper>
  );
}
