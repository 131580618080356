import Styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { useContext } from "react";

import pxToRem from "../../../utils/pxToRem";
import { theme, device } from "../../../constants";
import { LocaleContext } from "../../../utils/locale-context";

import en from "../copy/en.json";
import de from "../copy/de.json";
import es from "../copy/es.json";
import fr from "../copy/fr.json";
import it from "../copy/it.json";
import ru from "../copy/ru.json";

const Body = Styled.div`
  padding: ${pxToRem(24)} ${pxToRem(20)} ${pxToRem(44)};
  max-width: ${pxToRem(548)};
  margin: auto;
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
      max-width: unset;
      padding: ${pxToRem(24)} ${pxToRem(121)} ${pxToRem(56)};
  }

  @media ${device.desktop} {
    padding: ${pxToRem(48)} ${pxToRem(165)} ${pxToRem(56)};
    max-width: ${pxToRem(1440)};
}
`;

const Headline = Styled.h2`
    ${theme.copy_bold}
    color: ${theme.dark_grey_1};
    font-size: ${pxToRem(28)};
    line-height: ${pxToRem(34)};
    margin-top: ${pxToRem(24)};
    margin-bottom: ${pxToRem(8)};
`;

const BodyCopy = Styled.p`
    ${theme.copy_regular}
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(24)};
    color: ${theme.dark_grey_3};

    margin-bottom: 1em;

    p {
      margin-bottom: 1em;
    }


    a {
      color: ${theme.primary};
      text-decoration: none;

      &:hover,
      &:focus {
          text-decoration: underline;
      }
    }

    ul {
      list-style: disc;
      list-style-position: inside;
      margin-bottom: 1em;

      ul {
        list-style: circle;
        list-style-position: inside;
        margin-left: 1em;
        margin-bottom: 1em;
      }
    }

    b {
      font-weight: 700;
    }
`;

const Ul = Styled.ul`
    list-style: none;
    list-style-position: inside;
    margin-bottom: ${pxToRem(24)};

    ul {
      margin-left: 1em;
    }
`;

const Li = Styled.li`
    ${theme.copy_regular}
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(24)};
    color: ${theme.dark_grey_3};
    line-break: anywhere;
`;

const A = Styled.a`
    color: ${theme.primary};
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
`;

// const LOCALES_PER_LOCALE = {
//   en: "en-GB",
//   es: "es-es",
//   ru: "ru-ru",
//   it: "it-it",
//   de: "de-de",
//   fr: "fr-fr",
// };

const COPY_PER_LOCALE = {
  en,
  es,
  ru,
  it,
  de,
  fr,
};

export default function PrivacyBody() {
  const { locale } = useContext(LocaleContext);

  const intro = COPY_PER_LOCALE[locale].filter((item) => item.isTop);
  const main = COPY_PER_LOCALE[locale]
    .filter((item) => !item.isTop)
    .filter((item) => item.target);

  const meta = COPY_PER_LOCALE[locale]
    .filter((item) => !item.isTop)
    .filter((item) => !item.target);

  return (
    <Body>
      {intro.map((i) => (
        <div>
          <Headline>{i.headline}</Headline>

          <BodyCopy as="div" dangerouslySetInnerHTML={{ __html: i.body }} />
        </div>
      ))}

      <div>
        <Headline>
          <FormattedMessage
            id="privacy.table"
            defaultMessage="Table of Contents"
          />
        </Headline>
      </div>
      <Ul>
        {main.map((i) => (
          <Li key={i.target}>
            <A href={`#${i.target}`}>{i.headline}</A>
            {i.subSections && (
              <ul>
                {i.subSections.map((s) => (
                  <Li key={s.target}>
                    <A href={`#${s.target}`}>{s.headline}</A>
                  </Li>
                ))}
              </ul>
            )}
          </Li>
        ))}
      </Ul>

      {main.map((i) => (
        <>
          <Headline id={i.target}>{i.headline}</Headline>
          {i.body && (
            <BodyCopy as="div" dangerouslySetInnerHTML={{ __html: i.body }} />
          )}
          {i.subSections &&
            i.subSections.map((s) => (
              <>
                <Headline id={s.target}>{s.headline}</Headline>
                {s.body && (
                  <BodyCopy
                    as="div"
                    dangerouslySetInnerHTML={{ __html: s.body }}
                  />
                )}
                {s.subSections &&
                  s.subSections.map((t) => (
                    <>
                      <Headline id={t.target}>{t.headline}</Headline>
                      {t.body && (
                        <BodyCopy
                          as="div"
                          dangerouslySetInnerHTML={{ __html: t.body }}
                        />
                      )}
                    </>
                  ))}
              </>
            ))}
        </>
      ))}
      {meta.map(
        (i) =>
          i.body && (
            <BodyCopy as="div" dangerouslySetInnerHTML={{ __html: i.body }} />
          )
      )}
    </Body>
  );
}
