import Styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { routes, theme, device } from "../../../constants";
import pxToRem from "../../../utils/pxToRem";

const Wrapper = Styled.div`
    margin-top: ${pxToRem(36)};
`;

const Heading = Styled.h3`
    color: ${theme.dark_grey_1};
    font-size: ${pxToRem(20)};
    line-height: ${pxToRem(32)};
    ${theme.copy_bold}
    margin-bottom: ${pxToRem(30)};

    @media ${device.tablet} {
        font-size: ${pxToRem(28)};
        line-height: ${pxToRem(34)};
    }
`;

const SubHeading = Styled.h4`
    color: ${theme.dark_grey_1};
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(24)};
    ${theme.copy_medium}

    @media ${device.tablet} {
        font-size: ${pxToRem(20)};
        line-height: ${pxToRem(32)};
    }
`;

const Copy = Styled.div`
    color: ${theme.dark_grey_1};
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(24)};
    ${theme.copy_regular}
`;

const StyledLink = Styled.a`
    color: ${theme.primary};
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
`;

const P = Styled.p`
    margin-bottom: 1em;
`;

export default function Contact() {
  return (
    <Wrapper>
      <Heading>
        <FormattedMessage
          id="help.contact.heading"
          defaultMessage="translate"
        />
      </Heading>
      <SubHeading>
        <FormattedMessage
          id="help.contact.subheading.tech"
          defaultMessage="translate"
        />
      </SubHeading>
      <Copy>
        <FormattedMessage
          id="help.contact.tech.body"
          defaultMessage="translate"
          values={{
            p: (copy) => <P>{copy}</P>,
            link: (copy) => (
              <StyledLink href="mailto:help@pro-print-event.canon-europe.com">
                {copy}
              </StyledLink>
            ),
          }}
        />
      </Copy>
      <SubHeading>
        <FormattedMessage
          id="help.contact.subheading.general"
          defaultMessage="translate"
        />
      </SubHeading>
      <Copy>
        <FormattedMessage
          id="help.contact.general.body"
          defaultMessage="translate"
          values={{
            p: (copy) => <P>{copy}</P>,
            link: (copy) => (
              <StyledLink as={Link} to={routes.EXPERT}>
                {copy}
              </StyledLink>
            ),
          }}
        />
      </Copy>
    </Wrapper>
  );
}
