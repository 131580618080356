import { useContext, useState, useEffect } from "react";

import Page from "../../components/Page";
import Loading from "../../components/Loading";
import { UserMetaContext } from "../../utils/metadata-context";
import { GaContext } from "../../utils/ga-context";
import { getStarred, getContentForUserType } from "../../utils/data";
import mapCountry2Region from "../../constants/regions";
import { CENTRAL, ONDEMAND_PER_REGIONS } from "../../data/ondemand";

import Header from "./Header";
import Body from "./Body";
import PlayerModal from "./PlayerModal";
import LangSelectorModal from "./LangSelector";
import Banner from "./Banner";

import { VIDEO } from "./utils";

const getSections = (data) => {
  return data.reduce((total, current) => {
    const availableLanguages = [
      { key: "de", value: current["link-de"] },
      { key: "en", value: current["link-en"] },
      { key: "fr", value: current["link-fr"] },
      { key: "it", value: current["link-it"] },
      { key: "es", value: current["link-es"] },
      { key: "ru", value: current["link-ru"] },
    ].filter((i) => !!i.value);

    const item = {
      ...current,
      availableLangs: availableLanguages.map((i) => i.key),
    };

    return {
      ...total,
      [current.pillar]: total[current.pillar]
        ? [...total[current.pillar], item]
        : [item],
    };
  }, {});
};

export default function Ondemand() {
  const { user } = useContext(UserMetaContext);
  const { trackEvent } = useContext(GaContext);
  const { user_metadata } = user;
  const { country } = user_metadata;

  const [sections, setSections] = useState({});
  const [starred, setStarred] = useState([]);

  const [playerModalIsOpen, setPlayerModalIsOpen] = useState(false);
  const [langSelectorIsOpen, setLangSelectorIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [selectedItem, setSelectedItem] = useState({});

  useEffect(() => {
    const { ondemand_jsons } = mapCountry2Region(country) || [];

    const ondemandPerRegion = ondemand_jsons.flatMap((key) => {
      return ONDEMAND_PER_REGIONS[key];
    });
    const ondemandForUser = [...CENTRAL, ...ondemandPerRegion];

    const availableToUser = getContentForUserType(user, ondemandForUser);

    setSections(getSections(availableToUser));
    setStarred(getStarred(user, availableToUser));
  }, [country, user]);

  if (user.user_metadata.isLoading) return <Loading />;

  const handleOnItemSelect = (item) => {
    setSelectedItem(item);

    // always show language selector if there are multiple langs available
    if (item.availableLangs.length > 1) {
      setLangSelectorIsOpen(true);
      return;
    } else {
      setSelectedLanguage(item.availableLangs[0]);
    }

    if (item.type.toLowerCase() === VIDEO) {
      trackEvent("On-demand content selection", item[`title-en`]);
      setPlayerModalIsOpen(true);
    }
  };

  const handlePlayerModalClose = () => {
    setPlayerModalIsOpen(false);
    setSelectedItem({});
  };

  const handleLangSelectorModalClose = (lang) => {
    setLangSelectorIsOpen(false);
    if (lang) {
      setSelectedLanguage(lang);
      setPlayerModalIsOpen(true);
    }
  };

  return (
    <Page>
      {process.env.REACT_APP_WARNING_VISIBLE === "true" && <Banner />}

      <Header items={starred} onItemClick={handleOnItemSelect} />
      <Body sections={sections} onItemClick={handleOnItemSelect} />
      <PlayerModal
        isOpen={playerModalIsOpen}
        onClose={handlePlayerModalClose}
        item={selectedItem}
        selectedLanguage={selectedLanguage}
      />
      <LangSelectorModal
        isOpen={langSelectorIsOpen}
        onClose={handleLangSelectorModalClose}
        item={selectedItem}
      />
    </Page>
  );
}
