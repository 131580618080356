import Styled from "styled-components";
import { FormattedMessage } from "react-intl";

import { theme, device } from "../../../constants";
import pxToRem from "../../../utils/pxToRem";
import Cta from "../../../components/Cta";

import iconSticky from "./icon-sticky.svg";
import sync from "../Sync/sync.svg";
import check from "../Sync/check.svg";

const Wrapper = Styled.div`
    position: absolute;
    width: 100%;
    background: ${theme.white};
    display: flex;
    justify-content: space-between;
    padding: ${pxToRem(15)} ${pxToRem(20)};
    border: ${pxToRem(1)} solid ${theme.light_grey_1};
    align-items: center;

    ${(props) => !props.isSticky && "visibility: hidden;"}
`;

const Icon = Styled.img`
    margin-right: ${pxToRem(6)};
`;

const Img = Styled.img`
    margin-right: ${pxToRem(16)};
`;

const Content = Styled.div`
    display: flex;
    align-items: center;
`;

const Title = Styled.h3`
    ${theme.copy_bold}
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(22)};

    @media ${device.mobile} {
        display: none;
    }
`;

export default function StickySync({ isSticky, onClick, shouldSync }) {
  return (
    <Wrapper isSticky={isSticky}>
      <Content>
        <Img alt="" src={iconSticky} />
        <Title>
          <FormattedMessage id="agenda.sync.title" defaultMessage="TRANSLATE" />
        </Title>
      </Content>
      <Cta
        onClick={() => onClick()}
        uppercase
        size="small"
        disabled={shouldSync ? false : true}
        as={shouldSync ? "button" : "span"}
        variant={shouldSync ? "primary" : "invited"}
        hasIcon
      >
        <Icon src={shouldSync ? sync : check} alt="" />
        <FormattedMessage id="agenda.sync.cta" defaultMessage="translate" />
      </Cta>
    </Wrapper>
  );
}
