import Styled from "styled-components";
import { NavLink } from "react-router-dom";

import pxToRem from "../../utils/pxToRem";
import { theme } from "../../constants";

export const NavItem = Styled(NavLink)`
  display: flex;
  padding: ${pxToRem(16)} ${pxToRem(24)};
  text-decoration: none;
  border: none;
  background: transparent;
  border-bottom: ${pxToRem(1)} solid ${theme.grey_6};
  color: ${theme.grey_6};
  ${theme.copy_regular}
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(24)};
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  border-left:  ${pxToRem(4)} solid transparent;

  [data-selected="true"] + &,
  &.active {
    border-left: ${pxToRem(4)} solid ${theme.secondary};
    background: ${theme.black};
    color: ${theme.white};
    ${theme.copy_medium}
  }

  ${(props) =>
    props.isActive &&
    `
    border-left: ${pxToRem(4)} solid ${theme.secondary};
    background: ${theme.black};
    color: ${theme.white};
    ${theme.copy_medium}
  `}


  &:hover,
  &:focus {
    background: ${theme.black};
    color: ${theme.white};
  }

  ${(props) =>
    props.$isLive &&
    `
    background: ${theme.black};
  `}

  &:focus-visible {
    background: ${theme.black};
  }
`;

export const LangSelectorButton = Styled(NavItem)`
  & > div {
    display: flex;
    align-items: center;
  }
`;

export const Flag = Styled.img`
  width: ${pxToRem(24)};
  margin-right: ${pxToRem(15)};
`;

export const Chevron = Styled.img``;
