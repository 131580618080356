import PropTypes from "prop-types";
import Styled from "styled-components";
import { rgba } from "polished";

import { theme } from "../../constants";
import pxToRem from "../../utils/pxToRem";

const SIZES = {
  small: "small",
};

const StyledCta = Styled.button`
  background: ${theme.primary};
  color: #fff;
  border: 1px solid ${theme.primary};
  border-radius: ${pxToRem(40)};
  padding: ${pxToRem(15)} ${pxToRem(20)};

  ${theme.copy_bold}
  font-size: ${pxToRem(10)};
  line-height: ${pxToRem(14)};
  text-decoration: none;
  text-align: center;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: ${pxToRem(95)};
  cursor: pointer;

  &:hover,
  &:focus {
    background: ${theme.primary_dark};
    border-color: ${theme.primary_dark};
  }

  ${(props) =>
    props.$hasIcon &&
    `padding: ${pxToRem(10)} ${pxToRem(30)} ${pxToRem(10)} ${pxToRem(30)};`}

  ${(props) => props.$isLink && "display: table-cell;"}

  ${(props) => props.$uppercase && "text-transform: uppercase;"}

  ${(props) =>
    props.$variant === "secondary" &&
    `
    background: transparent;
    color: ${theme.primary};

    &:hover,
    &:focus {
      background: ${rgba(theme.primary, 0.07)};
    }
  `}

  ${(props) =>
    props.$variant === "red" &&
    `
    background: ${theme.secondary};
    border-color: ${theme.secondary};
    color: ${theme.white};

    &:hover,
    &:focus {
      background: ${theme.red_dark_1};
      border-color: ${theme.red_dark_1};
    }
  `}

  ${(props) =>
    props.$variant === "register" &&
    `
    background: ${theme.white};
    border-color: ${theme.primary};
    color: ${theme.primary};

    &:hover,
    &:focus {
      background: ${theme.primary};
      border-color: ${theme.primary};
      color: ${theme.white}
    }
  `}

  ${(props) =>
    props.$variant === "invited" &&
    `
    background: ${theme.invited}!important;
    border-color: ${theme.invited}!important;
    color: ${theme.white}!important;
  `}

  ${(props) =>
    props.$size === SIZES.small &&
    `
      padding: ${pxToRem(9)} ${pxToRem(20)} ${pxToRem(9)} ${pxToRem(20)};
  `}

  ${(props) =>
    props.$size === SIZES.small &&
    props.$hasIcon &&
    `
      padding: ${pxToRem(4)} ${pxToRem(20)} ${pxToRem(4)} ${pxToRem(20)};
  `}

  ${(props) =>
    props.$iconOnly &&
    `
    padding: ${pxToRem(4)} ${pxToRem(8)};
    min-width: unset;
  `}

  ${(props) =>
    props.$isOndemand &&
    `
    padding-left: ${pxToRem(30)};
    padding-right: ${pxToRem(30)};
    min-width: unset;
  `}

  &[disabled] {
    background: ${theme.light_grey_2};
    color: ${theme.light_grey_1};
    border-color: ${theme.light_grey_2};
  }
`;

export default function Cta({
  variant,
  as,
  uppercase,
  isLink,
  hasIcon,
  size,
  iconOnly,
  isOndemand,
  ...rest
}) {
  return (
    <StyledCta
      as={as}
      $variant={variant}
      $uppercase={uppercase}
      $isLink={isLink}
      $size={size}
      $hasIcon={hasIcon}
      $iconOnly={iconOnly}
      $isOndemand={isOndemand}
      data-id="button"
      {...rest}
    />
  );
}

Cta.propTypes = {
  variant: PropTypes.string,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
};

Cta.defaultProps = {
  variant: "primary",
  as: "button",
};
