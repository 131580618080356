import Styled from "styled-components";
import moment from "moment";
import { useEffect, useRef } from "react";

import Day from "../../../components/Day";

const Wrapper = Styled.div`
`;

export default function Body({ eventsByDates, onEventClick }) {
  const wrapperRef = useRef(null);
  useEffect(() => {
    const today = moment().format("YYYY-MM-DD");
    const currentEl = document.getElementById(today);
    if (currentEl) {
      currentEl.scrollIntoView({ behavior: "smooth" });
    }
  }, [wrapperRef?.current]);
  return (
    <Wrapper ref={wrapperRef}>
      {Object.keys(eventsByDates).map((key) => {
        return (
          <div key={key} id={key}>
            <Day
              events={eventsByDates[key]}
              date={key}
              onEventClick={onEventClick}
              isAgenda
              isActive
              gaActionPrefix={"Agenda"}
            ></Day>
          </div>
        );
      })}
    </Wrapper>
  );
}
